import { useEffect, useState } from "react"

import { useFormik } from "formik"
import { toast } from "react-toastify"
import { findIndex } from "lodash"
import { BsTrash } from "react-icons/bs"

import { BaseModal, Button, Checkbox, Input, Dropdown, DropdownMultiple } from "../common"
import {
  getVerticalsAPI,
  assignVerticalToCampagins,
  createRuleAPI,
  assignTemplatesToCampagins,
  assignBulkTemplatesToCampagins
} from "../../service"

import {
  newVerticalSchema,
  assignVerticalToCampaginsSchema,
  createRuleCampaignSchema
} from "../../helpers/validationSchema"

import { AD_NAMING, AD_SET_NAMING } from "../../utils/constants"

export const ManageColumnsCompaginsModals = ({
  open = false,
  setOpen,
  allColumns,
  setColumns
}) => {
  const [currentColumns, setCurrentColumns] = useState([])

  const handleSave = () => {
    setColumns(currentColumns)
    setOpen(false)
  }

  useEffect(() => {
    setCurrentColumns(allColumns)
  }, [open, allColumns])

  const handleChange = ({ target: { name, value } }) => {
    const tempColumns = [...currentColumns]

    let index = findIndex(tempColumns, { value: name })

    tempColumns[index] = {
      ...tempColumns[index],
      isActive: !tempColumns[index].isActive
    }
    setCurrentColumns(tempColumns)
  }

  return (
    <BaseModal open={open} setOpen={setOpen} title={"Manage Columns"}>
      <div className="border rounded-lg flex-col">
        <div className="border-b-2 w-full pl-5 py-2 text-sm">
          Select columns to manage{" "}
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-4">
          {currentColumns.map(({ label, value, isActive }) => (
            <Checkbox
              title={label}
              name={value}
              checked={!isActive}
              onChange={handleChange}
            />
          ))}
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
        <Button
          type="button"
          className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
          onClick={() => {
            setOpen(false)
          }}
        >
          Close
        </Button>
        <Button
          type="button"
          className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </BaseModal>
  )
}

export const AssignedVerticalsToCompaginsModals = ({
  open = false,
  setOpen,
  vertical: currentVertical = {},
  loadData
}) => {
  const [verticals, setVerticals] = useState([])

  useEffect(() => {
    getAllVerticals()
  }, [])

  const getAllVerticals = async () => {
    try {
      const {
        data: data
      } = await getVerticalsAPI()
      
      const reArrangedFiltered = data.map(({ id, name }) => ({
        label: name,
        value: id
      }))
      setVerticals(reArrangedFiltered)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const submitHandler = async ({ vertical }) => {
    try {
      await assignVerticalToCampagins(currentVertical.id, {
        verticals: [vertical.value]
      })
      toast.success("Vertical assigned")
      formik.resetForm()
      setOpen(false)
      loadData()
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      vertical: {}
    },
    validationSchema: assignVerticalToCampaginsSchema,
    onSubmit: submitHandler
  })

  return (
    <BaseModal open={open} setOpen={setOpen} title={"Assign Verticals"}>
      <form onSubmit={formik.handleSubmit}>
        <div className="border rounded-lg flex-col">
          <div className="border-b-2 w-full pl-5 py-2 text-sm">Verticals</div>
          <div className="grid grid-cols-1 gap-x-4 px-4 py-4">
            <Dropdown
              options={verticals}
              placeholder="verticals"
              onChange={value => formik.setFieldValue("vertical", value)}
              name="vertical"
              onBlur={formik.handleBlur}
              value={formik.values.vertical}
            />
            {formik.errors.vertical && formik.values.vertical && (
              <p className="text-red-600">Please select a vertical</p>
            )}
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
          <Button
            type="button"
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => {
              setOpen(false)
            }}
          >
            Close
          </Button>
          <Button
            type="submit"
            className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
          >
            Save
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}

export const ApplyTemplateToCompagins = ({ open = false, setOpen, data, selectedRows, type }) => {
  
  const submitHandler = async ({ template }) => {
    try {
      if (template && template.length === 0) {
        toast.error("Please select templates")
        return false
      }

      const temps = template.map((items) => items.value)
      const params = {
        rule_template_ids: temps
      }
      
      if (type && type === "campaigns") {
        params.meta_campaign_ids = selectedRows.map((items) => items.id)
      } else if (type && type === "adsets") {
        params.ad_set_ids = selectedRows.map((items) => items.id)
      } else if (type && type === "ads") {
        params.ad_ids = selectedRows.map((items) => items.id)
      }
      
      await assignBulkTemplatesToCampagins(params)
      toast.success("Applied Templates")
      formik.resetForm()
      setOpen(false)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      template: []
    },
    onSubmit: submitHandler
  })

  const handleChange = (value) => {
    if (value && value.length > 0) {
      formik.setFieldValue("template", value)
    } else {
      formik.setFieldValue("template", [])
    }
  }

  return (
    <BaseModal open={open} setOpen={setOpen} title={"Apply Template"} modalSize="max-w-xl">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex-col">
          <div className="grid grid-cols-1 gap-4 py-5">
            <DropdownMultiple
              placeholder="Select Template"
              options={data}
              onChange={value => handleChange(value)}
              onBlur={formik.handleBlur}
              value={formik.values.template}
              error={formik.errors.template}
              touched={formik.touched.template}
            />
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
          <Button
            type="button"
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => {
              setOpen(false)
            }}
          >
            Close
          </Button>
          <Button
            type="submit"
            className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
          >
            Save
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}

export const CreateRuleForCompaginsModal = ({
  open,
  setOpen,
  campaigns = []
}) => {
  const [numberOfInputs, setNumberOfInputs] = useState(1)

  const submitHandler = async ({
    action: { label: action },
    period: { label: period },
    params,
    name
  }) => {
    try {
      let finalParams = params.map(
        ({
          field: { label: field },
          operator: { label: operator },
          value
        }) => ({
          value: parseInt(value),
          operator,
          field
        })
      )
      console.log("FORM PARSM", {
        name,
        action,
        period: parseInt(period),
        conditions: finalParams,
        campaign: campaigns[0].id
      })
      const finalCampaigns = campaigns.map(({ id }) => id)
      await createRuleAPI({
        name,
        action,
        period: parseInt(period),
        conditions: finalParams,
        campaigns: finalCampaigns
      })
      toast.success("Rule Created")
      setNumberOfInputs(1)
      formik.resetForm()
      setOpen(false)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      params: [{ field: {}, operator: {}, value: "" }],
      action: {},
      period: {},
      name: ""
    },
    validationSchema: createRuleCampaignSchema,
    onSubmit: submitHandler
  })
  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title="Create New Rule"
      className="sm:max-w-xl"
    >
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Rule Name"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.errors.name}
          touched={formik.touched.name}
        />
        <div className="my-4">
          <p className="font-bold text-base">Apply Rule to:</p>
          <ul className="list-disc list-inside mt-3">
            {campaigns.map(({ name }) => (
              <li>{name}</li>
            ))}
          </ul>
        </div>
        <Dropdown
          placeholder="Action"
          options={[
            { label: "Turn on Adset", value: "Turn on Adset" },
            { label: "Turn off Adset", value: "Turn off Adset" },
            { label: "Increase Daily budget by", value: "Increase Daily budget by" },
            { label: "Decrease Daily budget by", value: "Decrease Daily budget by" },
            { label: "Increase Lifetime budget by", value: "Increase Lifetime budget by" },
            { label: "Send notification", value: "Send notification" }
          ]}
          name="action"
          onChange={value => formik.setFieldValue("action", value)}
          onBlur={formik.handleBlur}
          value={formik.values.action}
          error={formik.errors.action}
          touched={formik.touched.action}
        />
        <Dropdown
          placeholder="Period"
          options={[
            { label: "Today", value: "Today" },
            { label: "Yesterday", value: "Yesterday" },
            { label: "Last 3 Days", value: "Last 3 Days" },
            { label: "Last 7 Days", value: "Last 7 Days" },
            { label: "Last 14 Days", value: "Last 14 Days" },
            { label: "Last 30 Days", value: "Last 30 Days" },
            { label: "Last 3 Days, Including Today", value: "Last 3 Days, Including Today" },
            { label: "Last 7 Days, Including Today", value: "Last 7 Days, Including Today" },
            { label: "Last 14 Days, Including Today", value: "Last 14 Days, Including Today" },
            { label: "Last 30 Days, Including Today", value: "Last 30 Days, Including Today" }
          ]}
          name="period"
          onChange={value => formik.setFieldValue("period", value)}
          onBlur={formik.handleBlur}
          value={formik.values.period}
          error={formik.errors.period}
          touched={formik.touched.period}
        />
        <div className="py-5">
          <p className="font-semibold">Conditions</p>
          <p className="text-sm">All of the following match</p>
          <div className="grid grid-cols-3 gap-x-2 mr-5">
            {[...new Array(numberOfInputs)].map((_, index) => (
              <>
                <Dropdown
                  placeholder="Field"
                  options={[
                    { label: "Amount Spent", value: "Amount Spent" },
                    { label: "Lifetime Spent", value: "Lifetime Spent" },
                    { label: "Conversions", value: "Conversions" },
                    { label: "Cost Per Conversion", value: "Cost Per Conversion" },
                    { label: "Current Time", value: "Current Time" },
                    { label: "Clicks", value: "Clicks" },
                    { label: "CPM", value: "CPM" },
                    { label: "CPC", value: "CPC" },
                    { label: "CTR", value: "CTR" }
                  ]}
                  name={`params[${index}].field`}
                  onChange={value =>
                    formik.setFieldValue(`params[${index}].field`, value)
                  }
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.params &&
                    formik.values?.params[index] &&
                    formik.values?.params[index].field
                  }
                  error={
                    formik.errors?.params &&
                    formik.errors?.params[index] &&
                    formik.errors?.params[index].field
                  }
                  touched={
                    formik.touched?.params &&
                    formik.touched?.params[index] &&
                    formik.touched?.params[index].field
                  }
                />
                <Dropdown
                  placeholder="Operator"
                  options={[
                    { label: "<", value: "Less Than" },
                    { label: ">", value: "Greater Than" },
                    { label: "<=", value: "Less than or Equal to" },
                    { label: ">=", value: "Greater than or Equal to" },
                    { label: "=", value: "Equal to" },
                    { label: "≬", value: "Between" }
                  ]}
                  name={`params[${index}].operator`}
                  onChange={value =>
                    formik.setFieldValue(`params[${index}].operator`, value)
                  }
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.params &&
                    formik.values.params[index] &&
                    formik.values.params[index].operator
                  }
                  error={
                    formik.errors.params &&
                    formik.errors.params[index] &&
                    formik.errors.params[index].operator
                  }
                  touched={
                    formik.touched.params &&
                    formik.touched.params[index] &&
                    formik.touched.params[index].operator
                  }
                />
                <Input
                  placeholder="Value"
                  name={`params[${index}].value`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min="0"
                  value={
                    formik.values.params && formik.values.params[index]?.value
                  }
                  error={
                    formik.errors.params && formik.errors.params[index]?.value
                  }
                  touched={
                    formik.touched.params && formik.touched.params[index]?.value
                  }
                />
              </>
            ))}
          </div>
          <p className="absolute right-3 bottom-36 text-[#CC4B43]">
            {numberOfInputs > 1 && (
              <BsTrash
                size={24}
                onClick={() => {
                  formik.values.params.pop()
                  formik.setFieldValue("params", [...formik.values.params])
                  setNumberOfInputs(old => --old)
                }}
                className="cursor-pointer"
              />
            )}
          </p>
          <div className="flex justify-end mr-3">
            <Button
              className={"bg-[#00B2B4] mr-2 py-2 px-3 shadow-sm"}
              onClick={() => {
                formik.setFieldValue("params", [
                  ...formik.values.params,
                  { field: {}, operator: {}, value: "" }
                ])
                setNumberOfInputs(old => ++old)
              }}
            >
              + Add
            </Button>
          </div>
        </div>

        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
          <Button
            className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
            type="submit"
          >
            Save
          </Button>
          <Button
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => {
              setNumberOfInputs(1)
              formik.resetForm()
              setOpen(false)
            }}
            type="button"
          >
            Close
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}

export const AddTokenModal = ({ open = false, setOpen, handleChange, currentElement }) => {
 
  const submitHandler = async (values) => {
    setOpen(false)
    handleChange(values.naming)
  }

  const formik = useFormik({
    initialValues: {
      naming: ""
    },
    onSubmit: submitHandler
  })

  return (
    <BaseModal open={open} setOpen={setOpen} title={"Insert Token"}>
      <form onSubmit={formik.handleSubmit}>
        
        <div className="grid grid-cols-1 gap-4">
          <Dropdown
            placeholder="Select Token"
            options={(currentElement && currentElement.includes('adSet')) ? AD_SET_NAMING : AD_NAMING}
            onChange={value => formik.setFieldValue("naming", value)}
            onBlur={formik.handleBlur}
            value={formik.values.naming}
            error={formik.errors.naming}
            touched={formik.touched.naming}
          />
        </div>
        <div className="mt-8 sm:mt-4 sm:flex sm:flex-row justify-end">
          <Button
            type="button"
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => {
              setOpen(false)
            }}
          >
            Close
          </Button>
          <Button
            type="submit"
            className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
          >
            Add
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}
