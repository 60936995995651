import { useEffect, useLayoutEffect, useRef, useState, useImperativeHandle } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  BsSearch,
  BsArrowCounterclockwise,
  BsThreeDotsVertical
} from "react-icons/bs"
import { toast } from "react-toastify"
import { findIndex } from "lodash"
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs"
import { getCampaginsAPI, createDuplicateCampaign, deleteCampaignAPI, getRuleTemplatesAPI, updateCampaignDataAPI, getSelfConfigAPI, updateSelfConfigAPI, fetchCampaignDataAPI } from "../../service"
import {
  Input,
  Button,
  Toggle,
  ManageColumnsCompaginsModals,
  AssignedVerticalsToCompaginsModals,
  ApplyTemplateToCompagins,
  CreateRuleForCompaginsModal,
  CreateCompagins,
  Pagination,
  Dropdown,
  FilterComponent,
  BaseModal,
  Spinner
} from "../../components"
import { BiCopyAlt, BiDollar, BiEditAlt, BiRefresh, BiTrash } from "react-icons/bi"
import DataTable from 'react-data-table-component'
import { getStatsSummary } from '../../redux/actions/common'
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { FaCopy, FaDollarSign, FaPercent, FaUsers } from "react-icons/fa"
import moment from 'moment'
import 'moment-timezone'
import { useFormik } from "formik"
import { confirmAlert } from "react-confirm-alert"
import SummartStats from './summaryStats'

export default function VerticalsComponent({ setCampaignSelected, adAccounts, formik, forwardedRef, formikfilters, forwardedFilterRef, reset }) {
  
  const tableRef = useRef(null)
  const dispatch = useDispatch()
  const { stats } = useSelector(({ common }) => common)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedVerticals, setSelectedVerticals] = useState([])
  const [showManageColumnsModal, setShowManageColums] = useState(false)
  const [showVerticalsModal, SetShowVerticalsModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [showCreateRuleModal, setShowCreateRuleModal] = useState(false)
  const [showCreateCompaginModal, setShowCreateCompaginModal] = useState(false)
  const [campagins, setAllCampaigns] = useState([])
  const [totalItems, setCampaignsCount] = useState(0)
  const [totalPerPage, setTotalPerPage] = useState({ label: 25, value: 25 })
  const [rowActionIndex, setRowActionIndex] = useState(-1)
  const [currentPage, setCurrentPage] = useState(0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [dataId, setDataId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [rulesTemplateData, setRulesTemplateData] = useState([])
  const [sorted, setSorted] = useState('-impressions')

  const [allColumns, setColumns] = useState([
    { label: "ID", value: "id", isActive: false },
    { label: "Action", value: "action", isActive: false },
    { label: "Status", value: "status", isActive: false },
    { label: "Name", value: "name", isActive: false },
    { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
    { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
    { label: "Clicks", value: "ms_clicks", isActive: true },
    { label: "CTR", value: "ms_ctr", isActive: true },
    { label: "ROI", value: "ms_roi", isActive: true },
    { label: "Conv. Rate", value: "ms_conversion_rate", isActive: false },
    { label: "Sold Rate", value: "ms_sold_rate", isActive: false },
    { label: "EPC", value: "ms_epc", isActive: true },
    { label: "CPC", value: "ms_lpvc", isActive: true },
    { label: "Revenue", value: "ms_revenue", isActive: true },
    { label: "Profit", value: "ms_profit", isActive: false },
    { label: "Spend", value: "spend", isActive: false },
    { label: "Impressions", value: "impressions", isActive: false }
  ])

  const [columnData, setColumnData] = useState([])

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  useEffect(() => {
    getRuleTemplates()
    getColumnOrderList()
  }, [])

  const handleFormData = async values => {
    try {
      let params = {}
      let objs = {}
      if (values.insights_start_date) {
        params.insights_start_date = values.insights_start_date
        objs.start_date = params.insights_start_date
      }
      if (values.insights_end_date) {
        params.insights_end_date = values.insights_end_date
        objs.end_date = params.insights_end_date
      }

      if (formikfilters && formikfilters.values && formikfilters.values.status && Object.keys(formikfilters.values.status).length > 0) {
        objs.campaign_status = formikfilters.values.status.value
      }

      getAllCampagins(params)
      dispatch(getStatsSummary(objs))
    } catch (error) {
      
    }
  }

  useImperativeHandle(forwardedRef, () => ({
    handleFormData,
  }));

  useEffect(() => {
    getAllCampagins()
  }, [currentPage])

  // GET SUMMARY STATS
  useEffect(() => {
    const params = { start_date: formik.values.insights_start_date, end_date: formik.values.insights_end_date }

    if (formikfilters && formikfilters.values && formikfilters.values.ad_account_id && Object.keys(formikfilters.values.ad_account_id).length > 0) {
      params.ad_account_id = formikfilters.values.ad_account_id.value
    }

    if (formikfilters && formikfilters.values && formikfilters.values.offer_ids && Object.keys(formikfilters.values.offer_ids).length > 0) {
      params.offer_ids = formikfilters.values.offer_ids.value
    }

    if (formikfilters && formikfilters.values && formikfilters.values.status && Object.keys(formikfilters.values.status).length > 0) {
      params.campaign_status = formikfilters.values.status.value
    }

    dispatch(getStatsSummary(params))
  }, [])

  // GET ALL CAMPAIGNS
  const getAllCampagins = async (param) => {

    let current = (currentPage > 0) ? currentPage : 0
    let obj = { offset: current, limit: totalPerPage.value, drafts: false, insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date }
    
    if (param && param.limit) {
      obj.limit = param.limit
    }
    if (param && param.search) {
      obj.search_query = param.search
    }
    if (param && param.insights_start_date) {
      obj.insights_start_date = param.insights_start_date
    }
    if (param && param.insights_end_date) {
      obj.insights_end_date = param.insights_end_date
    }
    
    if (param && param.ad_account_id === null) {

    } else if (param && param.ad_account_id) {
      obj.ad_account_id = param.ad_account_id
    } else if (formikfilters && formikfilters.values && formikfilters.values.ad_account_id && Object.keys(formikfilters.values.ad_account_id).length > 0) {
      obj.ad_account_id = formikfilters.values.ad_account_id.value
    }

    if (param && param.offer_ids === null) {

    } else if (param && param.offer_ids) {
      obj.offer_ids = param.offer_ids
    } else if (formikfilters && formikfilters.values && formikfilters.values.offer_ids && Object.keys(formikfilters.values.offer_ids).length > 0) {
      obj.offer_ids = formikfilters.values.offer_ids.value
    }

    if (param && param.status === null) {

    } else if (param && param.status) {
      obj.status = param.status
    } else if (formikfilters && formikfilters.values && formikfilters.values.status && Object.keys(formikfilters.values.status).length > 0) {
      obj.status = formikfilters.values.status.value
    }
   
    if (param && param.sort_by) {
      obj.sort_by = param.sort_by
    } else if (sorted) {
      obj.sort_by = sorted
    }
     
    try {
      setLoading(true)
      const { data } = await getCampaginsAPI(obj)
      setLoading(false)
      setCampaignsCount(data.count)
      setAllCampaigns(data.results)
    } catch (error) {
      setLoading(false)
      const message = (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      toast.error(message)
    }
  }

  // GET RULE TEMPLATES
  const getRuleTemplates = async () => {
    try {
      const { data } = await getRuleTemplatesAPI()
      const res = data.map(item => {
        return { label: item.name, value: item.id }
      })
      setRulesTemplateData(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  // GET COLUMN ORDERS
  const getColumnOrderList = async () => {
    try {
      const { data } = await getSelfConfigAPI()
      if (data.campaign_cols) {
        setColumns(data.campaign_cols)
      }
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedVerticals.length > 0 &&
      selectedVerticals.length < campagins.length
    setChecked(selectedVerticals.length === campagins.length)
    setIndeterminate(isIndeterminate)
    if (setCampaignSelected) {
      const selected = selectedVerticals.map((a) => a.id)
      setCampaignSelected(selected)
    }
  }, [selectedVerticals, campagins])

  const deleteRow = async () => {
    try {
      if (dataId) {
        setRowActionIndex(-1)
        await deleteCampaignAPI(dataId)
        getAllCampagins()
      }
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleDelete = (id) => {
    setDataId(id)
    setOpenConfirm(!openConfirm)
  }

  const changeCurrentStatus = async (id, status) => {
    try {
      const togg = status ? 'ACTIVE' : 'PAUSED'
      await updateCampaignDataAPI(id, { status: togg })
      const index = findIndex(campagins, { id })
      if (index >= 0) {
        campagins[index].status = status
        setAllCampaigns([...campagins])
      }
    } catch (error) {
      const message = (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors) || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleReset = () => {
    reset()
    setKeyword('')
    setSorted(null)
    getAllCampagins({ insights_start_date: moment().format('YYYY-MM-DD'), insights_end_date: moment().format('YYYY-MM-DD'), ad_account_id: null, status: null, offer_ids: null })
    dispatch(getStatsSummary({ start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD') }))
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      getAllCampagins({
        search: keyword
      })
    }
  }

  const handlePerPage = (e) => {
    setTotalPerPage(e)
    getAllCampagins({ limit: e.value })
  }

  const handleRefreshData = async (id) => {
    try {
      const {data} = await fetchCampaignDataAPI(id)
      const index = findIndex(campagins, { id })
      getAllCampagins()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getAllCampagins({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  const handleDuplicateData = (id) => {
    confirmAlert({
      title: "Confirm Duplication",
      message: "Are you sure you want to duplicate this campaign?",
      buttons: [
        {
          label: "Yes",
          onClick: () => createDuplicateCampaign(id)
        },
        {
          label: "No"
        }
      ]
    })
  }

  const handleFilteredStats = (values) => {
    const obj = { start_date: formik.values.insights_start_date, end_date: formik.values.insights_end_date }
    if (values.ad_account_id) {
      obj.ad_account_id = values.ad_account_id
    }
    if (values.offer_ids) {
      obj.offer_ids = values.offer_ids
    }
    if (values.status) {
      obj.campaign_status = values.status
    }
    dispatch(getStatsSummary(obj))
  }
 
  useEffect(() => {
    const cols = allColumns.map((items) => {
      if (items.value === 'id') {
        return {
          name: <div><span className="font-semibold ml-10">ID</span></div>,
          label: "ID",
          id: 'id',
          sortable: true,
          minWidth: '100px',
          omit: items.isActive,
          selector: row => <div className="flex justify-center">
            <span className="mr-4 cursor-pointer text-gray-500" onClick={() => handleDuplicateData(row.id)}><BiCopyAlt size="24" /></span>
            <span>{row.id}</span>
          </div>,
          reorder: true
        }
      }
      else if (items.value === 'status') {
        return {
          name: <div><span className="font-semibold">Status</span></div>,
          id: 'status',
          label: "Status",
          sortable: false,
          minWidth: '150px',
          omit: items.isActive,
          selector: row => <div className="flex justify-end">
            <Toggle
              isChecked={(row.status === 'ACTIVE')}
              onChange={status =>
                changeCurrentStatus(row.id, status)
              }
              disabled={row.status === 'ARCHIVED' || row.status === 'DELETED'}
            />
            <span className="ml-4 cursor-pointer text-gray-500" onClick={() => handleRefreshData(row.id)}><BiRefresh size="24" /></span>
          </div>,
          reorder: true
        }
      }
      else if (items.value === 'name') {
        return {
          name: <div><span className="font-semibold">Name</span></div>,
          id: 'name',
          label: "Name",
          sortable: true,
          minWidth: '250px',
          omit: items.isActive,
          selector: row => <span>{row.name}</span>,
          reorder: true,
          wrap: 'no'
        }
      }
      else if (items.value === 'ms_submitted_leads') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Submitted Leads</span><span>{stats?.ms_submitted_leads || 0}</span></div>,
          id: 'ms_submitted_leads',
          label: "Submitted Leads",
          sortable: true,
          minWidth: '180px',
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_submitted_leads}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_sold_leads') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Sold Leads</span><span>{stats?.ms_sold_leads || 0}</span></div>,
          id: 'ms_sold_leads',
          label: "Sold Leads",
          sortable: true,
          minWidth: '150px',
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_sold_leads}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_clicks') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Clicks</span><span>{stats?.ms_clicks || 0}</span></div>,
          id: 'ms_clicks',
          label: "Clicks",
          sortable: true,
          minWidth: '100px',
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_clicks}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_ctr') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">CTR</span><span>{(stats?.ms_ctr || 0).toFixed(2)}%</span></div>,
          id: 'ms_ctr',
          label: "CTR",
          sortable: true,
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_ctr.toFixed(2)}%</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_roi') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">ROI</span><span>{(stats?.ms_roi || 0).toFixed(2)}%</span></div>,
          id: 'ms_roi',
          label: "ROI",
          sortable: true,
          minWidth: '130px',
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_roi}%</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_conversion_rate') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Conv. Rate</span><span>{(stats?.ms_conversion_rate || 0).toFixed(2)}%</span></div>,
          id: 'ms_conversion_rate',
          label: "Conv. Rate",
          sortable: true,
          right: true,
          minWidth: '150px',
          omit: items.isActive,
          selector: row => (
            <span>
              {row.ms_conversion_rate.toFixed(2)}%
            </span>
          ),
          reorder: true
        }
      }
      else if (items.value === 'ms_sold_rate') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Sold Rate</span><span>{(stats?.ms_sold_rate || 0).toFixed(2)}%</span></div>,
          id: 'ms_sold_rate',
          label: "Sold Rate",
          sortable: true,
          minWidth: '130px',
          right: true,
          omit: items.isActive,
          selector: row => (
            <span>
              {row.ms_sold_rate.toFixed(2)}%
            </span>
          ),
          reorder: true
        }
      }
      else if (items.value === 'ms_epc') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">EPC</span><span>${stats?.ms_epc || 0}</span></div>,
          id: 'ms_epc',
          label: "EPC",
          sortable: true,
          minWidth: '130px',
          right: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_epc}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_lpvc') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">CPC</span><span>${stats?.ms_lpvc || 0}</span></div>,
          id: 'ms_lpvc',
          label: "CPC",
          sortable: true,
          minWidth: '130px',
          right: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_lpvc}</span>,
          reorder: true
        }
      }
      else if (items.value === 'spend') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Spend</span><span>${stats?.spend || 0}</span></div>,
          id: 'spend',
          label: "Spend",
          sortable: true,
          minWidth: '130px',
          right: true,
          omit: items.isActive,
          selector: row => <span>${row.spend}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_revenue') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Revenue</span><span>${stats?.ms_revenue || 0}</span></div>,
          id: 'ms_revenue',
          label: "Revenue",
          sortable: true,
          minWidth: '150px',
          right: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_revenue}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_profit') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Profit</span><span>${stats?.ms_profit || 0}</span></div>,
          id: 'ms_profit',
          label: "Profit",
          sortable: true,
          minWidth: '150px',
          right: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_profit}</span>,
          reorder: true
        }
      }
      else if (items.value === 'impressions') {
        return {
          name: <div className="flex flex-col text-right"><span className="font-semibold">Impressions</span><span>{stats?.impressions || 0}</span></div>,
          id: 'impressions',
          label: "Impressions",
          minWidth: '150px',
          sortable: true,
          right: true,
          omit: items.isActive,
          selector: row => <span>{row.impressions}</span>,
          reorder: true
        }
      }
      else {
        return items
      }
    })
    setColumnData(cols)

  }, [allColumns, stats])

  const customStyles = {
    headRow: {
      style: {
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '10px',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        boxShadow: '5px 1px 5px 1px #5E84C24F'
      }
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  const handleRowSelection = ({ selectedRows }) => {
    setSelectedVerticals(selectedRows)
  }

  const handleColumnOrder = async (col) => {
    const column = col.map(item => {
      return { label: item.label, value: item.id, isActive: item.omit }
    })
    await updateSelfConfigAPI({ campaign_cols: column })
    setColumns(column)
  }

  const handleAssignRules = () => {
    if (selectedVerticals && selectedVerticals.length > 0) {
      setShowTemplateModal(true)
    } else {
      toast.error("Please select campaigns")
      return false
    }
  }

  const conditionalRowStyles = [
    {
      when: row => row.ms_roi > 0,
      style: { backgroundColor: '#A5D6A7' }
    },
    {
      when: row => row.ms_roi === 0,
      style: { backgroundColor: '#FFFFFF' }
    },
    {
      when: row => row.ms_roi < 0,
      style: { backgroundColor: '#EF9A9A' }
    }
  ]

  return (
    <section className="mx-auto px-3 md:px-6 py-1 bg-gray-200">
      <ManageColumnsCompaginsModals
        open={showManageColumnsModal}
        setOpen={setShowManageColums}
        allColumns={allColumns}
        setColumns={setColumns}
      />
      <AssignedVerticalsToCompaginsModals
        open={showVerticalsModal}
        setOpen={SetShowVerticalsModal}
        vertical={selectedVerticals && selectedVerticals[0]}
        loadData={getAllCampagins}
      />
      <ApplyTemplateToCompagins
        open={showTemplateModal}
        setOpen={setShowTemplateModal}
        data={rulesTemplateData}
        selectedRows={selectedVerticals}
        type="campaigns"
      />
      <CreateRuleForCompaginsModal
        open={showCreateRuleModal}
        setOpen={setShowCreateRuleModal}
        campaigns={selectedVerticals}
      />
      <CreateCompagins
        open={showCreateCompaginModal}
        setOpen={setShowCreateCompaginModal}
        loadData={getAllCampagins}
      />
      <div>

        <SummartStats />

        <div className="mt-4 flex flex-col items-center md:flex-row justify-between w-full">
          <Link
            to={"/dashboard/campagins-structure"}
            className="my-2 md:my-0 px-7 py-2 bg-[#B6CC43] rounded-lg mr-0 md:mr-3 text-center text-white hover:text-white hover:no-underline font-semibold"
          >
            Create Campaign 
          </Link>
          <div className="w-[100px]">
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 }
              ]}
              onChange={e => handlePerPage(e)}
              value={totalPerPage}
            />
          </div>
          
          <Button
            className="my-2 md:my-0 px-7 py-2 bg-[#00B2B4] rounded-lg mr-3 ml-3 justify-center"
            onClick={() => handleAssignRules()}
          >
            Assign Rules
          </Button>
          <span className="relative flex rounded-lg shadow-sm my-4 lg:my-0">
            <input
              type="text"
              name="account-number"
              id="account-number"
              className="block w-full rounded-lg border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleSearch}
            />
            <span className="absolute flex items-center pr-3 top-2 right-0 cursor-pointer" onClick={() => getAllCampagins({ search: keyword })}>
              <BsSearch className="h-5 w-5 text-[#B6CC43]" aria-hidden="true" />
            </span>
          </span>
          
          <Button
            className="px-7 py-2 bg-[#B6CC43] rounded-lg ml-3"
            onClick={() => setShowManageColums(true)}
          >
            Manage Columns
          </Button>
          <form onSubmit={formik.handleSubmit} className="flex flex-col items-center md:flex-row">
            <div className="ml-3">
              <Input
                placeholder="Start Date"
                type="date"
                className="bg-white"
                name="insights_start_date"
                onChange={(e) => formik.setFieldValue("insights_start_date", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.insights_start_date}
                error={formik.errors.insights_start_date}
                touched={formik.touched.insights_start_date}
              />
            </div>
            <div className="ml-3">
              <Input
                placeholder="End Date"
                type="date"
                className="bg-white"
                name="insights_end_date"
                onChange={(e) => formik.setFieldValue("insights_end_date", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.insights_end_date}
                error={formik.errors.insights_end_date}
                touched={formik.touched.insights_end_date}
              />
            </div>
            <div className="ml-3">
              <button className="px-5 py-2 my-4 button-gradient rounded-lg text-white w-full">
                Apply
              </button>
            </div>
          </form>
          <div className="relative ml-3">
            <FilterComponent getAllData={getAllCampagins} adAccounts={adAccounts} handleFilteredStats={handleFilteredStats} formik={formikfilters} forwardedRef={forwardedFilterRef} />
          </div>
          <Button className="px-4 py-3 bg-[#DBD312] rounded-lg my-4 md:my-0 ml-3" onClick={() => handleReset()}>
            <BsArrowCounterclockwise />
          </Button>

        </div>

        <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
          <div className="-my-2 mx-0 md:-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative py-3" style={{ display: "grid" }}>
                <DataTable
                  columns={columnData}
                  data={campagins}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<div className="py-6"><Spinner /></div>}
                  customStyles={customStyles}
                  selectableRows
                  onSelectedRowsChange={handleRowSelection}
                  className='react-dataTable'
                  onColumnOrderChange={cols => handleColumnOrder(cols)}
                  onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                  highlightOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="750px"
                  persistTableHead
                  conditionalRowStyles={conditionalRowStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{campagins.length}</span>&nbsp;of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
        </div>
        <Pagination
          totalPages={Math.ceil(totalItems / totalPerPage.value)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          offset={25}
        />
      </div>
      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title="Delete Campaign?">
        <div class="md:flex items-center">
          <div class="mt-4 md:mt-0 md:text-left">
            <p class="text-sm text-gray-700 mt-1">Are you sure you want to delete this campaign?</p>
            <p class="text-sm text-gray-700 mt-1">This action cannot be undone.</p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end">
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={deleteRow}>Yes, Delete it</button>
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => { setOpenConfirm(!openConfirm); setRowActionIndex(-1) }}>Cancel</button>
        </div>
      </BaseModal>
    </section>
  )
}
