import { useState, useEffect, useRef } from "react"

import { Button } from "../../../components"
import { Campagins, Adset, AdCreative } from "../../../pages"
import { getFacebookAdAccounts } from "../../../service"
import { getOfferLists } from '../../../redux/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import { useFormik } from "formik"

export default function CampaignLists() {

  const childRef = useRef(null)
  const childRefFilter = useRef(null)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('campaigns')
  const [campaignSelected, setCampaignSelected] = useState([])
  const [adsetSelected, setAdsetSelected] = useState([])
  const [adAccounts, setAddAccounts] = useState([])

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  useEffect(() => {
    getFacebookAccount()
    dispatch(getOfferLists())
  }, [])

  const getFacebookAccount = async () => {
    try {
      const { data } = await getFacebookAdAccounts()
      if (data.length) {
        const ad_accounts = data.map((item) => {
          return { label: item.name, value: item.id }
        })
        setAddAccounts(ad_accounts)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
    }
  }

  const handleFormData = (values) => {
    if (childRef.current) {
      childRef.current.handleFormData(values)
    }
  }

  const handleFilterData = (values) => {
    if (childRefFilter.current) {
      childRefFilter.current.handleFilterData(values)
    }
  }

  const formik = useFormik({
    initialValues: {
      insights_start_date: moment().format('YYYY-MM-DD'),
      insights_end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFormData
  })

  const formikfilters = useFormik({
    initialValues: {
      offer_ids: null,
      ad_account_id: null,
      status: { label: "ACTIVE", value: "ACTIVE" }
    },
    onSubmit: handleFilterData
  })

  const handleReset = () => {
    formik.resetForm()
    formikfilters.resetForm()
  }

  return (
    <section className="min-h-screen bg-gray-200 pb-6">
      <div className="px-4 sm:px-6 lg:px-6">
      <div className="mt-12">
        <Button
          className={`my-2 md:my-0 px-8 py-3 ${(activeTab === 'campaigns') ? 'bg-white text-[#B6CC43]' : 'bg-[#B6CC43]'} text-lg rounded-lg rounded-b-none mr-3 disabled:opacity-60`}
          onClick={() => setActiveTab('campaigns')}
        >
          Campaigns
        </Button>
        <Button
          className={`my-2 md:my-0 px-8 py-3 ${(activeTab === 'adsets') ? 'bg-white text-[#B6CC43]' : 'bg-[#B6CC43]'} text-lg rounded-lg rounded-b-none mr-3`}
          onClick={() => setActiveTab('adsets')}
        >
          Ad Sets
        </Button>
        <Button
          className={`my-2 md:my-0 px-8 py-3 ${(activeTab === 'ads') ? 'bg-white text-[#B6CC43]' : 'bg-[#B6CC43]'} text-lg rounded-lg rounded-b-none mr-3 disabled:opacity-60`}
          onClick={() => setActiveTab('ads')}
        >
          Ads
        </Button>
      </div>

      {/* CAMPAIGNS */}
      {(activeTab === 'campaigns') &&
        <div className="bg-white rounded-xl rounded-t-none shadow-lg p-4">
          <Campagins 
            setCampaignSelected={setCampaignSelected} 
            adAccounts={adAccounts} 
            formik={formik} forwardedRef={childRef}
            formikfilters={formikfilters} forwardedFilterRef={childRefFilter}
            reset={handleReset}
          />
        </div>
      }

      {/* ADSETS */}
      {(activeTab === 'adsets') &&
        <div className="bg-white rounded-xl rounded-t-none shadow-lg p-4">
          <Adset 
            campaignSelected={campaignSelected} 
            setAdsetSelected={setAdsetSelected} 
            adAccounts={adAccounts} 
            formik={formik} forwardedRef={childRef}
            formikfilters={formikfilters} forwardedFilterRef={childRefFilter}
            reset={handleReset}
          />
        </div>
      }

      {/* AD CREATIVE */}
      {(activeTab === 'ads') &&
        <div className="bg-white rounded-xl rounded-t-none shadow-lg p-4">
          <AdCreative 
            campaignSelected={campaignSelected} 
            adsetSelected={adsetSelected} 
            adAccounts={adAccounts} 
            formik={formik} forwardedRef={childRef}
            formikfilters={formikfilters} forwardedFilterRef={childRefFilter}
            reset={handleReset}
          />
        </div>
      }
      </div>
    </section>
  )
}
