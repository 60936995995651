import { useEffect, useState } from "react"
import { Button, Dropdown, Spinner, Toggle, Pagination } from "../../../components"
import { useFormik } from "formik"
import { getRuleLogsAPI } from "../../../service"
import DataTable from 'react-data-table-component'
import moment from 'moment'
import 'moment-timezone'

export default function ActiveRulesLogs({ activeTab, adAccounts, campagins, adsets, ads }) {

  const [currentPageLogs, setCurrentPageLogs] = useState(0)
  const [ruleLogsData, setRuleLogsData] = useState([])
  const [ruleLogsCount, setRuleLogsCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPerPage, setTotalPerPage] = useState({ label: 25, value: 25 })
  const [sorted, setSorted] = useState(null)

  const handleFormData = async values => {
    try {
      let params = {}

      if (values.ad_account_id) {
        params.ad_account_id = values.ad_account_id.value
      }
      if (values.campaign_id) {
        params.campaign_id = values.campaign_id.value
      }
      if (values.ad_set_id) {
        params.ad_set_id = values.ad_set_id.value
      }
      if (values.ad_id) {
        params.ad_id = values.ad_id.value
      }

      getActiveRuleLogs(params)
    } catch (error) {
      console.log(error)
    }
  }

  const formiklogs = useFormik({
    initialValues: {
      ad_account_id: {},
      campaign_id: {},
      ad_set_id: {},
      ad_id: {}
    },
    onSubmit: handleFormData
  })

  // GET ACTIVE RULE LOGS
  const getActiveRuleLogs = async (param) => {
    try {
      let current = (currentPageLogs > 0) ? currentPageLogs : 0
      let obj = { offset: current, limit: totalPerPage.value }

      if (param && param.limit) {
        obj.limit = param.limit
      }

      if (param && param.ad_account_id === null) {

      } else if (param && param.ad_account_id) {
        obj.ad_account_id = param.ad_account_id
      } else if (formiklogs && formiklogs.values && formiklogs.values.ad_account_id && Object.keys(formiklogs.values.ad_account_id).length > 0) {
        obj.ad_account_id = formiklogs.values.ad_account_id.value
      }

      if (param && param.campaign_id === null) {

      } else if (param && param.campaign_id) {
        obj.campaign_id = param.campaign_id
      } else if (formiklogs && formiklogs.values && formiklogs.values.campaign_id && Object.keys(formiklogs.values.campaign_id).length > 0) {
        obj.campaign_id = formiklogs.values.campaign_id.value
      }

      if (param && param.ad_set_id === null) {

      } else if (param && param.ad_set_id) {
        obj.ad_set_id = param.ad_set_id
      } else if (formiklogs && formiklogs.values && formiklogs.values.ad_set_id && Object.keys(formiklogs.values.ad_set_id).length > 0) {
        obj.ad_set_id = formiklogs.values.ad_set_id.value
      }

      if (param && param.ad_id === null) {

      } if (param && param.ad_id) {
        obj.ad_id = param.ad_id
      } else if (formiklogs && formiklogs.values && formiklogs.values.ad_id && Object.keys(formiklogs.values.ad_id).length > 0) {
        obj.ad_id = formiklogs.values.ad_id.value
      }

      if (param && param.sort_by) {
        obj.sort_by = param.sort_by
      } else if (sorted) {
        obj.sort_by = sorted
      }

      setLoading(true)
      const { data } = await getRuleLogsAPI(obj)
      setLoading(false)
      if (param && param.limit === 'all') {
        setRuleLogsData(data)
        setRuleLogsCount(data.length)
      } else {
        setRuleLogsData(data.results)
        setRuleLogsCount(data.count)
      }

    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (activeTab === "logs") {
      getActiveRuleLogs()
    }
  }, [activeTab])

  useEffect(() => {
    getActiveRuleLogs()
  }, [currentPageLogs])

  const handlePerPage = (e) => {
    setTotalPerPage(e)
    getActiveRuleLogs({ limit: e.value })
  }

  const columns = [
    {
      name: "Rule Name",
      id: 'active_rule__rule_template__name',
      sortable: true,
      selector: row => <>
        {row?.active_rule && row?.active_rule.rule_template && row.active_rule.rule_template.name}
      </>
    },
    {
      name: "Apply To",
      sortable: false,
      selector: row => <span>
        {row?.active_rule?.meta_campaign ? `Campaign (${row?.active_rule?.meta_campaign?.id})` : (row?.active_rule?.remote_ad_set) ? `Adset (${row?.active_rule?.remote_ad_set?.id})` : (row?.active_rule?.remote_ad) ? `Ad (${row?.active_rule?.remote_ad?.id})` : ''}
      </span>
    },
    {
      name: "Status",
      id: 'active_rule__enabled',
      sortable: true,
      selector: row => <Toggle isChecked={row?.active_rule?.enabled} disabled />
    },
    {
      name: "Date Created",
      id: 'created',
      sortable: true,
      selector: row => <span>{moment(row.created).format('YYYY-MM-DD hh:mm A')}</span>
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  const handleReset = () => {
    formiklogs.resetForm()
    setTotalPerPage({ label: 25, value: 25 })
    getActiveRuleLogs({ limit: 25, ad_account_id: null, status: null, campaign_id: null, ad_set_id: null, ad_id: null })
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getActiveRuleLogs({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-lg p-4 mt-4">
        <form onSubmit={formiklogs.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 lg:gap-3 mb-4 items-center">
            <Dropdown
              placeholder="Account"
              options={adAccounts}
              onChange={(e) => formiklogs.setFieldValue("ad_account_id", e)}
              onBlur={formiklogs.handleBlur}
              value={formiklogs.values.ad_account_id}
              error={formiklogs.errors.ad_account_id}
              touched={formiklogs.touched.ad_account_id}
            />
            <Dropdown
              placeholder="Campaign"
              options={campagins}
              onChange={(e) => formiklogs.setFieldValue("campaign_id", e)}
              onBlur={formiklogs.handleBlur}
              value={formiklogs.values.campaign_id}
              error={formiklogs.errors.campaign_id}
              touched={formiklogs.touched.campaign_id}
            />
            <Dropdown
              placeholder="Adset"
              options={adsets}
              onChange={(e) => formiklogs.setFieldValue("ad_set_id", e)}
              onBlur={formiklogs.handleBlur}
              value={formiklogs.values.ad_set_id}
              error={formiklogs.errors.ad_set_id}
              touched={formiklogs.touched.ad_set_id}
            />
            <Dropdown
              placeholder="Ads"
              options={ads}
              onChange={(e) => formiklogs.setFieldValue("ad_id", e)}
              onBlur={formiklogs.handleBlur}
              value={formiklogs.values.ad_id}
              error={formiklogs.errors.ad_id}
              touched={formiklogs.touched.ad_id}
            />
            <Button className="button-gradient p-2 flex items-center justify-center h-10 mb-2" type="submit">
              Apply Filters
            </Button>
            <Button className="bg-[#DBD312] p-2 flex items-center justify-center h-10 mb-2" onClick={() => handleReset()}>
              Reset
            </Button>
          </div>
        </form>

        <div className="flex items-center justify-between mb-4">
          <div className="w-[100px]">
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: 'All', value: 'all' },
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 }
              ]}
              onChange={e => handlePerPage(e)}
              value={totalPerPage}
            />
          </div>
        </div>

        <div className="overflow-y-auto">
          <div className="relative grid py-3">
            <DataTable
              columns={columns}
              data={ruleLogsData}
              responsive={true}
              progressPending={loading}
              progressComponent={<Spinner />}
              customStyles={customStyles}
              className='react-dataTable'
              onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
            />
          </div>
        </div>

        <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
          <div className="flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{ruleLogsData.length}</span>&nbsp;of <span className="font-medium">{ruleLogsCount}</span> results
              </p>
            </div>
          </div>
          <Pagination
            totalPages={Math.ceil(ruleLogsCount / totalPerPage.value)}
            currentPage={currentPageLogs}
            setCurrentPage={setCurrentPageLogs}
            offset={totalPerPage.value}
          />
        </div>
      </div>
    </>
  )
}
