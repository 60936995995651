import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { LoginSocialFacebook } from "reactjs-social-login"
import { confirmAlert } from "react-confirm-alert"
import { CopyBlock, github } from "react-code-blocks"
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaTimes } from "react-icons/fa"
import _ from "lodash"
import { Input, Button, BaseModal, Dropdown, Checkbox } from "../../components"
import { AddTokenModal } from "../../components"

import {
  changePasswordAPI,
  addFacebookAccount,
  getFacebookAccounts,
  removeFacebookAccount,
  removeFacebookAdAccount,
  getTrackingScript,
  getProfileAPI,
  addFacebookAdAccount,
  getUsersAPI,
  getSelfConfigAPI,
  updateSelfConfigAPI
} from "../../service"
import { changePasswordSchema } from "../../helpers/validationSchema"
import { MdRemove } from "react-icons/md"
import { BsTrash } from "react-icons/bs"

export default function ChangePassword() {

  const [adAccounts, setAddAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(-1)
  const [viewAccounts, setViewAccounts] = useState(false)
  const [selectedAdAccount, setSelectedAdAccount] = useState({})
  const [codeSnippet, setCodeSnippet] = useState("")
  const [postBack, setPostBack] = useState("")
  const [profileData, setProfileData] = useState(null)
  const [users, setUsers] = useState([])
  const [userLists, setUserLists] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openInsertToken, setOpenInsertToken] = useState(false)
  const [currentElementId, setCurrentElementId] = useState(null)

  const submitHandler = async ({
    currentPassword: old_password,
    password: new_password1,
    confirmPassword: new_password2
  }) => {
    try {
      const response = await changePasswordAPI({
        old_password,
        new_password1,
        new_password2
      })

      toast.success("Password Updated")
      formik.resetForm()
      console.log("response", response)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const handleGetFacebookAccount = async () => {
    try {
      const { data } = await getFacebookAccounts()
      setAddAccounts(data)
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }
  
  const handleLoginWithFacebook = async ({ data }) => {
    try {
      const {
        data: { key }
      } = await addFacebookAccount({ access_token: data.accessToken })
      handleGetFacebookAccount()
    } catch (error) {
      const message = error?.response.data?.non_field_errors ? Object.values(error?.response.data) : error?.response.data?.detail ? error?.response.data?.detail : "something went wrong"
      console.log("ERROR", error)
      toast.error((message && message[0]) ? message[0][0] : message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const removeAdsAccountConfirm = (id, fbAccount = false) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            (fbAccount) ? handleRemoveFacebookAccount(id, true) : handleRemoveFacebookAccount(id, false)
        },
        {
          label: "No"
        }
      ]
    })
  }

  const handleRemoveFacebookAccount = async (id, deleteAdAccount = false) => {
    try {
      const { data } = deleteAdAccount ? await removeFacebookAdAccount(id) : await removeFacebookAccount(id)
      handleGetFacebookAccount()
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleGetTracking = async () => {
    try {
      const { data: data } = await getTrackingScript()
      if (data) {
        setCodeSnippet(data.script)
        setPostBack(data.postback)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
    }
  }

  // GET USER LISTS
  const getUsers = async () => {
    try {
      const { data } = await getUsersAPI({ organization_role: 'Normal' })
      const res = data.map(item => {
        return { label: `${item.first_name} ${item.last_name}`, value: item.id }
      })
      setUsers(res)
      setUserLists(res)
    } catch (error) {
      console.log("error?.response?", error.response)
    }
  }

  // GET PROFILE DATA
  const getProfileData = async () => {
    try {
      const { data } = await getProfileAPI()
      if (data && data?.organization_role === 'Admin') {
        getUsers()
      }
      setProfileData(data)
    } catch (error) {
      
    }
  }

  const handleAddAssign = async () => {
    try {
      if (selectedUser) {
        const arr = selectedAdAccount.assignees.map((item) => item.id)
        arr.push(selectedUser?.value)
        await addFacebookAdAccount(selectedAdAccount?.id, { assignees: arr })
        handleGetFacebookAccount()
        setOpenModal(false)
        toast.success("Assignee added!")
      }
    } catch (error) {

    }
  }

  const handleRemoveAssign = async (val) => {
    const arr = selectedAdAccount.assignees.filter((item) => item.id !== val.id).map((item) => item.id)
    const { data } = await addFacebookAdAccount(selectedAdAccount?.id, { assignees: arr })
    handleGetFacebookAccount()
    setSelectedAdAccount(data)
    toast.success("Assignee removed!")
  }

  useEffect(() => {
    if(selectedAdAccount && selectedAdAccount.assignees) {
      const filtered = users.filter(obj1 => !selectedAdAccount.assignees.some(obj2 => obj1.value === obj2.id));
      setUserLists(filtered);
    }
  }, [selectedAdAccount])

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: changePasswordSchema,
    onSubmit: submitHandler
  })

  const submitStructureHandler = async (values) => {
    try {
      await updateSelfConfigAPI({ ad_set_naming: values.adsetStructure, ad_naming: values.adStructure })
      toast.success("Naming structure saved!")
    } catch(e) {

    }
  }

  const formikstructure = useFormik({
    initialValues: {
      adsetStructure: "",
      adStructure: ""
    },
    onSubmit: submitStructureHandler
  })

  // GET SELF CONFIGS
  const getSelfConfigData = async () => {
    try {
      const { data } = await getSelfConfigAPI()
      if (data.ad_set_naming) {
        formikstructure.setFieldValue(`adsetStructure`, data.ad_set_naming)
      }
      if (data.ad_naming) {
        formikstructure.setFieldValue(`adStructure`, data.ad_naming)
      }
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  useEffect(() => {
    handleGetFacebookAccount()
    handleGetTracking()
    getProfileData()
    getSelfConfigData()
  }, [])

  const handleAdAccountAssign = (item) => {
    setSelectedAdAccount(item)
    setOpenModal(true)
  }

  const handleNamingChange = (e) => {
    if (e && currentElementId) {
      const input = document.getElementById(currentElementId)
      
      if (input) {
        const val = formikstructure.values[currentElementId]
        const cursorPosition = input.selectionStart
        const textBefore = val.substring(0, cursorPosition)
        const textAfter = val.substring(cursorPosition, val.length)
       
        formikstructure.setFieldValue(currentElementId, `${textBefore} ${e.value} ${textAfter}`)
      }
    }
  }

  return (
    <section className="mx-auto px-8 w-full min-h-screen bg-gray-200">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10 mb-10 flex items-start justify-center">
        <div className="bg-white rounded-xl shadow-lg p-4">
          <form onSubmit={formik.handleSubmit} className="pb-10 flex flex-col">
            <h1 className="text-xl font-bold pb-3 border-b px-5 pt-5">
              Change Password
            </h1>
            <div className="px-5 flex flex-col mt-3">
              <Input
                placeholder="Current Password"
                name="currentPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
                error={formik.errors.currentPassword}
                touched={formik.touched.currentPassword}
              />
              <Input
                name="password"
                placeholder="New Password"
                type={"password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.errors.password}
                touched={formik.touched.password}
              />
              <Input
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                touched={formik.touched.confirmPassword}
              />

              <button
                type="submit"
                className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white"
              >
                Change Password
              </button>
            </div>
          </form>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-4 min-h-full">
          <div className="pb-10 flex flex-col">
            <h1 className="text-xl font-bold pb-3 border-b px-5 pt-5">
              Manage Accounts
            </h1>
            <div>
              <div className="my-10">
                {adAccounts.map(({ name, email, ad_accounts, id }) => (
                  <>
                    <div
                      className={`flex items-center flex-col md:flex-row gap-x-3 justify-between cursor-pointer mx-4 pb-2 border-b my-3 p-2 ${
                        id === selectedAccount &&
                        "bg-gray-100 border rounded-md"
                      }`}
                      onClick={() => {
                        setSelectedAdAccount({})
                        setViewAccounts(false)
                        setSelectedAccount(id === selectedAccount ? -1 : id)
                      }}
                    >
                      <div className="flex">
                        <img src="/static/facebook.svg" />
                        <div className="flex flex-col ml-8">
                          <span>{name}</span>
                          <span className="text-xs font-light opacity-70">
                            {email}
                          </span>
                          <div
                            className="text-[#B6CC43] flex"
                            onClick={e => {
                              e.stopPropagation()
                              setViewAccounts(!viewAccounts)
                              setSelectedAccount(id)
                            }}
                          >
                            <span className="text-xs font-medium opacity-70 mr-1">
                              View Ad Accounts
                            </span>
                            {viewAccounts ? (
                              <FaArrowAltCircleUp />
                            ) : (
                              <FaArrowAltCircleDown />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center mt-6 md:mt-0">
                        <p className="font-light opacity-70 mt-2">Authorized</p>
                        <p>{(profileData && profileData.organization_role === 'Admin') && 
                          <Button
                            className={"bg-[#CC4B43] py-1 px-1 text-xs ml-5 shadow-sm"}
                            onClick={() => removeAdsAccountConfirm(id, false)}
                          >
                            Remove
                          </Button>}
                        </p>
                      </div>
                    </div>
                    {viewAccounts && selectedAccount === id && (
                      <div className="mx-7 border rounded-md border-[#B6CC43] p-3 h-40 overflow-auto">
                        <ul className="">
                          {ad_accounts.map(item => (
                            <li>
                              <div className={`my-3 flex items-center justify-between cursor-pointer border-b w-50 border-gray-200 pb-2`}>
                                <span className={` ${selectedAdAccount.account_id === item.account_id && "underline underline-offset-2"}`}>{item.name}</span> 
                                {(profileData && profileData.organization_role === 'Admin') && <>
                                  <span onClick={() => handleAdAccountAssign(item)} class="inline-flex items-center rounded-md bg-[#B6CC43] ml-4 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-green-500/10">Assign</span>
                                  {/* <span onClick={() => removeAdsAccountConfirm(item.id, false)} class="inline-flex items-center rounded-md bg-red-100 ml-2 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Remove</span> */}
                                </>}
                              </div>
                            </li>
                          ))}
                        </ul>

                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="flex mt-3 justify-center">
              <LoginSocialFacebook
                appId={"602949464983958" || ""}
                fieldsProfile={
                  "id,first_name,last_name,middle_name,name,name_format,short_name,email"
                }
                scope="ads_management,public_profile,email,pages_show_list,pages_manage_ads,business_management"
                onLoginStart={console.log}
                onLogoutSuccess={console.log}
                onResolve={handleLoginWithFacebook}
                onReject={err => {
                  console.log(err)
                }}
              >
                <Button
                  className={"bg-[#B6CC43] mr-2 py-2 pr-10 pl-10 shadow-sm"}
                >
                  Add
                </Button>
              </LoginSocialFacebook>

            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-4 min-h-full">
          <div className="pb-10 flex flex-col">
            <h1 className="text-xl font-bold pb-3 border-b px-5 pt-5">
              Conversion Tracking
            </h1>
            <div className="px-5 flex flex-col mt-3">
              <label className="text-sm font-semibold text-gray-900 mb-3">
                Postback URL
              </label>
              <div className="text-gray-400 border border-1">
                <CopyBlock
                  language="go"
                  text={postBack}
                  codeBlock
                  theme={github}
                  showLineNumbers={false}
                />
              </div>
              <h5 className="mt-6 mb-2">Instructions:</h5>
              <p>{`Please replace <YOUR_AD_ID> with the ad ID value passed to the landing page via the “<name of URL parameter used>” URL parameter`}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-4 min-h-full">
          <div className="pb-10 flex flex-col">
            <h1 className="text-xl font-bold pb-3 border-b px-5 pt-5">
              Click Tracking
            </h1>
            <div className="px-5 flex flex-col mt-3">
              <label className="text-sm font-semibold text-gray-900 mb-3">
                JS Code Snippet
              </label>
              <div className="text-gray-400 border border-1">
                <CopyBlock
                  language="go"
                  text={codeSnippet}
                  codeBlock
                  theme={github}
                  showLineNumbers={false}
                />
              </div>
              <h5 className="mt-6 mb-2">Instructions:</h5>
              <p>{`Please replace the YOUR_AD_ID_PARAMETER value with the name of the URL parameter used on the landing page for indicating which ad ID generated this click.`}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-4">
          <form onSubmit={formikstructure.handleSubmit} className="pb-10 flex flex-col">
            <h1 className="text-xl font-bold pb-3 border-b px-5 pt-5">
              Campaign Naming Structure
            </h1>
            <div className="px-5 flex flex-col mt-3">
              {/* <Input
                placeholder="Enter Campaign Structure"
                name="campaignStructure"
                type="text"
                onChange={formikstructure.handleChange}
                onBlur={formikstructure.handleBlur}
                value={formikstructure.values.campaignStructure}
                error={formikstructure.errors.campaignStructure}
                touched={formikstructure.touched.campaignStructure}
              /> */}
              <p className="text-xs font-semibold mt-3">Adset Structure</p>
              <Input
                name="adsetStructure"
                id="adsetStructure"
                placeholder="Enter Adset Structure"
                type={"text"}
                onChange={formikstructure.handleChange}
                onFocus={(e) => setCurrentElementId("adsetStructure")}
                onBlur={formikstructure.handleBlur}
                value={formikstructure.values.adsetStructure}
                error={formikstructure.errors.adsetStructure}
                touched={formikstructure.touched.adsetStructure}
              />
              <p className="text-xs font-semibold mt-3">Ad Structure</p>
              <Input
                name="adStructure"
                id="adStructure"
                placeholder="Enter Ad Structure"
                type="text"
                onChange={formikstructure.handleChange}
                onFocus={(e) => setCurrentElementId("adStructure") }
                onBlur={formikstructure.handleBlur}
                value={formikstructure.values.adStructure}
                error={formikstructure.errors.adStructure}
                touched={formikstructure.touched.adStructure}
              />
              
              <div className="flex items-center justify-between">
                <p className="font-semibold text-sm text-[#B6CC43] cursor-pointer underline" onClick={() => setOpenInsertToken(true)}>Add Token</p>
                <button
                  type="submit"
                  className="px-5 py-2 my-5 button-gradient rounded-xl font-bold text-white"
                >
                  Save Structure
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <BaseModal open={openModal} setOpen={setOpenModal} title={`Assign Ad Account`}>
        {(selectedAdAccount && selectedAdAccount.assignees && selectedAdAccount.assignees.length > 0) && <>
          <div className="my-5 grid grid-cols-2 gap-x-5">
            {selectedAdAccount.assignees.map((item) => (
              <>
                <div className="bg-[#00B2B4] px-3 py-2 rounded-md font-medium text-white ring-1 ring-inset ring-gray-500/10 flex justify-between items-center">
                  <span>{`${item.first_name} ${item.last_name}`}</span>
                  <FaTimes size="16" className="text-white cursor-pointer" onClick={() => handleRemoveAssign(item)} />
                </div>
              </>
            ))}
          </div>
          <hr />
          </>
        }
        <Dropdown
          placeholder="Users"
          name="action"
          options={userLists}
          onChange={(e) => setSelectedUser(e)}
        />
        <div className="mt-6 sm:mt-6 sm:flex sm:flex-row justify-end">
          <Button
            type="button"
            className={"bg-[#CC4B43] mr-2 py-2 px-6 shadow-sm"}
            onClick={() => {
              setOpenModal(!openModal)
            }}
          >
            Close
          </Button>
          <Button
            className={"bg-[#B6CC43] mr-2 py-2 px-6 shadow-sm"}
            onClick={() => {
              handleAddAssign()
            }}
          >
            Save
          </Button>
        </div>
      </BaseModal>

      <AddTokenModal
        open={openInsertToken}
        setOpen={setOpenInsertToken}
        handleChange={handleNamingChange}
      />
    </section>
  )
}
