import { useEffect, useState } from "react"
import { Button, Dropdown, Pagination, Spinner, Input } from "../../components"
import { useFormik } from "formik"
import { getAllClickTracksAPI, getAllConversionsAPI, getUsersAPI, getFacebookAdAccounts, getLandingPagesAPI } from "../../service"
import { getOfferLists } from "../../redux/actions/common"
import moment from 'moment'
import 'moment-timezone'
import DataTable from 'react-data-table-component'
import {
  BsArrowCounterclockwise
} from "react-icons/bs"

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'

export default function LogManagement() {

  const dispatch = useDispatch()
  const { offerList } = useSelector(({ common }) => common)
  const [currentPageClicks, setCurrentPageClicks] = useState(0)
  const [currentPageConversions, setCurrentPageConversions] = useState(0)
  const [activeTab, setActiveTab] = useState('clicks')
  const [clicksCount, setClicksCount] = useState(0)
  const [clicksData, setClicksData] = useState([])
  const [conversionsCount, setConversionsCount] = useState(0)
  const [conversionsData, setConversionsData] = useState([])
  const [adAccounts, setAddAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [offers, setOffers] = useState([])
  const [userLists, setUserLists] = useState([])
  const [landingPages, setLandingPages] = useState([])
  const [sorted, setSorted] = useState(null)
  const [sortedConv, setSortedConv] = useState(null)

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  useEffect(() => {
    if (activeTab === "clicks") {
      getAllClickTracks()
    } else if (activeTab === "conversions") {
      getAllConversions()
    }
  }, [activeTab])

  const getUserLists = async () => {
    try {
      const { data } = await getUsersAPI()
      const res = data.map(item => {
        return { label: `${item.first_name} ${item.last_name}`, value: item.id }
      })
      setUserLists(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  // GET ALL CLICK TRACKS
  const getAllClickTracks = async (param) => {
    try {
      let current = (currentPageClicks > 0) ? currentPageClicks : 0
      let obj = { offset: current, limit: 25 }

      if (param && param.accounts) {
        obj.ad_account_id = param.accounts.value
      } else if (formik && formik.values && formik.values.accounts && Object.keys(formik.values.accounts).length > 0) {
        obj.ad_account_id = formik.values.accounts.value
      }

      if (param && param.offers) {
        obj.offer_ids = param.offers.value
      } else if (formik && formik.values && formik.values.offers && Object.keys(formik.values.offers).length > 0) {
        obj.offer_ids = formik.values.offers.value
      }

      if (param && param.buyer) {
        obj.user_ids = param.buyer.value
      } else if (formik && formik.values && formik.values.buyer && Object.keys(formik.values.buyer).length > 0) {
        obj.user_ids = formik.values.buyer.value
      }

      if (param && param.landingpages) {
        obj.landing_page_ids = param.landingpages.value
      } else if (formik && formik.values && formik.values.landingpages && Object.keys(formik.values.landingpages).length > 0) {
        obj.landing_page_ids = formik.values.landingpages.value
      }

      if (param && param.start_date) {
        obj.start_date = param.start_date
      } else if (formik && formik.values && formik.values.start_date) {
        obj.start_date = formik.values.start_date
      }

      if (param && param.end_date) {
        obj.end_date = param.end_date
      } else if (formik && formik.values && formik.values.end_date) {
        obj.end_date = formik.values.end_date
      }

      if (param && param.sort_by) {
        obj.sort_by = param.sort_by
      } else if (sorted) {
        obj.sort_by = sorted
      }

      setLoading(true)
      const { data } = await getAllClickTracksAPI(obj)
      setLoading(false)
      setClicksData(data.results)
      setClicksCount(data.count)
    } catch (error) {
      console.log("error?.response?", error)
    }
  }

  // GET ALL CONVERSIONS
  const getAllConversions = async (param) => {
    try {
      let current = (currentPageConversions > 0) ? currentPageConversions : 0
      let obj = { offset: current, limit: 25 }

      if (param && param.accounts) {
        obj.ad_account_id = param.accounts.value
      } else if (formikconv && formikconv.values && formikconv.values.accounts && Object.keys(formikconv.values.accounts).length > 0) {
        obj.ad_account_id = formikconv.values.accounts.value
      }

      if (param && param.offers) {
        obj.offer_ids = param.offers.value
      } else if (formikconv && formikconv.values && formikconv.values.offers && Object.keys(formikconv.values.offers).length > 0) {
        obj.offer_ids = formikconv.values.offers.value
      }

      if (param && param.buyer) {
        obj.user_ids = param.buyer.value
      } else if (formikconv && formikconv.values && formikconv.values.buyer && Object.keys(formikconv.values.buyer).length > 0) {
        obj.user_ids = formikconv.values.buyer.value
      }

      if (param && param.landingpages) {
        obj.landing_page_ids = param.landingpages.value
      } else if (formikconv && formikconv.values && formikconv.values.landingpages && Object.keys(formikconv.values.landingpages).length > 0) {
        obj.landing_page_ids = formikconv.values.landingpages.value
      }

      if (param && param.start_date) {
        obj.start_date = param.start_date
      } else if (formikconv && formikconv.values && formikconv.values.start_date) {
        obj.start_date = formikconv.values.start_date
      }

      if (param && param.end_date) {
        obj.end_date = param.end_date
      } else if (formikconv && formikconv.values && formikconv.values.end_date) {
        obj.end_date = formikconv.values.end_date
      }

      if (param && param.sort_by) {
        obj.sort_by = param.sort_by
      } else if (sortedConv) {
        obj.sort_by = sortedConv
      }
      
      setLoading(true)
      const { data } = await getAllConversionsAPI(obj)
      setLoading(false)
      setConversionsData(data.results)
      setConversionsCount(data.count)
    } catch (error) {
      // console.log("error?.response?", error.response)
    }
  }

  const handleGetFacebookAccount = async () => {
    try {
      const { data } = await getFacebookAdAccounts()
      if (data.length) {
        const ad_accounts = data.map((item) => {
          return { label: item.name, value: item.id }
        })
        setAddAccounts(ad_accounts)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
    }
  }

  const getLandingPages = async () => {
    try {
      const { data } = await getLandingPagesAPI()
      setLandingPages(data.map(({ id, name }) => ({ label: name, value: id })))

      if (data.length) {
        const newOffers = data?.map(item => {
          return { label: item.name, value: item.id }
        })
        setLandingPages(newOffers)
      }
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
    }
  }

  useEffect(() => {
    dispatch(getOfferLists())
    getUserLists()
    handleGetFacebookAccount()
    getLandingPages()
  }, [])

  useEffect(() => {
    getAllClickTracks()
  }, [currentPageClicks])

  useEffect(() => {
    getAllConversions()
  }, [currentPageConversions])

  useEffect(() => {
    if (offerList) {
      const res = offerList.map(item => {
        return { label: item.name, value: item.id }
      })
      setOffers(res)
    }
  }, [offerList])

  const submitHandler = async (values) => {
    try {
      getAllClickTracks(values)
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      buyer: {},
      offers: {},
      accounts: {},
      landingpages: {},
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: submitHandler
  })

  const submitHandlerConv = async values => {
    try {
      getAllConversions(values)
    } catch (error) {
      console.log(error)
    }
  }

  const formikconv = useFormik({
    initialValues: {
      buyer: {},
      offers: {},
      accounts: {},
      landingpages: {},
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: submitHandlerConv
  })

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  const handleReset = () => {
    formik.resetForm()
    getAllClickTracks()
  }

  const handleResetConv = () => {
    formikconv.resetForm()
    getAllConversions()
  }

  const columnClicks = [
    {
      name: "ClickId",
      id: "id",
      selector: row => <span>{row.id}</span>,
      sortable: true,
      right: true
    },
    {
      name: "MS ADID",
      id: "remote_ad_id",
      selector: row => <>{row.remote_ad && row.remote_ad.id}</>,
      sortable: true,
      right: true
    },
    {
      name: "FB AD Id",
      id: "remote_ad__remote_id",
      minWidth: '200px',
      selector: row => <>{row.remote_ad && row.remote_ad.remote_id}</>,
      sortable: true,
      right: true
    },
    {
      name: "Unique",
      id: "is_unique",
      selector: row => <>{(row.is_unique) ? 'Yes' : 'No'}</>,
      sortable: true,
      right: true
    },
    {
      name: "Click Time",
      id: "created_at",
      minWidth: '150px',
      selector: row => <>{moment(row.created_at).format('YYYY-MM-DD HH:mm')}</>,
      sortable: true,
      right: true
    },
    {
      name: "Bot Traffic",
      id: "is_bot",
      selector: row => <>{(row.is_bot) ? 'Yes' : 'No'}</>,
      sortable: true,
      right: true
    },
    {
      name: "IP Address",
      id: "ip_addr",
      selector: row => <>{row.ip_addr}</>,
      sortable: true,
      right: true
    },
    {
      name: "Media Buyer",
      id: "remote_ad__remote_ad_set__meta_campaign__user__first_name",
      selector: row => <>{row.user && `${row.user.first_name} ${row.user.last_name}`}</>,
      sortable: true,
      right: true
    },
    {
      name: "Landing Page",
      minWidth: '200px',
      id: "remote_ad__remote_ad_set__meta_campaign__landing_page__name",
      selector: row => <>{row.landing_page && row.landing_page.name}</>,
      sortable: true,
      right: true
    },
    {
      name: "Offer",
      id: "remote_ad__remote_ad_set__meta_campaign__landing_page__offer__name",
      selector: row => <>{row.landing_page && row.landing_page.offer && row.landing_page.offer.name}</>,
      sortable: true,
      right: true
    }
  ]

  const columnConversions = [
    {
      name: "Id",
      id: "id",
      selector: row => <span>{row.id}</span>,
      sortable: true,
      right: true
    },
    {
      name: "MS_ADID",
      id: "remote_ad_id",
      selector: row => <>{row.remote_ad && row.remote_ad.id}</>,
      sortable: true,
      right: true
    },
    {
      name: "FB AD Id",
      id: "remote_ad__remote_id",
      minWidth: '200px',
      selector: row => <>{row.remote_ad && row.remote_ad.remote_id}</>,
      sortable: true,
      right: true
    },
    {
      name: "Conversion Time",
      id: "created_at",
      minWidth: '150px',
      selector: row => <>{moment(row.created_at).format('YYYY-MM-DD HH:mm')}</>,
      sortable: true,
      right: true
    },
    {
      name: "Media Buyer",
      id: "remote_ad__remote_ad_set__meta_campaign__user__first_name",
      sortable: false,
      selector: row => <>{row.user && `${row.user.first_name} ${row.user.last_name}`}</>,
      sortable: true,
      right: true
    },
    {
      name: "Landing Page",
      id: "remote_ad__remote_ad_set__meta_campaign__landing_page__name",
      minWidth: '200px',
      selector: row => <>{row.landing_page && row.landing_page.name}</>,
      sortable: true,
      right: true
    },
    {
      name: "Offer",
      id: "remote_ad__remote_ad_set__meta_campaign__landing_page__offer__name",
      sortable: false,
      selector: row => <>{row.landing_page && row.landing_page.offer && row.landing_page.offer.name}</>,
      sortable: true,
      right: true
    }
  ]

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getAllClickTracks({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  const handleSortingConv = (col, direction) => {
    setSortedConv(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getAllConversions({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  return (
    <section className="w-full mx-auto px-3 min-h-screen bg-gray-200">
      <div className="px-0 sm:px-6 lg:px-8">
        <div className="mt-12 mb-6">
          <Button
            className={`my-2 md:my-0 px-7 py-3 ${(activeTab === 'clicks') ? 'bg-[#B6CC43]' : 'bg-white text-[#B6CC43]'} hover:bg-[#B6CC43] hover:text-white text-lg rounded-lg mr-3 disabled:opacity-60`}
            onClick={() => setActiveTab('clicks')}
          >
            Clicks
          </Button>
          <Button
            className={`my-2 md:my-0 px-7 py-3 ${(activeTab === 'conversions') ? 'bg-[#B6CC43]' : 'bg-white text-[#B6CC43]'} hover:bg-[#B6CC43] hover:text-white text-lg rounded-lg mr-3`}
            onClick={() => setActiveTab('conversions')}
          >
            Conversions
          </Button>
        </div>

        {/* CLICKS */}
        {(activeTab === 'clicks') &&
          <div className="bg-white rounded-xl shadow-lg p-4 mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-7 lg:gap-7 mb-4 items-end mt-4">
                <label class="block">
                  <span className="font-semibold text-gray-500">Accounts</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={adAccounts}
                    onChange={value => formik.setFieldValue("accounts", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.accounts}
                    error={formik.errors.accounts}
                    touched={formik.touched.offers}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Landing Pages</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={landingPages}
                    onChange={value => formik.setFieldValue("landingpages", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.landingpages}
                    error={formik.errors.landingpages}
                    touched={formik.touched.landingpages}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Offers</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={offers}
                    onChange={value => formik.setFieldValue("offers", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.offers}
                    error={formik.errors.offers}
                    touched={formik.touched.offers}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Media Buyer</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={userLists}
                    onChange={value => formik.setFieldValue("buyer", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.buyer}
                    error={formik.errors.buyer}
                    touched={formik.touched.buyer}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Start Date</span>
                  <Input className="bg-white" placeholder="Start Date" type="date" name="start_date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.start_date}
                    error={formik.errors.start_date}
                    touched={formik.touched.start_date}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">End Date</span>
                  <Input className="bg-white" placeholder="End Date" type="date" name="end_date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.end_date}
                    error={formik.errors.end_date}
                    touched={formik.touched.end_date}
                  />
                </label>
                <div className="flex items-center">
                  <button className="w-full px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white" type="submit">
                    Apply
                  </button>
                  <Button className="px-4 py-3 bg-[#DBD312] rounded-lg my-4 md:my-0 ml-3" onClick={() => handleReset()}>
                    <BsArrowCounterclockwise />
                  </Button>
                </div>
              </div>
            </form>
            <div className="overflow-y-auto">
              <div className="relative grid py-3">
                <DataTable
                  columns={columnClicks}
                  data={clicksData}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<Spinner />}
                  customStyles={customStyles}
                  className='react-dataTable'
                  onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                />
              </div>
            </div>

            <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
              <div className="flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{clicksData.length}</span>&nbsp;of <span className="font-medium">{clicksCount}</span> results
                  </p>
                </div>
              </div>
              <Pagination
                totalPages={Math.ceil(clicksCount / 25)}
                currentPage={currentPageClicks}
                setCurrentPage={setCurrentPageClicks}
                offset={25}
              />
            </div>
          </div>
        }

        {/* CONVERSIONS */}
        {(activeTab === 'conversions') &&
          <div className="bg-white rounded-xl shadow-lg p-4 mt-4">
            <form onSubmit={formikconv.handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-7 lg:gap-7 mb-4 items-end mt-4">
                <label class="block">
                  <span className="font-semibold text-gray-500">Accounts</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={adAccounts}
                    onChange={value => formikconv.setFieldValue("accounts", value)}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.accounts}
                    error={formikconv.errors.accounts}
                    touched={formikconv.touched.accounts}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Landing Pages</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={landingPages}
                    onChange={value => formikconv.setFieldValue("landingpages", value)}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.landingpages}
                    error={formikconv.errors.landingpages}
                    touched={formikconv.touched.landingpages}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Offers</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={offers}
                    onChange={value => formikconv.setFieldValue("offers", value)}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.offers}
                    error={formikconv.errors.offers}
                    touched={formikconv.touched.offers}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Media Buyer</span>
                  <Dropdown
                    placeholder="Select"
                    classBox="bg-white mb-0"
                    options={userLists}
                    onChange={value => formikconv.setFieldValue("buyer", value)}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.buyer}
                    error={formikconv.errors.buyer}
                    touched={formikconv.touched.buyer}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">Start Date</span>
                  <Input className="bg-white" placeholder="Start Date" type="date" name="start_date"
                    onChange={formikconv.handleChange}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.start_date}
                    error={formikconv.errors.start_date}
                    touched={formikconv.touched.start_date}
                  />
                </label>
                <label class="block">
                  <span className="font-semibold text-gray-500">End Date</span>
                  <Input className="bg-white" placeholder="End Date" type="date" name="end_date"
                    onChange={formikconv.handleChange}
                    onBlur={formikconv.handleBlur}
                    value={formikconv.values.end_date}
                    error={formikconv.errors.end_date}
                    touched={formikconv.touched.end_date}
                  />
                </label>
                <div className="flex items-center">
                  <button className="w-full px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white" type="submit">
                    Apply
                  </button>
                  <Button className="px-4 py-3 bg-[#DBD312] rounded-lg my-4 md:my-0 ml-3" onClick={() => handleResetConv()}>
                    <BsArrowCounterclockwise />
                  </Button>
                </div>
              </div>
            </form>
            <div className="overflow-y-auto">
              <div className="relative grid py-3">
                <DataTable
                  columns={columnConversions}
                  data={conversionsData}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<Spinner />}
                  customStyles={customStyles}
                  className='react-dataTable'
                  onSort={(selectedColumn, sortDirection) => handleSortingConv(selectedColumn, sortDirection)}
                />
              </div>
            </div>

            <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
              <div className="flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{conversionsData.length}</span>&nbsp;of <span className="font-medium">{conversionsCount}</span> results
                  </p>
                </div>
              </div>
              <Pagination
                totalPages={Math.ceil(conversionsCount / 25)}
                currentPage={currentPageConversions}
                setCurrentPage={setCurrentPageConversions}
                offset={25}
              />
            </div>

          </div>
        }

      </div>

    </section>
  )
}
