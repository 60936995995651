import React, { Component } from "react";

export default function Feeds() {

  
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between py-4 w-full border-b border-gray-200">
        <div className="flex">
          <img
            alt="graph-green"
            src="/static/icons/graph-green.svg"
            className="h-4 w-6"
          />
          <span className="ml-3">2% increase in conversion rates</span>
        </div>
        <span className="text-right text-gray-400">2 mins ago</span>
      </div>
      <div className="flex items-center justify-between py-4 w-full border-b border-gray-200">
        <div className="flex">
          <img
            alt="graph-green"
            src="/static/icons/graph-red.svg"
            className="h-4 w-6"
          />
          <span className="ml-3">Budget decreased up to 100$</span>
        </div>
        <span className="text-right text-gray-400">2 mins ago</span>
      </div>
      <div className="flex items-center justify-between py-4 w-full border-b border-gray-200">
        <div className="flex">
          <img
            alt="graph-green"
            src="/static/icons/graph-green.svg"
            className="h-4 w-6"
          />
          <span className="ml-3">Adset1 is now active</span>
        </div>
        <span className="text-right text-gray-400">2 mins ago</span>
      </div>
      <div className="flex items-center justify-between py-4 w-full border-b border-gray-200">
        <div className="flex">
          <img
            alt="graph-green"
            src="/static/icons/graph-green.svg"
            className="h-4 w-6"
          />
          <span className="ml-3">2% increase in conversion rates</span>
        </div>
        <span className="text-right text-gray-400">2 mins ago</span>
      </div>
      <div className="flex items-center justify-between py-4 w-full border-b border-gray-200">
        <div className="flex">
          <img
            alt="graph-green"
            src="/static/icons/graph-red.svg"
            className="h-4 w-6"
          />
          <span className="ml-3">Budget decreased up to 100$</span>
        </div>
        <span className="text-right text-gray-400">2 mins ago</span>
      </div>
    </div>
  )
}
