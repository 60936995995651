import { useState } from "react"
import _ from "lodash"
import { BsSearch, BsThreeDotsVertical } from "react-icons/bs"
import { MdKeyboardArrowDown } from "react-icons/md"
import { VscReply } from "react-icons/vsc"

import { Button } from "../../components"

const tabs = [{ label: "Inbox" }, { label: "Unread" }, { label: "Archived" }]
const UserChats = [
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago",
    image: "/static/google.svg"
  },
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago",
    image: "/static/facebook.svg"
  },
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago",
    image: "/static/google.svg"
  },
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago"
  },
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago",
    image: "/static/google.svg"
  },
  {
    title: "Wasif Jameel",
    message:
      "Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    time: "1m ago",
    image: "/static/google.svg"
  }
]
export default function Feedback() {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <section className="container max-w-6xlxl mx-auto">
      <div className="px-4 sm:px-6 lg:px-8 mt-10">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex flex-col gap-y-7">
            <div className="px-8 py-4 bg-white rounded-xl shadow-md flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <img src="/static/google.svg" />
                <span>Roberto Charloz</span>
              </div>
              <Button className="border-bg bg-[#B6CC43] py-1.5 px-3 rounded-bl-full rounded-tr-full rounded-br-full rounded-tl-full">
                View Detail
              </Button>
            </div>

            <div className="bg-white shadow-md rounded-2xl rounded-t-xl">
              <div className="py-6 mx-2 flex flex-row justify-between  bg-[#FEFEFE] shadow-sm rounded-xl gap-x-7">
                <ul className=" flex rounded-full bg-[#F0F0F0] px-1 py-1 justify-center">
                  {tabs.map((tab, index) => (
                    <li
                      key={index}
                      className={`mr-4 rounded-full px-4 ${
                        index === activeTab ? "bg-white" : ""
                      }`}
                    >
                      <a
                        className="text-gray-600 cursor-pointer"
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="flex items-center gap-x-2">
                  <BsSearch
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <button>
                    <BsThreeDotsVertical />
                  </button>
                </div>
              </div>
              <div className="p-2 w-full md:max-w-sm overflow-y-scroll max-h-96">
                {UserChats.map(data => (
                  <UserMessage {...data} />
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white flex-grow rounded-xl shadow-md">
            <div className="px-4 py-6 shadow-sm">
              <p>This is Subject Line. Here the Subject will be displayed</p>
            </div>
            <div className="mt-10 mx-10">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div>
                    <img src="/static/google.svg" />
                  </div>
                  <div>
                    <p className="font-semibold text-xs">
                      Wasif Jameel{" "}
                      <span className="font-thin text-xs">Today</span>
                    </p>
                    <p className="font-thin text-xs flex items-center">
                      to me <MdKeyboardArrowDown />
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-x-2">
                  <VscReply className="h-5 w-5" />
                  <button>
                    <BsThreeDotsVertical />
                  </button>
                </div>
              </div>
              <p className="mt-10 font-light">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatSed ut
                perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptat{" "}
              </p>
              <p className="mt-10 font-light text-xs">4.30 AM</p>
              <div className=" border-gray-200 bg-white border-2 mx-2 rounded-md my-5">
                <div className="p-4">
                  <textarea
                    className="h-32 w-full p-2 focus:outline-none border-none focus:shadow-none focus:ring-0"
                    placeholder="Type your reply here..."
                  />
                  <div className="p-4 flex items-end justify-end">
                    <Button className="border-bg bg-[#B6CC43] py-1.5 px-3 ">
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const UserMessage = ({ title, message, image, time }) => (
  <div className="bg-white p-4 md:p-2 my-2">
    <div className="flex flex-col md:flex-row">
      <div className="w-12">
        {image ? (
          <img src={image} className="w-10 h-10" />
        ) : (
          <div className="w-10 h-10 bg-[#C4C4C4] rounded-full" />
        )}
      </div>
      <div className="w-full ml-2">
        <div className="flex items-center justify-between">
          <h2 className="font-normal text-sm mb-1">{title}</h2>
          <p className="text-xs font-light mb-1 text-[#898989]">{time}</p>
        </div>
        <p className="text-gray-600 text-xs font-light">{message}</p>
      </div>
    </div>
  </div>
)
