import { useState, useEffect } from "react"
import _ from "lodash"
import { Button, Dropdown, Input, Toggle, Pagination, Tabs, Checkbox, BaseModal, TestSopModal, TestConfigModal, TestSaveTemplateModal, Spinner } from "../../../components"
import { MdOutlineArrowCircleDown } from "react-icons/md"
import { Link, useNavigate } from "react-router-dom"
import Graph from "./chartjs"
import Breakdown from "./breakdown"
import { BiPlus } from "react-icons/bi"
import { useFormik } from "formik"
import { getCampaginsAPI, getAdCreativeAPI, getUsersAPI, updateTestPhase } from "../../../service"
import { getOfferLists } from '../../../redux/actions/common'
import { DEFINED_KPIS } from "../../../utils/constants"

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import { toast } from "react-toastify"

export default function CampaignTesting() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { offerList } = useSelector(({ common }) => common)
  const [open, setOpen] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [openSOP, setOpenSOP] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [openSaveTemplate, setOpenSaveTemplate] = useState(false);
  const [openSelectTemplate, setOpenSelectTemplate] = useState(false);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [openAdvancePreview, setOpenAdvancePreview] = useState(false);
  const [loading, setLoading] = useState(false)
  const [testCampagins, setAllTestCampaigns] = useState([])
  const [totalItems, setCampaignsCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPhases, setTotalPhases] = useState(0)
  const [testModuleId, setTestModuleId] = useState(null)
  const [adsList, setAdsList] = useState([])
  const [adLoading, setAdLoading] = useState(false)
  const [offers, setOffers] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [winnerAds, setWinnerAds] = useState([]);
  const [submitting, setSubmitting] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [userLists, setUserLists] = useState([])

  const [tabsList, _setTabsList] = useState([
    { title: "Reach", value: 1, isActive: true },
    { title: "Clicks", value: 2, isActive: false },
    { title: "Conversion", value: 3, isActive: false },
    { title: "Revenue", value: 4, isActive: false },
    { title: "Spend", value: 5, isActive: false },
    { title: "Profit", value: 6, isActive: false },
    { title: "CPC", value: 7, isActive: false },
    { title: "CTR", value: 8, isActive: false },
    { title: "Conv.Rate", value: 9, isActive: false },
    { title: "ROI", value: 10, isActive: false },
    { title: "Avg. Daily Profit", value: 11, isActive: false }
  ])
  const [tabsListGraph, _setTabsListGraph] = useState([
    { title: "Daily", value: 1, isActive: true },
    { title: "Weekly", value: 2, isActive: false },
    { title: "Monthly", value: 3, isActive: false }
  ])

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  useEffect(() => {
    if (offerList) {
      const res = offerList.map(item => {
        return { label: item.name, value: item.id }
      })
      setOffers(res)
    }
  }, [offerList])

  const setTabsListGraph = activeId => {
    let tempTabs = tabsListGraph.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsListGraph(tempTabs)
  }

  const getAllTestCampagins = async (param) => {

    let current = (currentPage > 0) ? currentPage : 0
    let obj = { offset: current, limit: 10, has_test_phase: true, drafts: false, insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date }

    if (param && param.insights_start_date) {
      obj.insights_start_date = param.insights_start_date
    }
    if (param && param.insights_end_date) {
      obj.insights_end_date = param.insights_end_date
    }
    if (param && param.successmetric) {
      obj.test_phase_success_metric = param.successmetric
    }
    if (param && param.offers) {
      obj.offer_ids = param.offers
    }

    try {
      setLoading(true)
      const { data } = await getCampaginsAPI(obj)
      setLoading(false)
      setCampaignsCount(data.count)
      setAllTestCampaigns(data.results)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
    }
  }
  
  // USER LISTS
  const getUserLists = async () => {
    try {
      const { data } = await getUsersAPI()
      const res = data.map(item => {
        return { label: `${item.first_name} ${item.last_name}`, value: item.id }
      })
      setUserLists(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  } 

  useEffect(() => {
    getUserLists()
    getAllTestCampagins()
    dispatch(getOfferLists())
  }, [])

  const handleAdvancePreview = async (item) => {
    setSelectedItem(item)
    setOpenAdvancePreview(!openAdvancePreview)
    if (item) {
      try {
        setAdLoading(true)
        const { data } = await getAdCreativeAPI({ campaign_ids: item?.id })
        setAdLoading(false)
        
        if (data) {
          const sorteddata = data.sort((a, b) => {
            if (a.is_winner === b.is_winner) {
              return 0
            }
            return a.is_winner ? -1 : 1
          })
          setAdsList(sorteddata)

          const wids = data.filter((i) => i.is_winner === true).map((x) => x.id)
          setWinnerAds(wids)
        }
      } catch (error) {
        setAdLoading(false)
        // console.log("error?.response?", error.response)
      }
    }
  }

  const handleFormData = async values => {
    
    let params = {}
    if (values.offers) {
      params.offers = values.offers.value
    }
    if (values.successmetric) {
      params.successmetric = values.successmetric.value
    }
    if (values.start_date) {
      params.insights_start_date = values.start_date
    }
    if (values.end_date) {
      params.insights_end_date = values.end_date
    }
    getAllTestCampagins(params)
  }

  const formik = useFormik({
    initialValues: {
      buyer: {},
      offers: {},
      results: {},
      successmetric: {},
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFormData
  })

  const handleBreaktown = (row) => {
    setViewData(row)
    setOpen(!open)
  }

  const handleChangeNotes = (e, i) => {
    const temp = [...testCampagins]
    temp[i].test_phase.notes = e.target.value
    setAllTestCampaigns(temp)
  }

  const formatTargetDetail = (x) => {
    var output = []
    for (let i in x) {
      for (let key in x[i]) {
        if (Array.isArray(x[i][key]) && x[i][key].length > 0) {
          x[i][key].map((items) => { output.push(items) })
        }
      }
    }
    return output.map(({ name }) => name).join(", ")
  }

  const handleSelected = (e, k, id) => {
    const temp = [...winnerAds]
    if (e.target.checked) {
      temp.push(id)
    } else {
      let index = winnerAds.indexOf(id)
      temp.splice(index, 1)
    }
    setWinnerAds(temp)
  }

  const handlePreviewSubmit = async () => {
    if (winnerAds.length > 0) {
        const tId = selectedItem && selectedItem.test_phase && selectedItem?.test_phase?.test_module && selectedItem?.test_phase?.test_module?.id
        setTestModuleId(tId)
        setOpenAdvancePreview(!openAdvancePreview)
        setOpenSetup(false); setOpenSOP(false); setOpenSelectTemplate(false); setOpenConfig(true)
    }
  }

  const submitUpdatedNotes = async (item) => {
    if (item && item.test_phase) {
      try {
        const { data } = await updateTestPhase(item.test_phase.id, { notes: item.test_phase.notes })
        toast.success("Test phase notes updated!")
      } catch (error) {
        // console.log("error?.response?", error.response)
      }
    }
  }

  const handleReset = () => {
    formik.resetForm()
    formik.setFieldValue("start_date", moment().format('YYYY-MM-DD'))
    formik.setFieldValue("end_date", moment().format('YYYY-MM-DD'))
    getAllTestCampagins({ insights_start_date: moment().format('YYYY-MM-DD'), insights_end_date: moment().format('YYYY-MM-DD') })
  }

  return (
    <section className="mx-auto px-8 w-full min-h-screen bg-gray-200">
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-8 lg:gap-7 mb-4 items-end mt-12">
          <div className="block">
            <span className="font-bold text-gray-500">Offers</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white mb-0"
              options={offers}
              onChange={value => formik.setFieldValue("offers", value)}
              onBlur={formik.handleBlur}
              value={formik.values.offers}
              error={formik.errors.offers}
              touched={formik.touched.offers}
            />
          </div>
          <div className="block">
            <span className="font-bold text-gray-500">Media Buyer</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white mb-0"
              options={userLists}
              onChange={value => formik.setFieldValue("buyer", value)}
              onBlur={formik.handleBlur}
              value={formik.values.buyer}
              error={formik.errors.buyer}
              touched={formik.touched.buyer}
            />
          </div>
          <div className="block">
            <span className="font-bold text-gray-500">Start Date</span>
            <Input className="bg-white" placeholder="Start Date" type="date" name="start_date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.start_date}
              error={formik.errors.start_date}
              touched={formik.touched.start_date} 
            />
          </div>
          <div className="block">
            <span className="font-bold text-gray-500">End Date</span>
            <Input className="bg-white" placeholder="End Date" type="date" name="end_date" 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_date}
              error={formik.errors.end_date}
              touched={formik.touched.end_date}
            />
          </div>
          <div className="block">
            <span className="font-bold text-gray-500">Success Metric</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white mb-0"
              options={DEFINED_KPIS}
              onChange={value => formik.setFieldValue("successmetric", value)}
              onBlur={formik.handleBlur}
              value={formik.values.successmetric}
              error={formik.errors.successmetric}
              touched={formik.touched.successmetric}
            />
          </div>
          <div className="block">
            <span className="font-bold text-gray-500">Results</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white mb-0"
              options={[
                { label: "Winner", value: "Winner" },
                { label: "Loser", value: "Loser" }
              ]}
              onChange={value => formik.setFieldValue("results", value)}
              onBlur={formik.handleBlur}
              value={formik.values.results}
              error={formik.errors.results}
              touched={formik.touched.results}
            />
          </div>

          <button className="px-2 py-2 my-4 button-gradient rounded-md font-bold text-white" type="submit">
            Apply
          </button>
          <button className="px-2 py-2 my-4 bg-[#DBD312] rounded-md font-bold text-white" onClick={() => handleReset()}>
            Reset
          </button>
        </div>
      </form>

      <div className="bg-white rounded-lg border border-2 border-gray-300 px-4 pb-16 mb-10">
        <div className="mt-10 text-right">
          <Button
            className="px-7 py-2 bg-[#B6CC43] rounded-xl text-white hover:text-white hover:no-underline font-semibold"
            onClick={() => setOpenSetup(!openSetup)}
          >
            <BiPlus /> Add New
          </Button>
        </div>

        {(loading) ? <div className="text-center py-6"><Spinner /></div> : <>
          {(testCampagins && testCampagins.length > 0) ? <>
            {testCampagins.map((items, i) => (
              <>
                <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-9 lg:gap-5 bg-gray-100 rounded-t-lg border border-2 border-gray-400 flex-auto items-center p-4 py-2 mt-10">
                  <p className="mt-0 text-sm font-medium text-gray-600"><span className="font-bold block">ID:</span> #{items?.id}</p>
                  <p className="mt-0 text-sm font-medium text-gray-600 col-span-2"><span className="font-bold block">Test Name:</span> {items?.name}</p>
                  <p className="mt-0 text-sm font-medium text-gray-600"><span className="font-bold block">Test Date:</span> {items?.test_phase && moment(items?.test_phase?.created).format('YYYY-MM-DD')} </p>
                  <p className="mt-0 text-sm font-medium text-gray-600">
                    {(items && items.test_phase && items.test_phase.limit_days) && <>
                      <span className="font-bold block">Length of Time:</span> {items?.test_phase && items?.test_phase?.limit_days} Days
                    </>}
                    {(items && items.test_phase && items.test_phase.limit_spend) && <>
                      <span className="font-bold block">Money Spent:</span> ${items?.test_phase && items?.test_phase?.limit_spend}
                    </>}
                    {(items && items.test_phase && items.test_phase.limit_impressions) && <>
                      <span className="font-bold block">Impressions:</span> {items?.test_phase && items?.test_phase?.limit_impressions}
                    </>}
                  </p>
                  <p className="mt-0 text-sm font-medium text-gray-600"><span className="font-bold block">Phase:</span> {items?.test_phase && items?.test_phase?.phase_number}</p>
                  <p className="mt-0 text-sm font-medium text-gray-600"><span className="font-bold block">Test Variable:</span> {items?.test_phase && items?.test_phase?.variable}</p>
                  <p className="mt-0 text-sm font-medium text-gray-600">
                    <span className="font-bold block ">Success Metric:</span> 
                    {items?.test_phase && items?.test_phase?.winning_kpi} {items?.test_phase && items?.test_phase?.winning_operator} {items?.test_phase && items?.test_phase?.winning_threshold}
                  </p>
                  <p className="mt-0 text-sm font-medium text-gray-600 border border-1 p-2 border-gray-400 text-center rounded-lg">{items?.status}</p>
                
                </div>
                <div className="flex flex-col md:flex-row bg-white rounded-b-lg border border-2 border-t-0 border-gray-400 p-4 py-6">
                  <div className="w-full md:w-1/3">
                    <div className="mb-16 relative test-campaign-media">
                      {items?.meta_media_configs && items?.meta_media_configs.map((item, key) => (
                        <>
                          {(key < 4 && item && item.meta_media_images && item.meta_media_images.length > 0) && <img className={(key > 0) ? `absolute top-4 left-8 w-[160px] h-[160px] rounded-lg` : 'w-[160px] h-[160px] rounded-lg'} src={item.meta_media_images[0]?.file} />}
                        </>
                      ))}
                    </div>
                    <div className="flex flex-col mb-12">
                      <p className="text-base font-bold text-gray-800 flex items-center">Breakdown <MdOutlineArrowCircleDown className="ml-1 mt-1" onClick={() => handleBreaktown(items)} /></p>
                      <p className="text-base font-medium text-gray-800 lg:ml-32">
                        <span className="font-bold">Audiences:</span> <br /> 
                        {items?.meta_ad_set_audience_configs && items?.meta_ad_set_audience_configs.map((item) => (
                          <><span className="text-sm">{formatTargetDetail(item?.flexible_spec)}</span></>
                        ))}
                      </p>
                    </div>
                    <div className="mr-5">
                      <Input className="bg-white" placeholder="Add Notes" name="addcomment" onChange={(e) => handleChangeNotes(e, i) } value={items && items.test_phase && items.test_phase.notes} />
                      <Button className="px-5 py-2 bg-[#B6CC43] rounded-xl w-fit" onClick={() => submitUpdatedNotes(items)}>
                        Update Notes
                      </Button>
                    </div>
                  </div>
                  <div className="w-full md:w-2/3">
                    <div className='flex justify-end'>
                      <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
                    </div>
                    <div className="mb-6">
                      <Graph requests={{ start_date: formik.values.start_date, end_date: formik.values.end_date, campaignId: items?.id }} />
                    </div>
                    <div className="flex flex-wrap md:flex-row justify-between">
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Impressions</p> <p>{items?.impressions}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Clicks</p> <p>{items?.ms_clicks}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">EPC</p> <p>${items?.ms_epc}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">CPC</p> <p>${items?.ms_lpvc}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">CTR</p> <p>{items?.ms_ctr.toFixed(2)}%</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Sub. Leads</p> <p>{items?.ms_submitted_leads}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Conv. Rate</p> <p>{items?.ms_conversion_rate.toFixed(2)}%</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Sold Leads</p> <p>{items?.ms_sold_leads}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Sold Rate</p> <p>{items?.ms_sold_rate.toFixed(2)}%</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Revenue</p> <p>${items?.ms_revenue}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Spend</p> <p>${items?.spend}</p></div></div>
                      <div className="flex"><div className="mt-0 text-xs font-medium text-gray-600"><p className="font-bold">Profit</p> <p>${items?.ms_profit}</p></div></div>
                    </div>
                    <div className="text-right mt-6">
                      {((items.test_phase && items.test_phase.phase_number) < (items.test_phase && items.test_phase && items.test_phase.test_module && items.test_phase.test_module.total_phases)) &&
                        <Button className="px-5 py-2 bg-[#00B2B4] rounded-xl w-fit" onClick={() => handleAdvancePreview(items)}>
                          Next Phase
                        </Button>
                      }
                    </div>
                  </div>
                </div>
              </>
            ))}
          </> : <>
            <p className="text-center py-5">No Records Found!</p>
          </>}
        </>}
      </div>

      <BaseModal open={open} setOpen={setOpen} className="xl:max-w-screen-xl 3xl:max-w-screen-3xl" title={`Test Name: ${viewData && viewData?.name}`}>
        <Breakdown data={viewData} />
      </BaseModal>

      <BaseModal open={openSetup} setOpen={setOpenSetup} title={"Campaign Test Configuration"}>
        <div class="text-center md:text-right mt-4 flex py-10">
          <button
            class="block w-full md:inline-block md:w-auto px-4 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
            onClick={() => {
              setOpenSetup(!openSetup); setOpenSOP(!openSOP); setOpenSelectTemplate(false);
            }}>Create New Test</button>
          <button
            class="block w-full md:inline-block md:w-auto px-4 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
            onClick={() => {
              setOpenSetup(!openSetup); setOpenSOP(false); setOpenSelectTemplate(!openSelectTemplate);
            }}>Use Existing Test Template</button>
        </div>
      </BaseModal>

      <TestSopModal
        openSetup={openSetup} setOpenSetup={setOpenSetup}
        openSOP={openSOP} setOpenSOP={setOpenSOP}
        openConfig={openConfig} setOpenConfig={setOpenConfig}
        openSelectTemplate={openSelectTemplate} setOpenSelectTemplate={setOpenSelectTemplate}
        setTotalPhases={setTotalPhases}
        setTestModuleId={setTestModuleId}
      />

      <TestConfigModal
        title={(selectedItem && selectedItem.test_phase) ? `Testing Phase ${selectedItem.test_phase.phase_number + 1} Configuration` : "Testing Phase 1 Configuration"}
        openConfig={openConfig} setOpenConfig={setOpenConfig}
        openSOP={openSOP} setOpenSOP={setOpenSOP}
        openSaveTemplate={openSaveTemplate} setOpenSaveTemplate={setOpenSaveTemplate}
        saveTemplate={saveTemplate} setSaveTemplate={setSaveTemplate}
        testModuleId={testModuleId}
        winnerAds={winnerAds}
        setWinnerAds={setWinnerAds}
      />

      <BaseModal open={openSelectTemplate} setOpen={setOpenSelectTemplate} title={"Existing Test Template"}>
        <div class="mt-4 flex flex-col">
          <div className="block py-7">
            <p className="font-semibold mb-1">Select Template</p>
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" }
              ]}
            />
          </div>
          <div className="flex">
            <button
              class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
              onClick={() => {
                setOpenConfig(true); setOpenSelectTemplate(false);
              }}>Next</button>
            <button
              class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
              onClick={() => {
                setOpenSetup(true); setOpenSelectTemplate(false);
              }}>Back</button>
          </div>
        </div>
      </BaseModal>

      <BaseModal open={openSaveTemplate} setOpen={setOpenSaveTemplate} title={"Save as Template?"}>
        {(saveTemplate) ? <div class="mt-4 flex flex-col">
          <div className="block py-7">
            <p className="font-semibold mb-1">Name Template</p>
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" }
              ]}
            />
          </div>
          <div className="flex">
            <button
              class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
              onClick={() => {

              }}>Next</button>
            <button
              class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
              onClick={() => {
                setSaveTemplate(!saveTemplate);
              }}>Back</button></div>
        </div> : <div class="text-center md:text-right mt-4 flex py-10">
          <button
            class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
            onClick={() => {
              setSaveTemplate(!saveTemplate);
            }}>Yes</button>
          <button
            class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
            onClick={() => {
              setOpenSaveTemplate(!openSaveTemplate);
            }}>No</button>
        </div>}
      </BaseModal>

      <BaseModal open={openAdvancePreview} setOpen={setOpenAdvancePreview} className="xl:max-w-screen-xl 3xl:max-w-screen-3xl" title={"Advance Preview"}>
        <div>
          {(adLoading) ? <div className="text-center py-6"><Spinner /></div> : <>
            {(adsList && adsList.length > 0) ? <>
              <div className="grid grid-cols-4 gap-x-5 gap-y-7 my-8">
                {adsList.map((row, key) => (
                  <>
                    <div className="border border-gray-300 rounded-md bg-white py-5 px-5">
                      <div className="flex justify-start mb-3">
                        <Checkbox
                          className="w-6 h-6"
                          title={(row.is_winner === true) ? <span className="text-[#B6CC43] font-bold">Winner</span> : <span></span>}
                          name="budget_type"
                          onChange={(e) => handleSelected(e, key, row.id)}
                          checked={winnerAds.includes(row.id)}
                          disabled={row.is_winner === true}
                        />
                      </div>
                      <img className="h-[350px] w-full" src={(row.remote_ad_images && row.remote_ad_images[0]) && row.remote_ad_images[0]?.file} />
                      <h1 className="text-xl mt-4">{row.headline}</h1>
                      <div className="grid grid-cols-3 gap-4 my-5">
                        <p className="col-span-2">{row.text}</p>
                        <div className="text-right">
                          <Button className={`px-3 py-2 bg-[#adadad] rounded-md text-xs text-black`}>
                            {(row.call_to_action) ? row.call_to_action : 'LEARN MORE'}
                          </Button>
                        </div>
                      </div>
                      <div className="border-t w-full pt-4 flex flex-wrap">
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">Clicks</p><p>{row.ms_clicks}</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">EPC</p><p>${row.ms_epc}</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">CTR</p><p>{row.ms_ctr.toFixed(2)}%</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">CPC</p><p>${row.ms_lpvc}</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">Revenue</p><p>${row.ms_revenue}</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">Spend</p><p>${row.spend}</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">Conv.Rate</p><p>{row.ms_conversion_rate.toFixed(2)}%</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">ROI</p><p>{row.ms_roi}%</p>
                        </div>
                        <div className="text-center mr-4 mt-3">
                          <p className="font-semibold">Impressions</p><p>{row.impressions}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div> </> : <>
              <div className="py-8 text-center"> No Ads Found!</div>
            </>} </>}
        </div>
        <div class="flex justify-end py-4">
          {(adsList && adsList.length > 0) &&
            <button
              class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#B6CC43] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
              onClick={() => {
                handlePreviewSubmit()
              }}>{(submitting) ? <Spinner /> : 'Submit'}</button>
          }
          <button
            class="block w-full md:inline-block md:w-auto px-20 py-3 bg-[#00B2B4] rounded-xl mr-3 text-white hover:text-white hover:no-underline font-semibold text-sm"
            onClick={() => {
              setOpenAdvancePreview(!openAdvancePreview);
            }}>Back</button>
        </div>
      </BaseModal>

    </section>
  )
}
