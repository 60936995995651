import { useEffect, useState } from "react"
import { MdKeyboardArrowDown, MdRemoveCircle, MdRemoveCircleOutline, MdRemoveDone } from "react-icons/md"
import { Combobox } from "@headlessui/react"
import { IoMdCloseCircle, IoMdRemove, IoMdRemoveCircle } from "react-icons/io"
import { FaCross } from "react-icons/fa"
import { BiTrashAlt } from "react-icons/bi"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Dropdown({
  title = "",
  placeholder,
  options,
  classRoot,
  classBox,
  className,
  onChange,
  error,
  touched,
  disabled = false,
  value,
  ...props
}) {
  const [query, setQuery] = useState("")
  const [selectedPerson, setSelectedPerson] = useState(null)

  const filteredOptions =
    query === ""
      ? options
      : options.filter(person => {
          return (person.label) ? person.label?.toLowerCase()?.includes(query.toLowerCase()) : person.name?.toLowerCase()?.includes(query.toLowerCase())
        })

  useEffect(() => {
    setSelectedPerson(value)
  }, [value])

  return (
    <Combobox
      as="div"
      className={`my-3 ${classRoot}`}
      value={selectedPerson}
      onChange={value => {
        setSelectedPerson(value)
        onChange(value)
      }}
      disabled={disabled}
    >
      {title && (
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          {title}
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Button className="w-full">
          <Combobox.Input
            className={`w-full rounded-md border-gray-300  bg-[#F4F4F4] py-1.5 pl-3 ${(classBox) ? classBox : 'mb-2'} pr-10 text-gray-900 shadow-sm sm:text-sm sm:leading-6`}
            onChange={event => setQuery(event.target.value)}
            displayValue={person => person?.label}
            placeholder={placeholder}
            autoComplete="off"
          />
          <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <MdKeyboardArrowDown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </Combobox.Button>
        {filteredOptions && filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map(person => (
              <Combobox.Option
                key={person.id}
                value={person}
                title={person.label}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-8 pr-4 ${
                    active ? "bg-[#B6CC43]  text-white" : "text-gray-900"
                  }
                     ${className}
                  `
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.label}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {error && touched && (
          <p className="text text-red-500">{error || 'Required'}</p>
        )}
      </div>
    </Combobox>
  )
}
