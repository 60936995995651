import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function Conversions({ leads }) {

  const [rate, setRate] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [months, setMonths] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])

  const formatData = (data, type) => {

    const monthSums = {}

    months.forEach(month => {
      monthSums[month] = 0
    })

    data && data.forEach(item => {
      const month = new Date(item.range_start).getMonth()
      const monthName = months[month]
      monthSums[monthName] += parseFloat(item[type])
    })
   
    return Object.entries(monthSums).map((item) => item[1].toFixed(2))
  }

  useEffect(() => {
    setRate(formatData(leads, 'ms_cost_per_lead'))
  }, [leads])

  const series = [{
    name: "Conversion Rate",
    data: rate
  }]

  const options = {
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#B6CC43'],
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: months,
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<div class="px-4 py-4"><b>$' + data + '</b><p>Conversion Rate</p>'
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height="300px" />
    </div>
  )
}
