import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function LeadGenerated({ leads }) {

  const [revenue, setRevenue] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [spend, setSpend] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [months, setMonths] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])

  const formatData = (data, type) => {

    const monthSums = {}

    months.forEach(month => {
      monthSums[month] = 0
    })

    data && data.forEach(item => {
      const month = new Date(item.range_start).getMonth()
      const monthName = months[month]
      monthSums[monthName] += parseFloat(item[type])
    })
   
    return Object.entries(monthSums).map((item) => item[1])
  }

  useEffect(() => {
    setRevenue(formatData(leads, 'revenue'))
    setSpend(formatData(leads, 'spend'))
  }, [leads])

  const series = [{
    name: "Revenue",
    data: revenue
  }, {
    name: "Spend",
    data: spend
  }]

  const options = {
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#B6CC43', '#00B2B4'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
      show: true,
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: months,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var label = w.globals.initialSeries[seriesIndex].name;
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<div class="px-4 py-4"><b>$' + data + '</b><p>'+ label +'</p>'
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          var val = Math.abs(value)
          if (val >= 1000) {
            val = (val / 1000).toFixed(0) + 'K'
          }
          return val
        }
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height="300px" />
    </div>
  )
}
