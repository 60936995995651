import { AiFillEye } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useState, useContext } from "react"
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login"

import { Input, Spinner } from "../components"
import { ProfileContext } from "../context/profileContext"
import {
  login,
  loginWithGoogle,
  loginWithFacebook,
  getProfileAPI
} from "../service"
import { LoginSchema } from "../helpers/validationSchema"

export default function Login() {
  const navigate = useNavigate()
  const [_, setProfileData] = useContext(ProfileContext)
  const [showPassword, setPasswordStatus] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const getProfileData = async () => {
    try {
      const { data } = await getProfileAPI()
     
      setProfileData({ ...data, loaded: true })
      navigate("/dashboard/campagins")
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
    }
  }

  const submitHandler = async values => {
    try {
      setSubmitting(true)
      const {
        data: { auth_token }
      } = await login(values)

      setSubmitting(false)
      localStorage.setItem("token", auth_token)
      getProfileData()
      formik.resetForm()
    } catch (error) {
      setSubmitting(false)
      const message =
        error?.response.data?.non_field_errors[0] || "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const handleLoginWithGoogle = async ({ data: { credential } = {} }) => {
    try {
      const {
        data: { auth_token }
      } = await loginWithGoogle({ access_token: credential })

      console.log("response", auth_token)
      localStorage.setItem("token", auth_token)
      navigate("/dashboard/change-password")
      formik.resetForm()
      getProfileData()
    } catch (error) {
      const message =
        error?.response.data?.non_field_errors[0] || "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  // ({ provider, data }) => {
  //   console.log("provider, data", provider, data)
  // }

  const handleLoginWithFacebook = async ({ data }) => {
    try {
      const {
        data: { key }
      } = await loginWithFacebook({ access_token: data.accessToken })

      console.log("response", key)
      localStorage.setItem("token", key)
      navigate("/dashboard/change-password")
      formik.resetForm()
      getProfileData()
    } catch (error) {
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    validationSchema: LoginSchema,
    onSubmit: submitHandler
  })

  return (
    <section className="flex items-center justify-center min-h-screen bg-gradient">
      <div className="mx-auto container">
        <form
          onSubmit={formik.handleSubmit}
          className="mx-auto max-w-sm bg-white rounded-lg py-10 px-10 flex flex-col"
        >
          <h1 className="text-xl font-bold mb-2">Login</h1>
          <Input
            placeholder="E-mail"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
          <div className="w-full flex flex-col">
            <Input
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              Icon={AiFillEye}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              iconClick={() => setPasswordStatus(old => !old)}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />

            <div className="flex justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  name="rememberMe"
                  Icon={AiFillEye}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rememberMe}
                  error={formik.errors.rememberMe}
                  touched={formik.touched.rememberMe}
                />
                <label className="text-xs font-thin">remember me</label>
              </div>
              <Link to="/forget-password" className="text-xs">
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white"
            >
              {(submitting) ? <Spinner className="w-4 h-4" /> : 'Login'}
            </button>

            <div className="relative">
              <hr className="h-px mb-8 mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
              <span className="absolute top-0 left-0 right-0 mx-auto bg-white p-2 w-10 h-10">
                OR
              </span>
            </div>
            <div className="grid grid-cols-2 gap-3 mb-4">
              <LoginSocialGoogle
                client_id="924716124890-r7pofa2phmiehd34t092cp15nlnjn9r8.apps.googleusercontent.com"
                onLoginStart={console.log}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="online"
                typeResponse="idToken"
                onResolve={handleLoginWithGoogle}
                onReject={err => {
                  console.log(err)
                }}
              >
                <button
                  type="button"
                  className="bg-white px-2 py-2 text-center w-full border rounded-xl border-[#00B2B4] flex justify-center items-center"
                >
                  <img src="/static/google.svg" className="w-8 h-8" />
                  Google
                </button>
              </LoginSocialGoogle>
              <LoginSocialFacebook
                appId={"602949464983958" || ""}
                fieldsProfile={
                  "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                }
                onLoginStart={console.log}
                onLogoutSuccess={console.log}
                onResolve={handleLoginWithFacebook}
                onReject={err => {
                  console.log(err)
                }}
              >
                <button
                  type="button"
                  className="bg-white px-2 py-2 text-center w-full border rounded-xl border-[#00B2B4]  flex justify-center items-center"
                >
                  <img src="/static/facebook.svg" className="w-8 h-8" />
                  Facebook
                </button>
              </LoginSocialFacebook>
            </div>

            <div className="flex text-sm justify-center">
              <p>Don’t have an account? </p>{" "}
              <Link to="/signup" className="ml-1 text-[#B6CC43]">
                {" "}
                Sign up
              </Link>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
