import { useState, useEffect } from "react"
import _ from "lodash"
import { Dropdown, Input, Tabs, Button } from "../../../components"
import Conversions from "./Conversions"
import ProfitFromLeads from "./ProfitFromLeads"
import CampaignLists from "./CampaignLists"
import Feeds from "./Feeds"
import { getStatsSummary, getOfferLists, getLeadStats, getCampaignLists, getUserLists } from '../../../redux/actions/common'
import { useFormik } from "formik"
import { getUsersAPI } from "../../../service"
import moment from 'moment'
import 'moment-timezone'
import SummartStats from '../summaryStats'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'

import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs"
import { FaDollarSign, FaPercent, FaUsers } from "react-icons/fa"

export default function Dashbaord() {
  const dispatch = useDispatch()
  const { stats, leads, offerList } = useSelector(({ common }) => common)

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  const [offers, setOffers] = useState([]);
  const [users, setUsers] = useState([]);
  const [tabsList, _setTabsList] = useState([
    { title: "Reach", value: 1, isActive: true },
    { title: "Clicks", value: 2, isActive: false },
    { title: "Conversion", value: 3, isActive: false },
    { title: "Revenue", value: 4, isActive: false },
    { title: "Spend", value: 5, isActive: false },
    { title: "Profit", value: 6, isActive: false },
    { title: "CPC", value: 7, isActive: false },
    { title: "CTR", value: 8, isActive: false },
    { title: "Conv.Rate", value: 9, isActive: false },
    { title: "ROI", value: 10, isActive: false },
    { title: "Avg. Daily Profit", value: 11, isActive: false }
  ])
  const [tabsListGraph, _setTabsListGraph] = useState([
    { title: "Daily", value: 1, isActive: true },
    { title: "Weekly", value: 2, isActive: false },
    { title: "Monthly", value: 3, isActive: false }
  ])

  const handleFormData = async values => {
    try {
      let params = {}
      let obj = { offset: 1, limit: 5, drafts: false }
      let reqs = {}

      if (values.offers) {
        params.offer_ids = values.offers.value
        obj.offer_ids = values.offers.value
      }
      if (values.status) {
        params.status = values.status.value
        obj.status = values.status.value
      }
      if (values.start_date) {
        params.start_date = values.start_date
        reqs.start_date = values.start_date
        obj.insights_start_date = values.start_date
      }
      if (values.end_date) {
        params.end_date = values.end_date
        reqs.end_date = values.end_date
        obj.insights_end_date = values.end_date
      }
      if (values.refs) {
        params.user_ids = values.refs.value
        reqs.user_ids = values.refs.value
      }
      dispatch(getStatsSummary(params))
      dispatch(getCampaignLists(obj))
      dispatch(getLeadStats(reqs))
    } catch (error) {

    }
  }

  const getUserLists = async () => {
    try {
      const { data } = await getUsersAPI()
      const res = data.map(item => {
        return { label: `${item.first_name} ${item.last_name}`, value: item.id }
      })
      setUsers(res)
    } catch (error) {
      console.log("ERROR", error)
    }
  } 

  const formik = useFormik({
    initialValues: {
      refs: {},
      offers: {},
      status: {},
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFormData
  })

  useEffect(() => {
    dispatch(getStatsSummary({ start_date: formik.values.start_date, end_date: formik.values.end_date }))
    dispatch(getOfferLists())
    getUserLists()
    dispatch(getCampaignLists({ offset: 1, limit: 5, drafts: false }))
    dispatch(getLeadStats({ start_date: formik.values.start_date, end_date: formik.values.end_date }))
  }, [])

  useEffect(() => {
    if (offerList) {
      const res = offerList.map(item => {
        return { label: item.name, value: item.id }
      })
      setOffers(res)
    }
  }, [offerList])

  const setTabsList = activeId => {
    let tempTabs = tabsList.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsList(tempTabs)
  }

  const setTabsListGraph = activeId => {
    let tempTabs = tabsListGraph.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsListGraph(tempTabs)
  }

  return (
    <section className="mx-auto w-full px-8 min-h-screen bg-gray-200">

      <SummartStats dashboard={true} />

      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-6 lg:gap-5 mb-2 items-end mt-12">
          <div class="block">
            <span className="font-bold text-gray-500">Offers</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={offers}
              onChange={value =>
                formik.setFieldValue("offers", value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.offers}
              error={formik.errors.offers}
              touched={formik.touched.offers}
            />
          </div>
          <div class="block">
            <span className="font-bold text-gray-500">Media Buyers</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={users}
              onChange={value =>
                formik.setFieldValue("refs", value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.refs}
              error={formik.errors.refs}
              touched={formik.touched.refs}
            />
          </div>
          <div class="block">
            <span className="font-bold text-gray-500">Start Date</span>
            <Input
              placeholder="Start Date"
              type="date"
              className="bg-white"
              name="start_date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.start_date}
              error={formik.errors.start_date}
              touched={formik.touched.start_date}
            />
          </div>
          <div class="block">
            <span className="font-bold text-gray-500">End Date</span>
            <Input
              placeholder="End Date"
              type="date"
              className="bg-white"
              name="end_date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_date}
              error={formik.errors.end_date}
              touched={formik.touched.end_date}
            />
          </div>
          <div class="block">
            <span className="font-bold text-gray-500">Campaign Status</span>
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: "ACTIVE", value: "ACTIVE" },
                { label: "ARCHIVED", value: "ARCHIVED" },
                { label: "DELETED", value: "DELETED" },
                { label: "PAUSED", value: "PAUSED" }
              ]}
              onChange={value =>
                formik.setFieldValue("status", value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.status}
              error={formik.errors.status}
              touched={formik.touched.status}
            />
          </div>
          <button type="submit" className="px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white">
            Apply
          </button>
        </div>
      </form>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:gap-5 mb-4 items-end mt-8">
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Conversions</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <Conversions leads={leads} />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Profit From Leads</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <ProfitFromLeads leads={leads} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-5 items-start mt-8">
        <div className="col-span-2 py-3">
          <CampaignLists />
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-5 mt-6 mb-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Feeds</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div>
              <Feeds />
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}
