import React, { Component } from "react";

export default function Analytics() {

  const analyticData = [
    {
      name: "Campaign Name",
      date: "Started 7 November 2022",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      date: "Started 7 November 2022",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      date: "Started 7 November 2022",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      date: "Started 7 November 2022",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      date: "Started 7 November 2022",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
  ]

  return (
    <div className="overflow-y-auto mt-4">
      <table className="min-w-full table-fixed divide-y divide-gray-300 rounded-2xl overflow-hidden border-spacing-0">
        
        <tbody className="divide-y-2 divide-gray-200 bg-[#F4F4F4]">
          {analyticData.map(
            ({ name, date, conversion, profit, roi }, index) => (
              <tr key={index} className={"hover:bg-gray-100"}>
                <td className="whitespace-nowrap py-4 px-3 text-sm font-medium flex flex-col">
                  <p className="font-semibold">{name}</p>
                  <p><small>{date}</small></p>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{conversion}%</span> <img src="/static/graphdown.png" className="ml-1" width={13} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{profit}%</span> <img src="/static/graphup.png" className="ml-1" width={13} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{roi}</span> <img src="/static/graphdown.png" className="ml-1" width={13} />
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}
