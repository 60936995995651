import { useState, useEffect } from "react"
import DataTable, { createTheme } from 'react-data-table-component';
import { getCampaignLists, getLandingPages } from '../../../redux/actions/common'
import { Dropdown, Input, Tabs, Button, ManageColumnsCompaginsModals } from "../../../components"
import { getSelfConfigAPI } from "../../../service";
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { BsSearch, BsArrowCounterclockwise } from "react-icons/bs"

export default function CampaignLists() {

  const dispatch = useDispatch()
  const { campaigns, landingPages } = useSelector(({ common }) => common)
  const [campaignList, setCampaignList] = useState([])
  const [landingPageList, setLandingPageList] = useState([])
  const [showManageColumnsModal, setShowManageColums] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [selectedLanding, setSelectedLanding] = useState(null)
  const [allColumns, setColumns] = useState([
    { label: "ID", value: "id", isActive: false },
    { label: "Name", value: "name", isActive: false },
    { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
    { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
    { label: "Clicks", value: "ms_clicks", isActive: true },
    { label: "CTR", value: "ms_ctr", isActive: true },
    { label: "ROI", value: "ms_roi", isActive: true },
    { label: "Conv. Rate", value: "ms_conversion_rate", isActive: false },
    { label: "Sold Rate", value: "ms_sold_rate", isActive: false },
    { label: "EPC", value: "ms_epc", isActive: true },
    { label: "CPC", value: "ms_lpvc", isActive: true },
    { label: "Revenue", value: "ms_revenue", isActive: false },
    { label: "Profit", value: "ms_profit", isActive: false }
  ])
  const [columnData, setColumnData] = useState([])

  useEffect(() => {
    dispatch(getLandingPages())
    getColumnOrderList()
    dispatch(getCampaignLists({ offset: 1, limit: 5, drafts: false }))
  }, [])

  // GET COLUMN ORDERS
  const getColumnOrderList = async () => {
    try {
      const { data } = await getSelfConfigAPI()
      if (data.campaign_cols) {
        setColumns(data.campaign_cols)
      }
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  useEffect(() => {
    if (campaigns) {
      if (campaigns?.results) {
        setCampaignList(campaigns?.results)
      } else {
        setCampaignList(campaigns)
      }
    }
  }, [campaigns])

  useEffect(() => {
    if (landingPages) {
      const res = landingPages.map(item => {
        return { label: item.name, value: item.id }
      })
      setLandingPageList(res)
    }
  }, [landingPages])

  useEffect(() => {
    const cols = allColumns.map((items) => {
      if (items.value === 'id') {
        return {
          name: "ID",
          id: 'id',
          sortable: true,
          omit: items.isActive,
          selector: row => <span>{row.id}</span>,
          reorder: true
        }
      }
      else if (items.value === 'name') {
        return {
          name: "Name",
          id: 'name',
          sortable: true,
          minWidth: '250px',
          omit: items.isActive,
          selector: row => <span>{row.name}</span>,
          reorder: true,
          wrap: 'no'
        }
      }
      else if (items.value === 'ms_submitted_leads') {
        return {
          name: "Submitted Leads",
          id: 'ms_submitted_leads',
          sortable: true,
          minWidth: '180px',
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_submitted_leads}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_sold_leads') {
        return {
          name: "Sold Leads",
          id: 'ms_sold_leads',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_sold_leads}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_clicks') {
        return {
          name: "Clicks",
          id: 'ms_clicks',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_clicks}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_ctr') {
        return {
          name: "CTR",
          id: 'ms_ctr',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_ctr}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_roi') {
        return {
          name: "ROI",
          id: 'ms_roi',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_roi}%</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_conversion_rate') {
        return {
          name: "Conv. Rate",
          id: 'ms_conversion_rate',
          sortable: true,
          center: true,
          minWidth: '150px',
          omit: items.isActive,
          selector: row => (
            <div className="flex items-center">
              {row.ms_conversion_rate.toFixed(2)}%
            </div>
          ),
          reorder: true
        }
      }
      else if (items.value === 'ms_sold_rate') {
        return {
          name: "Sold Rate",
          id: 'ms_sold_rate',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => (
            <div className="flex items-center">
              {row.ms_sold_rate.toFixed(2)}%
            </div>
          ),
          reorder: true
        }
      }
      else if (items.value === 'ms_epc') {
        return {
          name: "EPC",
          id: 'ms_epc',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.ms_epc}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_lpvc') {
        return {
          name: "CPC",
          id: 'ms_lpvc',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_lpvc}</span>,
          reorder: true
        }
      }
      else if (items.value === 'spend') {
        return {
          name: "Spend",
          id: 'spend',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>${row.spend}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_revenue') {
        return {
          name: "Revenue",
          id: 'ms_revenue',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_revenue}</span>,
          reorder: true
        }
      }
      else if (items.value === 'ms_profit') {
        return {
          name: "Profit",
          id: 'ms_profit',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>${row.ms_profit}</span>,
          reorder: true
        }
      }
      else if (items.value === 'impressions') {
        return {
          name: "Impressions",
          id: 'impressions',
          minWidth: '150px',
          sortable: true,
          center: true,
          omit: items.isActive,
          selector: row => <span>{row.impressions}</span>,
          reorder: true
        }
      }
      else {
        return items
      }
    })
    setColumnData(cols)
  }, [allColumns])

  createTheme('solarized', {
    background: {
      default: '#F4F4F4'
    },
    text: {
      primary: '#273240',
      secondary: '#273240',
    },
    divider: {
      default: '#0000001f',
    },
  }, 'dark');

  const customStyles = {
    table: {
      style: {
        backgroundColor: 'transparent',
        border: 'none'
      },
    },
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        background: '#D9D9D9'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  const handleLandingPage = (val) => {
    setSelectedLanding(val)
   
    dispatch(getCampaignLists({ page: 1, limit: 5, drafts: false, landing_page_ids: val.value }))
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      dispatch(getCampaignLists({ page: 1, limit: 5, drafts: false, search_query: keyword }))
    }
  }

  const clickSearch = () => {
    dispatch(getCampaignLists({ page: 1, limit: 5, drafts: false, search_query: keyword }))
  }

  const handleReset = () => {
    setKeyword('')
    setSelectedLanding(null)
    dispatch(getCampaignLists({ page: 1, limit: 5, drafts: false }))
  }

  const handleSorting = (col, direction) => {
    dispatch(getCampaignLists({ page: 1, limit: 5, drafts: false, sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` }))
  }

  return (
    <>
      <div className="flex items-center justify-between w-full mb-3">
        <label class="block">
          <Dropdown
            placeholder="Landing Pages"
            classBox="bg-white mb-0"
            options={landingPageList}
            value={selectedLanding}
            onChange={value => handleLandingPage(value)}
          />
        </label>
        <span className="relative flex rounded-md shadow-sm">
          <input
            type="text"
            name="account-number"
            id="account-number"
            className="block w-full rounded-xl border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={handleSearch}
          />
          <span className="absolute flex items-center pr-3 top-2 right-0">
            <BsSearch className="h-5 w-5 text-[#B6CC43] cursor-pointer" aria-hidden="true"  onClick={clickSearch} />
          </span>
        </span>

        <Button className="px-4 py-2 bg-[#DBD312] rounded-xl ml-3" onClick={() => handleReset()}>
            <BsArrowCounterclockwise />
          </Button>

        <Button
          className="px-7 py-2 bg-[#B6CC43] rounded-xl ml-3"
          onClick={() => setShowManageColums(true)}
        >
          Manage Columns
        </Button>
      </div>
      <div className="bg-white rounded-lg shadow-xl px-0">
        <div className="w-full">
          <div className="my-3 rounded-lg">
            <DataTable
              columns={columnData}
              data={campaignList}
              theme="solarized"
              customStyles={customStyles}
              onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
            />
          </div>
        </div>
      </div>

      <ManageColumnsCompaginsModals
        open={showManageColumnsModal}
        setOpen={setShowManageColums}
        allColumns={allColumns}
        setColumns={setColumns}
      />
    </>
  );
};
