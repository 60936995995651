import { getStatsAPI, getVerticalsAPI, getCampaginsAPI, getLandingPagesAPI, getLeadStatsAPI } from "../../../service"

export const getStatsSummary = (param) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_STATS_SUMMARY', payload: null, isFetching: true })

    const {status, data } = await getStatsAPI(param)

    if (status === 200) {
      dispatch({
        type: 'GET_STATS_SUMMARY',
        payload: data,
        isFetching: false
      })
    }
  }
}

export const getLeadStats = (param) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_LEAD_STATS', payload: null, isFetching: true })

    const {status, data } = await getLeadStatsAPI(param)

    if (status === 200) {
      dispatch({
        type: 'GET_LEAD_STATS',
        payload: data,
        isFetching: false
      })
    }
  }
}

export const getOfferLists = () => {
  return async (dispatch) => {
    dispatch({ type: 'GET_ALL_OFFERS', payload: null, isFetching: true })

    const {status, data } = await getVerticalsAPI()

    if (status === 200) {
      dispatch({
        type: 'GET_ALL_OFFERS',
        payload: data,
        isFetching: false
      })
    }
  }
}

export const getLandingPages = () => {
  return async (dispatch) => {
    dispatch({ type: 'GET_ALL_LANDING', payload: null, isFetching: true })

    const {status, data } = await getLandingPagesAPI()

    if (status === 200) {
      dispatch({
        type: 'GET_ALL_LANDING',
        payload: data,
        isFetching: false
      })
    }
  }
}

export const getCampaignLists = (param) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_CAMPAIGN_LISTS', payload: null, isFetching: true })

    const {status, data } = await getCampaginsAPI(param)

    if (status === 200) {
      dispatch({
        type: 'GET_CAMPAIGN_LISTS',
        payload: data,
        isFetching: false
      })
    }
  }
}