import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { BsArrowCounterclockwise } from "react-icons/bs"
import { Button, Dropdown, Spinner, Toggle, Pagination, BaseModal } from "../../../components"
import { useFormik } from "formik"
import { updateRulesAPI, getRulesAPI, deleteRulesAPI, deleteBulkRulesAPI } from "../../../service"
import { FaTrash } from "react-icons/fa"
import DataTable from 'react-data-table-component'
import 'moment-timezone'

export default function ActiveRules({ activeTab, adAccounts, campagins, adsets, ads }) {

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([])
  const [currentPageRules, setCurrentPageRules] = useState(0)
  const [rulesData, setRulesData] = useState([])
  const [rulesCount, setRulesCount] = useState(0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [dataId, setDataId] = useState(null)
  const [selectedRules, setSelectedRules] = useState([])
  const [loading, setLoading] = useState(false)
  const [bulkDeletion, setBulkDeletion] = useState(false)
  const [totalPerPage, setTotalPerPage] = useState({ label: 25, value: 25 })
  const [sorted, setSorted] = useState(null)

  const actionData = [
    { label: "Turn on Campaign", value: "campaign__turn_on" },
    { label: "Turn off Campaign", value: "campaign__turn_off" },
    { label: "Campaign Notification", value: "campaign__notification" },
    { label: "Turn on Adset", value: "ad_set__turn_on" },
    { label: "Turn off Adset", value: "ad_set__turn_off" },
    { label: "Adset Notification", value: "ad_set__notification" },
    { label: "Increase Daily budget by Adset", value: "ad_set__inc_daily_budget" },
    { label: "Decrease Daily budget by Adset", value: "ad_set__dec_daily_budget" },
    { label: "Increase Lifetime budget by Adset", value: "ad_set__inc_lifetime_budget" },
    { label: "Decrease Lifetime budget by Adset", value: "ad_set__dec_lifetime_budget" },
    { label: "Turn on Ad", value: "ad__turn_on" },
    { label: "Turn off Ad", value: "ad__turn_off" },
    { label: "Ad Notification", value: "ad__notification" }
  ]

  const timeRangeData = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 3 Days", value: "last_3_days" },
    { label: "Last 7 Days", value: "last_7_days" },
    { label: "Last 14 Days", value: "last_14_days" },
    { label: "Last 30 Days", value: "last_30_days" },
    { label: "Last 3 Days, Including Today", value: "last_3_days_incl_today" },
    { label: "Last 7 Days, Including Today", value: "last_7_days_incl_today" },
    { label: "Last 14 Days, Including Today", value: "last_14_days_incl_today" },
    { label: "Last 30 Days, Including Today", value: "last_30_days_incl_today" }
  ]

  const handleFormData = async values => {
    try {
      let params = {}
      
      if (values.ad_account_id) {
        params.ad_account_id = values.ad_account_id.value
      }
      if (values.status) {
        params.status = values.status.value
      }
      if (values.campaign_id) {
        params.campaign_id = values.campaign_id.value
      }
      if (values.ad_set_id) {
        params.ad_set_id = values.ad_set_id.value
      }
      if (values.ad_id) {
        params.ad_id = values.ad_id.value
      }

      getActiveRules(params)
    } catch (error) {
      console.log(error)
    }
  }

  const formikrules = useFormik({
    initialValues: {
      ad_account_id: {},
      status: {},
      campaign_id: {},
      ad_set_id: {},
      ad_id: {}
    },
    onSubmit: handleFormData
  })

  // GET ACTIVE RULES
  const getActiveRules = async (param) => {
    try {
      let current = (currentPageRules > 0) ? currentPageRules : 0
      let obj = { offset: current, limit: totalPerPage.value }

      if (param && param.limit) {
        obj.limit = param.limit
      }

      if (param && param.ad_account_id === null) {

      } else if (param && param.ad_account_id) {
        obj.ad_account_id = param.ad_account_id
      } else if (formikrules && formikrules.values && formikrules.values.ad_account_id && Object.keys(formikrules.values.ad_account_id).length > 0) {
        obj.ad_account_id = formikrules.values.ad_account_id.value
      }

      if (param && param.status === null) {

      } else if (param && param.status) {
        obj.status = param.status
      } else if (formikrules && formikrules.values && formikrules.values.status && Object.keys(formikrules.values.status).length > 0) {
        obj.status = formikrules.values.status.value
      }

      if (param && param.campaign_id === null) {

      } else if (param && param.campaign_id) {
        obj.campaign_id = param.campaign_id
      } else if (formikrules && formikrules.values && formikrules.values.campaign_id && Object.keys(formikrules.values.campaign_id).length > 0) {
        obj.campaign_id = formikrules.values.campaign_id.value
      }

      if (param && param.ad_set_id === null) {

      } else if (param && param.ad_set_id) {
        obj.ad_set_id = param.ad_set_id
      } else if (formikrules && formikrules.values && formikrules.values.ad_set_id && Object.keys(formikrules.values.ad_set_id).length > 0) {
        obj.ad_set_id = formikrules.values.ad_set_id.value
      }
      
      if (param && param.ad_id === null) {

      } if (param && param.ad_id) {
        obj.ad_id = param.ad_id
      } else if (formikrules && formikrules.values && formikrules.values.ad_id && Object.keys(formikrules.values.ad_id).length > 0) {
        obj.ad_id = formikrules.values.ad_id.value
      }

      if (param && param.sort_by) {
        obj.sort_by = param.sort_by
      } else if (sorted) {
        obj.sort_by = sorted
      }

      setLoading(true)
      const { data } = await getRulesAPI(obj)
      setLoading(false)
      if (param && param.limit === 'all') {
        setRulesData(data)
        setRulesCount(data.length)
      } else {
        setRulesData(data.results)
        setRulesCount(data.count)
      }
      
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (activeTab === "rules") {
      getActiveRules()
    }
  }, [activeTab])

  useEffect(() => {
    getActiveRules()
  }, [currentPageRules])

  const renderConditions = (conditions) => {
    return (
      <>
        {conditions.map((a) => (
          <>
            <p><span className="capitalize">{a.condition.replace('_', ' ')}</span> {a.operator} {a.value}</p>
          </>
        ))}
      </>
    )
  }

  const handleRuleStatus = async (id, status) => {
    try {
      await updateRulesAPI(id, { enabled: !status })
      getActiveRules()
      toast.success("Active rule updated successfully!")
    } catch (error) {
      const message = (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors) || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleDelete = (id, bulk = false) => {
    if (bulk) {
      if (selectedRules.length > 0) {
        setBulkDeletion(true)
        setOpenConfirm(!openConfirm)
      } else {
        toast.error("Please select the rules for bulk deletion!")
      }
    } else {
      setBulkDeletion(false)
      setDataId(id)
      setOpenConfirm(!openConfirm)
    }
  }

  const deleteRow = async () => {
    try {
      if (bulkDeletion) {
        if (selectedRules.length > 0) {
          const ids = selectedRules.map((x) => x.id)
          const params = { active_rule_ids: ids }
          await deleteBulkRulesAPI(params)
        }
      } else {
        if (dataId) {
          await deleteRulesAPI(dataId)
        }
      }
      getActiveRules()
      toast.success("Active Rule(s) Deleted!")
      setDataId(null)
      setOpenConfirm(!openConfirm)
      setBulkDeletion(false)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      toast.error(message)
      setBulkDeletion(false)
    }
  }

  const handlePerPage = (e) => {
    setTotalPerPage(e)
    getActiveRules({ limit: e.value })
  }

  const columns = [
    {
      name: "ON/OFF",
      id: 'enabled',
      sortable: true,
      selector: row => <>
        <Toggle
          isChecked={row.enabled}
          onChange={status =>
            handleRuleStatus(row.id, row.enabled)
          }
        />
      </>
    },
    {
      name: "Rule Name",
      id: 'rule_template__name',
      sortable: true,
      selector: row => <>
        {row && row.rule_template && row.rule_template.name}
      </>
    },
    {
      name: "Apply To",
      sortable: false,
      selector: row => <span>{row.meta_campaign ? `Campaign (${row.meta_campaign?.id})` : (row.remote_ad_set) ? `Adset (${row.remote_ad_set?.id})` : (row.remote_ad) ? `Ad (${row.remote_ad?.id})` : ''}</span>
    },
    {
      name: "Rule Action",
      id: 'rule_template__action',
      sortable: true,
      selector: row => <span>{row.rule_template && actionData.filter((item) => item.value === row.rule_template.action)[0].label}</span>
    },
    {
      name: "Period",
      id: 'rule_template__time_range',
      sortable: true,
      selector: row => <span>{row.rule_template && timeRangeData.filter((item) => item.value === row.rule_template.time_range)[0].label}</span>
    },
    {
      name: "Condition",
      sortable: false,
      selector: row => <span>{row.rule_template && renderConditions(row.rule_template.conditions)}</span>
    },
    {
      name: "Actions",
      sortable: false,
      center: true,
      selector: row => (
        <div className="py-3">
          <Button
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => handleDelete(row.id, false)}
          >
            <FaTrash />
          </Button>
        </div>
      )
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  const handleRowSelection = ({ selectedRows }) => {
    setSelectedRules(selectedRows)
  }

  const handleReset = () => {
    formikrules.resetForm()
    setTotalPerPage({ label: 25, value: 25 })
    getActiveRules({ limit : 25, ad_account_id: null, status: null, campaign_id: null, ad_set_id: null, ad_id: null })
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getActiveRules({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-lg p-4 mt-4">
        <form onSubmit={formikrules.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 lg:gap-3 items-center">
            <Dropdown
              placeholder="Account"
              options={adAccounts}
              onChange={(e) => formikrules.setFieldValue("ad_account_id", e)}
              onBlur={formikrules.handleBlur}
              value={formikrules.values.ad_account_id}
              error={formikrules.errors.ad_account_id}
              touched={formikrules.touched.ad_account_id}
            />
            <Dropdown
              placeholder="Campaign"
              options={campagins}
              onChange={(e) => formikrules.setFieldValue("campaign_id", e)}
              onBlur={formikrules.handleBlur}
              value={formikrules.values.campaign_id}
              error={formikrules.errors.campaign_id}
              touched={formikrules.touched.campaign_id}
            />
            <Dropdown
              placeholder="Adset"
              options={adsets}
              onChange={(e) => formikrules.setFieldValue("ad_set_id", e)}
              onBlur={formikrules.handleBlur}
              value={formikrules.values.ad_set_id}
              error={formikrules.errors.ad_set_id}
              touched={formikrules.touched.ad_set_id}
            />
            <Dropdown
              placeholder="Ads"
              options={ads}
              onChange={(e) => formikrules.setFieldValue("ad_id", e)}
              onBlur={formikrules.handleBlur}
              value={formikrules.values.ad_id}
              error={formikrules.errors.ad_id}
              touched={formikrules.touched.ad_id}
            />
            <div>
              <Dropdown
                placeholder="Campaign Delivery"
                options={[
                  { label: "ACTIVE", value: "" },
                  { label: "ACTIVE", value: "ACTIVE" },
                  { label: "ARCHIVED", value: "ARCHIVED" },
                  { label: "DELETED", value: "DELETED" },
                  { label: "PAUSED", value: "PAUSED" }
                ]}
                onChange={(e) => formikrules.setFieldValue("status", e)}
                onBlur={formikrules.handleBlur}
                value={formikrules.values.status}
                error={formikrules.errors.status}
                touched={formikrules.touched.status}
              />
            </div>
            <Button className="button-gradient p-2 flex items-center justify-center h-10 mb-2" type="submit">
              Apply Filters
            </Button>
            <Button className="bg-[#DBD312] p-2 flex items-center justify-center h-10 mb-2" onClick={() => handleReset()}>
              Reset
            </Button>
          </div>
        </form>

        <div className="flex items-center justify-between mb-4">
          <div className="w-[100px]">
            <Dropdown
              placeholder="Select"
              classBox="bg-white"
              options={[
                { label: 'All', value: 'all' },
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 }
              ]}
              onChange={e => handlePerPage(e)}
              value={totalPerPage}
            />
          </div>
          <Button className="bg-[#CC4B43] w-[200px] p-2 flex items-center justify-center h-10 mb-2" onClick={() => handleDelete(null, true)}>
            Bulk Delete
          </Button>
        </div>

        <div className="overflow-y-auto">
          <div className="relative grid py-3">
            <DataTable
              columns={columns}
              data={rulesData}
              responsive={true}
              progressPending={loading}
              progressComponent={<Spinner />}
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleRowSelection}
              className='react-dataTable'
              onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
            />
          </div>
        </div>

        <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
          <div className="flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{rulesData.length}</span>&nbsp;of <span className="font-medium">{rulesCount}</span> results
              </p>
            </div>
          </div>
          <Pagination
            totalPages={Math.ceil(rulesCount / totalPerPage.value)}
            currentPage={currentPageRules}
            setCurrentPage={setCurrentPageRules}
            offset={totalPerPage.value}
          />
        </div>
      </div>

      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title={`Delete Active Rule`}>
        <div class="md:flex items-center">
          <div class="mt-4 md:mt-0 md:text-left">
            <p class="text-sm text-gray-700 mt-1">{`Are you sure you want to delete this ${(activeTab === 'rules') ? 'active rule' : (activeTab === 'templates') ? 'rules template' : ''}?`}</p>
            <p class="text-sm text-gray-700 mt-1">This action cannot be undone.</p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end">
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={deleteRow}>Yes, Delete it</button>
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => setOpenConfirm(!openConfirm)}>Cancel</button>
        </div>
      </BaseModal>
    </>
  )
}
