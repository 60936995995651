import { useState, useEffect } from "react"
import { toast } from "react-toastify"

import { AdPreview } from "../../../components"
import { getCampaignDetailAPI, getFacebookAccounts } from "../../../service"

const AdvancePreview = ({}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [adAccounts, setAddAccounts] = useState([])

  const handleGetFacebookAccount = async () => {
    try {
      const { data } = await getCampaignDetailAPI(10)
      if (data.length) {
        console.log("data", data)
      }
    } catch (error) {
      console.log("error", error)
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  useEffect(() => {
    handleGetFacebookAccount()
  }, [])

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <section className="container max-w-6xlxl mx-auto pt-10">
      <div className="border border-gray-300 rounded-md p-10 min-h-screen">
        <div className="grid grid-cols-3 gap-x-3 gap-y-4">
          {[1, 1, 1, 1, 1, 1].map(item => (
            <AdPreview
              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer dapibus fermentum feugiat. In facilisis turpis dictum, finibus mi vitae, "
              headline="Headline"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer dapibus fermentum feugiat. In facilisis turpis dictum, finibus mi vitae, "
              image="https://dummyimage.com/600x400/000/fff"
              handleRemove={() => {}}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default AdvancePreview
