import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { getAdCreativeAPI, getSelfConfigAPI } from "../../../service";
import { Spinner, Toggle } from "../../../components";

export default function BreakdownExpanded(props) {
  
  const [ads, setAds] = useState([])
  const [loading, setLoading] = useState(false)
  const [allColumns, setColumns] = useState([
    { label: "ID", value: "id", isActive: false },
    { label: "Status", value: "status", isActive: false },
    { label: "Name", value: "name", isActive: false },
    { label: "Image", value: "remote_ad_images", isActive: false },
    { label: "Submitted Leads", value: "ms_submitted_leads", isActive: false },
    { label: "Sold Leads", value: "ms_sold_leads", isActive: false },
    { label: "Clicks", value: "ms_clicks", isActive: true },
    { label: "CTR", value: "ms_ctr", isActive: true },
    { label: "ROI", value: "ms_roi", isActive: true },
    { label: "Conversion Rate", value: "ms_conversion_rate", isActive: false },
    { label: "Sold Rate", value: "ms_sold_rate", isActive: false },
    { label: "EPC", value: "ms_epc", isActive: true },
    { label: "CPC", value: "ms_lpvc", isActive: true },
    { label: "Revenue", value: "ms_revenue", isActive: true },
    { label: "Profit", value: "ms_profit", isActive: false },
    { label: "Spend", value: "spend", isActive: false },
    { label: "Impressions", value: "impressions", isActive: false }
  ])
  const [columnData, setColumnData] = useState([])
  const [isImageOpen, setIsImageOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const getAllAds = async (id) => {
    try {
      const params = { remote_ad_set_ids : id }

      if (props && props.filtered && props.filtered.values.insights_start_date) {
        params.insights_start_date = props.filtered.values.insights_start_date
      }
      if (props && props.filtered && props.filtered.values.insights_end_date) {
        params.insights_end_date = props.filtered.values.insights_end_date
      }
      setLoading(true)
      const { data } = await getAdCreativeAPI(params)
      setLoading(false)
      setAds(data)
    } catch (error) {
      setLoading(false)
      console.log("error response")
    }
  }

    // GET COLUMN ORDERS
    const getColumnOrderList = async () => {
      try {
        const { data } = await getSelfConfigAPI()
        if (data.ad_cols) {
          setColumns(data.ad_cols)
        }
      } catch (error) {
        console.log("ERROR", error)
      }
    }

  useEffect(() => {
    if (props.data) {
      getAllAds(props.data.id)
    }
    getColumnOrderList()
  }, [props])

  const openLightbox = (image) => {
    setSelectedImage(image)
    setIsImageOpen(true)
  }

  const closeLightbox = () => {
    setSelectedImage(null)
    setIsImageOpen(false)
  }

  useEffect(() => {
    const cols = allColumns.map((items) => {
      if (items.value === 'id') {
        return {
          name: "ID",
          id: 'id',
          maxWidth: '50px',
          selector: row => <span>{row.id}</span>
        }
      }
      else if (items.value === 'status') {
        return {
          name: "Status",
          id: 'status',
          selector: row => <div className="flex justify-end">
            <Toggle
              isChecked={(row.status === 'ACTIVE')}
              disabled={true}
            />
          </div>
        }
      }
      else if (items.value === 'name') {
        return {
          name: "Name",
          id: 'name',
          minWidth: '250px',
          selector: row => <span>{row.name}</span>,
          wrap: 'no'
        }
      }
      else if (items.value === 'remote_ad_images') {
        return {
          name: "Image",
          id: 'remote_ad_images',
          selector: row => (
            <>{row.remote_ad_images && row.remote_ad_images[0] && <><img className="w-10 h-10 cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto" src={row.remote_ad_images[0]?.file} onClick={() => openLightbox(row.remote_ad_images[0]?.file)} /></>}</>
          ),
          wrap: 'no'
        }
      }
      else if (items.value === 'ms_submitted_leads') {
        return {
          name: "Submitted Leads",
          id: 'ms_submitted_leads',
          minWidth: '180px',
          center: true,
          selector: row => <span>{row.ms_submitted_leads}</span>
        }
      }
      else if (items.value === 'ms_sold_leads') {
        return {
          name: "Sold Leads",
          id: 'ms_sold_leads',
          minWidth: '180px',
          center: true,
          selector: row => <span>{row.ms_sold_leads}</span>
        }
      }
      else if (items.value === 'ms_clicks') {
        return {
          name: "Clicks",
          id: 'ms_clicks',
          center: true,
          selector: row => <span>{row.ms_clicks}</span>
        }
      }
      else if (items.value === 'ms_ctr') {
        return {
          name: "CTR",
          id: 'ms_ctr',
          center: true,
          selector: row => <span>{row.ms_ctr.toFixed(2)}%</span>
        }
      }
      else if (items.value === 'ms_roi') {
        return {
          name: "ROI",
          id: 'ms_roi',
          center: true,
          selector: row => <span>{row.ms_roi}%</span>
        }
      }
      else if (items.value === 'ms_conversion_rate') {
        return {
          name: "Conversion Rate",
          id: 'ms_conversion_rate',
          center: true,
          minWidth: '150px',
          selector: row => (
            <div className="flex items-center">
              {row.ms_conversion_rate.toFixed(2)}%
            </div>
          )
        }
      }
      else if (items.value === 'ms_sold_rate') {
        return {
          name: "Sold Rate",
          id: 'ms_sold_rate',
          center: true,
          selector: row => (
            <div className="flex items-center">
              {row.ms_sold_rate.toFixed(2)}%
            </div>
          )
        }
      }
      else if (items.value === 'ms_epc') {
        return {
          name: "EPC",
          id: 'ms_epc',
          center: true,
          selector: row => <span>${row.ms_epc}</span>
        }
      }
      else if (items.value === 'ms_lpvc') {
        return {
          name: "CPC",
          id: 'ms_lpvc',
          center: true,
          selector: row => <span>${row.ms_lpvc}</span>
        }
      }
      else if (items.value === 'spend') {
        return {
          name: "Spend",
          id: 'spend',
          center: true,
          selector: row => <span>${row.spend}</span>
        }
      }
      else if (items.value === 'ms_revenue') {
        return {
          name: "Revenue",
          id: 'ms_revenue',
          center: true,
          selector: row => <span>${row.ms_revenue}</span>
        }
      }
      else if (items.value === 'ms_profit') {
        return {
          name: "Profit",
          id: 'ms_profit',
          center: true,
          selector: row => <span>${row.ms_profit}</span>
        }
      }
      else if (items.value === 'impressions') {
        return {
          name: "Impressions",
          id: 'impressions',
          minWidth: '150px',
          center: true,
          selector: row => <span>{row.impressions}</span>
        }
      }
      else {
        return items
      }
    })
    setColumnData(cols)
  }, [allColumns])

  createTheme('solarized', {
    background: {
      default: '#F4F4F4'
    },
    text: {
      primary: '#273240',
      secondary: '#273240',
    },
    divider: {
      default: '#0000001f',
    },
  }, 'dark');

  return (
    <div className="relative py-3">
      <DataTable
        columns={columnData}
        data={ads}
        theme="solarized"
        className="rounded-md border border-2 border-gray-300 m-4 py-3 bg-gray-100"
        progressPending={loading}
        progressComponent={<Spinner />}
      />
    </div>
  );
};
