import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from "react"

export default function CreativeStudio() {

  return (
    <section className="w-full mx-auto px-3 md:px-8 min-h-screen bg-gray-200">

      <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
        <div className="relative h-[600px] flex items-center justify-center">
          <div className="text-center">
            <h1>COMING SOON</h1>
            <p className="text-xl">This page is under construction</p>
          </div>
        </div>
      </div>

    </section>
  )
}
