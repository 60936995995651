import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default function LeadGenerated() {

  const series = [{
    name: "Revenue",
    data: [300000, 350000, 375000, 410000, 450000, 250000, 520000, 550000, 350000, 354150, 578510, 450000]
  }, {
    name: "Spend",
    data: [358196, 544545, 654854, 707887, 815480, 550000, 600000, 630000, 668780, 720000, 900000, 854790]
  }]

  const options = {
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#B6CC43', '#00B2B4'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
      show: true,
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var label = '';
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<div class="px-4 py-4"><b>$' + data + '</b><p>'+ label +'</p>'
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          var val = Math.abs(value)
          if (val >= 1000) {
            val = (val / 1000).toFixed(0) + 'K'
          }
          return val
        }
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height="300px" />
    </div>
  )
}
