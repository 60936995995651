import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"

import { displayErrors } from "../../../helpers"
import { AdCreation } from "../../../components/campagins/createCompagins/conversionForms"
import {
  updateCampaignAPI,
  updateCampaignDataAPI,
  updateImagesAPI,
  getCampaignDetailAPI
} from "../../../service"
import { useNavigate, useParams } from "react-router-dom"

const CreateCampaignSteps = ({}) => {
  const navigate = useNavigate()
  const {campaignId } = useParams()
  const [campaignData, setCampaignData] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    const fetchCampaign = async (id) => {
      const { data } = await getCampaignDetailAPI(id)
      if (data) {
        setCampaignData(data)
      }
    }
    if (campaignId) {
      fetchCampaign(campaignId)
    }
  }, [])

  const formDataContainsFile = (formData) => {
    for (const pair of formData.entries()) {
      const [, value] = pair
      if (value instanceof File) {
        return true
      }
    }
    return false
  }

  const handleFormsData = async ({
    meta_facebook_page_config,
    meta_media_configs,
    ...values
  }) => {
    try {
      const formData = new FormData()

      for (const index in meta_media_configs) {
        formData.append(`meta_media_configs[${index}]is_carousel`, meta_media_configs[index].is_carousel || false)
        if (meta_media_configs[index].id) {
          formData.append(`meta_media_configs[${index}]id`, meta_media_configs[index].id)
        }
        for (const image in meta_media_configs[index].meta_media_images) {
          if (meta_media_configs[index].meta_media_images[image].constructor.name !== "Object") {
            formData.append(`meta_media_configs[${index}]meta_media_images[${image}]file`,
              meta_media_configs[index].meta_media_images[image]
            )
            formData.append(`meta_media_configs[${index}]meta_media_images[${image}]is_video`,
              (meta_media_configs[index].meta_media_images[image].type.startsWith('video/')) ? true : false
            )
          }
        }
      }
     
      setSubmitting(true)
      if (formDataContainsFile(formData)) {
        await updateImagesAPI(parseInt(campaignId), formData)
      }
      
      await updateCampaignDataAPI(parseInt(campaignId), {
        pixel_id: meta_facebook_page_config?.pixel ? meta_facebook_page_config?.pixel?.value : '',
        pixel_name: meta_facebook_page_config?.pixel ? meta_facebook_page_config?.pixel?.label : '',
        custom_event_type: meta_facebook_page_config?.custom_event_type ? meta_facebook_page_config?.custom_event_type?.value : '',
        meta_facebook_page_config: {
          id: meta_facebook_page_config.page.value,
          name: meta_facebook_page_config.page.label,
          call_to_action: meta_facebook_page_config?.call_to_action?.value,
          instant_form_id: meta_facebook_page_config?.instant?.value,
          instant_form_name: meta_facebook_page_config?.instant?.label
        },
        ...values
      })
      setSubmitting(false)
      toast.success("Information added to campagin")
      navigate(`/dashboard/ad-review/${campaignId}`)
    } catch (error) {
      setSubmitting(false)
      setErrors(error?.response?.data)

      const message =  (Object.values(error?.response?.data)[0][0].non_field_errors) ? displayErrors(Object.values(error?.response?.data)[0][0].non_field_errors) : (error?.response?.data) ? displayErrors(error?.response?.data) : "Something Went Wrong"
      confirmAlert({
        title: "Error",
        message: message,
        buttons: [
          {
            label: "Close"
          }
        ]
      })
    }
  }

  return (
    <section className="mx-auto px-6 py-6 bg-gray-200 w-full">
      <div className="rounded-md">
        <AdCreation submitData={handleFormsData} campaignData={campaignData} submitting={submitting} errors={errors} />
      </div>
    </section>
  )
}

export default CreateCampaignSteps
