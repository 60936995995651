import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown } from "../../../components"

export default function LeadGenerated() {

  const series = [60]
  const series1 = [80]

  const options = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    legend: {
      show: false
    },
    colors: ['#B6CC43'],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            fontSize: '30px',
            fontWeight: 'bold',
            color: '#000'
          }
        }
      }
    }
  }

  const options1 = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    legend: {
      show: false
    },
    colors: ['#00B2B4'],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            fontSize: '30px',
            fontWeight: 'bold',
            color: '#000'
          }
        }
      }
    }
  }

  return (
    <div id="chart" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:gap-5 mb-2 items-end">
      <div className="flex flex-col mt-4">
        <Dropdown
          placeholder="Campaign 1"
          options={[
            { label: "1", value: "1" },
            { label: "2", value: "2" }
          ]}
        />
        <ReactApexChart options={options} series={series} type="radialBar" height="270px" />
      </div>
      <div className="flex flex-col mt-4">
        <Dropdown
          placeholder="Campaign 2"
          options={[
            { label: "1", value: "1" },
            { label: "2", value: "2" }
          ]}
        />
        <ReactApexChart options={options1} series={series1} type="radialBar" height="270px" />
      </div>
    </div>
  )
}
