import { AiFillEye } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login"
import { useContext } from "react"

import { Input } from "../components"
import {
  signup,
  loginWithGoogle,
  loginWithFacebook,
  getProfileAPI
} from "../service"
import { SignupSchema } from "../helpers/validationSchema"
import { ProfileContext } from "../context/profileContext"

export default function Signup() {
  const navigate = useNavigate()
  const [_, setProfileData] = useContext(ProfileContext)
  const submitHandler = async ({
    firstName,
    lastName,
    email,
    phone,
    cc,
    password,
    organization_name
  }) => {
    try {
      await signup({
        // name: `${firstName} ${lastName}`,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: cc + phone,
        password,
        organization_name
      })

      toast.success("Account created")
      navigate("/login")
      formik.resetForm()
    } catch (error) {
      console.log("ERROR", error)
      toast.error(error.message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const getProfileData = async () => {
    try {
      const { data } = await getProfileAPI()
      console.log("data", data)
      setProfileData({ ...data[0], loaded: true })
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
    }
  }

  const handleLoginWithGoogle = async ({ data: { credential } = {} }) => {
    try {
      const {
        data: { auth_token }
      } = await loginWithGoogle({ access_token: credential })

      console.log("response", auth_token)
      localStorage.setItem("token", auth_token)
      navigate("/dashboard/change-password")
      formik.resetForm()
      getProfileData()
    } catch (error) {
      const message =
        error?.response.data?.non_field_errors[0] || "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const handleLoginWithFacebook = async ({ data, ...rest }) => {
    try {
      const {
        data: { auth_token }
      } = await loginWithFacebook({ access_token: data.accessToken })

      console.log("response", auth_token)
      localStorage.setItem("token", auth_token)
      navigate("/dashboard/change-password")
      formik.resetForm()
      getProfileData()
    } catch (error) {
      console.log("error?.response", error?.response)
      const message = error?.response.data?.detail || "something went wrong"
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      cc: "",
      organization_name: ""
    },
    validationSchema: SignupSchema,
    onSubmit: submitHandler
  })
  return (
    <section className="flex items-center justify-center min-h-screen bg-gradient">
      <div className="mx-auto container">
        <form
          onSubmit={formik.handleSubmit}
          className="mx-auto max-w-sm bg-white rounded-lg py-10 px-10 flex flex-col"
        >
          <h1 className="text-xl font-bold mb-2">Sign Up</h1>

          <div className="grid grid-cols-2 gap-2">
            <Input
              placeholder="First Name"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={formik.errors.firstName}
              touched={formik.touched.firstName}
            />
            <Input
              placeholder="Last Name"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.errors.lastName}
              touched={formik.touched.lastName}
            />
          </div>
          <div className="grid grid-cols-4 gap-2">
            <Input
              placeholder="CC"
              className="col-span-1 pr-0"
              name="cc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cc}
              error={formik.errors.cc}
              touched={formik.touched.cc}
            />
            <div className="col-span-3">
              <Input
                placeholder="Phone"
                type="tel"
                className="w-full"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.errors.phone}
                touched={formik.touched.phone}
              />
            </div>
          </div>
          <div className="w-full flex flex-col">
            <Input
              placeholder="Organization Name (Optional)"
              name="organization_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organization_name}
              error={formik.errors.organization_name}
              touched={formik.touched.organization_name}
            />
            <Input
              placeholder="E-mail"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <Input
              placeholder="Password"
              type="password"
              Icon={AiFillEye}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              Icon={AiFillEye}
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />

            <div className="flex items-start mt-3">
              <input
                type="checkbox"
                className="mr-2"
                name="agreeToTerms"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.agreeToTerms}
                error={formik.errors.agreeToTerms}
                touched={formik.touched.agreeToTerms}
              />
              <label className="text-xs font-thin">
                By Clicking this, I agree to{" "}
                <Link className="font-medium">Terms & Condition</Link> and{" "}
                <Link className="font-medium">Privacy Policy</Link>{" "}
              </label>
            </div>
            {formik.errors.agreeToTerms && formik.touched.agreeToTerms && (
              <p className="text text-red-500">{formik.errors.agreeToTerms}</p>
            )}

            <button
              type="subimit"
              className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white"
            >
              Sign Up
            </button>

            <div className="relative">
              <hr className="h-px mb-8 mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
              <span className="absolute top-0 left-0 right-0 mx-auto bg-white p-2 w-10 h-10">
                OR
              </span>
            </div>
            <div className="grid grid-cols-2 gap-3 mb-4">
              <LoginSocialGoogle
                client_id="924716124890-r7pofa2phmiehd34t092cp15nlnjn9r8.apps.googleusercontent.com"
                onLoginStart={console.log}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={handleLoginWithGoogle}
                onReject={err => {
                  console.log(err)
                }}
              >
                <button
                  type="button"
                  className="bg-white px-2 py-2 text-center w-full border rounded-xl border-[#00B2B4] flex justify-center items-center"
                >
                  <img src="/static/google.svg" className="w-8 h-8" />
                  Google
                </button>
              </LoginSocialGoogle>
              <LoginSocialFacebook
                appId={"602949464983958" || ""}
                fieldsProfile={
                  "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                }
                onLoginStart={console.log}
                onLogoutSuccess={console.log}
                onResolve={handleLoginWithFacebook}
                onReject={err => {
                  console.log(err)
                }}
              >
                <button
                  type="button"
                  className="bg-white px-2 py-2 text-center w-full border rounded-xl border-[#00B2B4]  flex justify-center items-center"
                >
                  <img src="/static/facebook.svg" className="w-8 h-8" />
                  Facebook
                </button>
              </LoginSocialFacebook>
            </div>

            <div className="flex text-sm justify-center">
              <p>Don’t have an account? </p>{" "}
              <Link to="/login" className="ml-1 text-[#B6CC43]">
                Log in
              </Link>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
