export default function Checkbox({ title, ...props }) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
          {...props}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor="offers" className="font-medium text-gray-900">
          {title}
        </label>
      </div>
    </div>
  )
}
