import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import { BsSearch } from "react-icons/bs"
import { toast } from "react-toastify"
import _ from "lodash"

import {
  Button,
  AddParamatersVericalModal,
  CreateVerticalModal,
  Pagination,
  BaseModal,
  Spinner
} from "../../components"

import { getVerticalsAPI, deleteVerticalsAPI } from "../../service"
import DataTable from 'react-data-table-component'

export default function VerticalsComponent() {

  const [selectedVerticals, setSelectedVerticals] = useState([])
  const [createVertical, setCreateVertical] = useState(false)
  const [addParametersModal, setAddParametersModal] = useState(false)
  const [verticals, setVerticals] = useState([])
  const [currentVertical, setCurrentVertical] = useState({})
  const [verticalCount, setVerticalCount] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [dataId, setDataId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sorted, setSorted] = useState(null)

  useEffect(() => {
    getAllVerticals()
  }, [currentPage])

  const getAllVerticals = async (obj) => {
    try {
      let current = (currentPage > 0) ? currentPage : 0
      let params = { page: current, limit: 10 }
      if (obj && obj.search !== "") {
        params.search_query = obj.search
      }
      if (obj && obj.sort_by) {
        params.sort_by = obj.sort_by
      }
      setLoading(true)
      const { data } = await getVerticalsAPI(params)
      setLoading(false)
      setVerticalCount(data.count)
      setVerticals(data.results)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      toast.error(message)
    }
  }

  const deleteVerticals = async () => {
    try {
      if (dataId) {
        await deleteVerticalsAPI(dataId)

        toast.success("Vertical Created")
        getAllVerticals()
      }
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const handleDelete = (id) => {
    setDataId(id)
    setOpenConfirm(!openConfirm)
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      getAllVerticals({
        search: keyword
      })
    }
  }

  const handleReset = () => {
    setKeyword("")
    getAllVerticals()
  }

  const handleRowSelection = ({ selectedRows }) => {
    setSelectedVerticals(selectedRows)
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getAllVerticals({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  const columns = [
    {
      name: "ID",
      id: "id",
      sortable: true,
      selector: row => <span>{row.id}</span>
    },
    {
      name: "Name",
      id: "name",
      sortable: true,
      selector: row => <>
        {row.name}
      </>
    },
    {
      name: "Actions",
      sortable: false,
      center: true,
      minWidth: '350px',
      selector: row => (
        <div className="py-3">
          <Button
            onClick={() => {
              setCurrentVertical(row)
              setAddParametersModal(true)
            }}
            className={"bg-[#00B2B4] mr-2 py-2 px-3 shadow-sm"}
          >
            +Add Parameter
          </Button>
          <Button
            className={"bg-[#DBD312] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => {
              setCurrentVertical(row)
              setCreateVertical(true)
            }}
          >
            Edit
          </Button>
          <Button
            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        backgroundColor: '#E3E3E3'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  return (
    <section className="w-full mx-auto px-3 md:px-8 min-h-screen bg-gray-200">
      <CreateVerticalModal
        open={createVertical}
        setOpen={value => {
          getAllVerticals()
          setCreateVertical(value)
          setCurrentVertical({})
        }}
        vertical={currentVertical}
      />
      <AddParamatersVericalModal
        open={addParametersModal}
        setOpen={setAddParametersModal}
        verticalId={currentVertical?.id}
        data={currentVertical}
        type='offer'
        getAllData={getAllVerticals}
      />
      <div>
        <div className="mt-8 flex flex-col md:flex-row justify-end w-full">
          <Button
            onClick={() => setCreateVertical(true)}
            text={""}
            className="px-7 py-2 bg-[#B6CC43] rounded-xl w-fit mr-3 mb-4"
          >
            Create Offer
          </Button>
          <span className="relative flex rounded-md shadow-sm w-full md:w-1/3 mb-4">
            <input
              type="text"
              name="account-number"
              id="account-number"
              className="block w-full rounded-xl border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B6CC43] sm:text-sm sm:leading-6"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleSearch}
            />
            <span className="absolute flex items-center cursor-pointer pr-3 top-2 right-0" onClick={() => getAllVerticals({ search: keyword })}>
              <BsSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </span>
          <Button className="px-4 py-2 bg-[#DBD312] w-fit rounded-xl ml-3 mb-4" onClick={() => handleReset()}>
            Reset
          </Button>
        </div>

        <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
          <div className="-my-2 mx-0 md:-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative px-3 py-3">
                <DataTable
                  columns={columns}
                  data={verticals}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<Spinner />}
                  customStyles={customStyles}
                  selectableRows
                  onSelectedRowsChange={handleRowSelection}
                  className='react-dataTable'
                  onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{verticals.length}</span> of <span className="font-medium">{verticalCount}</span> results
            </p>
          </div>
        </div>
        <Pagination
          totalPages={Math.ceil(verticalCount / 10)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          offset={10}
        />
      </div>

      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title="Delete Vertical?">
        <div class="md:flex items-center">
          <div class="mt-4 md:mt-0 md:text-left">
            <p class="text-sm text-gray-700 mt-1">Are you sure you want to delete this vertical?</p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end">
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={deleteVerticals}>Yes, Delete it</button>
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => { setOpenConfirm(!openConfirm) }}>Cancel</button>
        </div>
      </BaseModal>
    </section>
  )
}
