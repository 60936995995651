import { AiFillEye } from "react-icons/ai"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useFormik } from "formik"
import { toast } from "react-toastify"

import { Input } from "../components"
import { joinTeamResetPassword } from "../service"
import { JoinTeamSchema } from "../helpers/validationSchema"

export default function JoinTeam() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const submitHandler = async ({ password }) => {
    try {
      await joinTeamResetPassword({
        password,
        token: searchParams.get("token")
      })

      toast.success("Account created")
      navigate("/login")
      formik.resetForm()
    } catch (error) {
      console.log("ERROR", error)
      toast.error(error.message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: JoinTeamSchema,
    onSubmit: submitHandler
  })
  return (
    <section className="flex items-center justify-center min-h-screen bg-gradient">
      <div className="mx-auto container">
        <form
          onSubmit={formik.handleSubmit}
          className="mx-auto max-w-sm bg-white rounded-lg py-10 px-10 flex flex-col"
        >
          <h1 className="text-xl font-bold mb-2">Sign Up</h1>

          <div className="w-full flex flex-col">
            <Input
              placeholder="Organization Name (Optional)"
              name="organization"
              disabled
              className="disabled:opacity-60"
              value={searchParams.get("org_name")}
            />
            <Input
              placeholder="E-mail"
              type="email"
              name="email"
              disabled
              className="disabled:opacity-60"
              value={searchParams.get("email")}
            />
            <Input
              placeholder="Password"
              type="password"
              Icon={AiFillEye}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              Icon={AiFillEye}
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />

            <button
              type="subimit"
              className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}
