import { Fragment, useState, useContext } from "react"
import { Dialog, Transition, Popover } from "@headlessui/react"
import { Link } from "react-router-dom"
import { FaBars } from "react-icons/fa"
import { ImCross } from "react-icons/im"
import { MdChat } from "react-icons/md"
import { BiUser } from "react-icons/bi"
import Avatar from "react-avatar"
import { Outlet } from "react-router-dom"

import Disclosure from "./disclosure"

import { ProfileContext } from "../../context/profileContext"

const navigation = [
  {
    isLink: true,
    name: "Users",
    href: "/admin/users",
    Icon: BiUser
  },

  {
    isLink: true,
    name: "Feedback",
    href: "/admin/feedback",
    Icon: MdChat
  }
]

const routesToName = {
  "/admin/users": "Users",
  "/admin/feedback": "Feedback"
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function AdminLayout() {
  const [profile] = useContext(ProfileContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [togglePopover, setPopover] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem("token")
    window.location.href = "/login"
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-full md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="bg-gray-600 fixed inset-0 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-fit flex-1 flex-col bg-white shadow-md pt-5 pb-4">
                  {/* Mobile */}
                  <div className="flex items-center justify-between px-4">
                    <img
                      className="h-8 w-32 pr-1"
                      width={150}
                      height={50}
                      src={"/static/logo-full.svg"}
                      alt="Sweet Scene"
                      quality={100}
                    />
                    <div
                      onClick={() => setSidebarOpen(false)}
                      className="cursor-pointer"
                    >
                      <ImCross className="text-gray-500" />
                    </div>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1">
                      {navigation.map(({ name, href, Icon, subMenu = [] }) => (
                        <>
                          <Link
                            key={name}
                            to={href}
                            className={classNames(
                              href === window.location.pathname
                                ? "bg-light-purple"
                                : " hover:text-[#B6CC43]",
                              " border-l-2 border-l-white hover:border-l-[#B6CC43] group mx-auto flex items-center py-4 text-sm font-medium text-gray-500 justify-start"
                            )}
                          >
                            <div className="mx-3">
                              <Icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="font-bold block">{name}</p>
                          </Link>
                          {subMenu.length > 0 && (
                            <Disclosure subMenu={subMenu} />
                          )}
                        </>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden group md:fixed md:inset-y-0 md:flex w-14 hover:md:w-52 md:flex-col hover:lg:w-72 z-20">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto bg-white shadow-md pt-5">
            <div className="flex flex-shrink-0 items-center">
              <img
                className="hidden h-8 pl-4 group-hover:block"
                width={200}
                height={50}
                src={"/static/logo-full.svg"}
                alt="Sweet Scene"
              />
              <img
                className="block h-8 w-32 pl-4 group-hover:hidden"
                width={150}
                height={50}
                src={"/static/logo.svg"}
                alt="Sweet Scene"
              />
            </div>
            <div className="mt-5 flex flex-1 flex-col">
              <nav className="flex-1 space-y-1 pb-4">
                {/* desktop */}
                {navigation.map(({ name, href, Icon, subMenu = [] }) => (
                  <>
                    <Link
                      key={name}
                      to={href}
                      className={classNames(
                        // href === asPath
                        href === window.location.pathname
                          ? "bg-light-purple"
                          : " hover:text-[#B6CC43]",
                        " border-l-8 border-l-white hover:border-l-[#B6CC43] group mx-auto flex items-center py-4 text-sm font-medium text-gray-500 group-hover:lg:pl-8 group-hover:justify-start justify-center"
                      )}
                    >
                      <span className="group-hover:md:mr-3 group-hover:md:ml-0">
                        <Icon className="mx-auto h-6 w-6" aria-hidden="true" />
                      </span>
                      <p className="hidden font-bold group-hover:md:block">
                        {name}
                      </p>
                    </Link>
                    {subMenu.length > 0 && <Disclosure subMenu={subMenu} />}
                  </>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div
          className="px-5 py-5 shadow-md bg-white"
          onClick={() => {
            setSidebarOpen(true)
          }}
        >
          <div className="flex justify-between">
            <div className="flex">
              <FaBars size={24} />
              <h1 className="text-[#9B9B9B] text-xl font-semibold ml-5">
                {routesToName[window.location.pathname]}
              </h1>
            </div>
            <div className="relative">
              <Popover
                as="div"
                className="relative inline-block text-left"
                isOpen={togglePopover}
                onClose={() => setPopover(false)}
              >
                <Popover.Button className="px-4 py-2 font-medium">
                  {profile.loaded && (
                    <div>
                      <Avatar
                        name={profile.name}
                        size="40"
                        className="w-6"
                        round
                        color="#B6CC43"
                      />
                      <span className="text-xs ml-2">
                        Hi, {profile?.name?.split(" ")[0]}
                      </span>
                    </div>
                  )}
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white border border-gray-300 rounded-md p-4 w-full">
                  <Popover.Button onClick={handleLogout}>Logout</Popover.Button>
                </Popover.Panel>
              </Popover>
            </div>
          </div>
        </div>
        <main className="mb-20 flex flex-1 flex-col ">
          <Outlet />
        </main>
      </div>
    </>
  )
}

export default AdminLayout
