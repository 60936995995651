import { AiFillEye } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useState } from "react"

import { Input } from "../components"

import { login } from "../service"
import { LoginSchema } from "../helpers/validationSchema"

export default function Login() {
  const navigate = useNavigate()
  const [showPassword, setPasswordStatus] = useState(false)

  const submitHandler = async values => {
    try {
      navigate("/admin/users")
      return
      const {
        data: { token }
      } = await login(values)

      console.log("response", token)
      localStorage.setItem("token", token)
      navigate("/dashboard/campagins")
      formik.resetForm()
    } catch (error) {
      const message =
        error?.response.data?.non_field_errors[0] || "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberMe: false
    },
    validationSchema: LoginSchema,
    onSubmit: submitHandler
  })

  return (
    <section className="flex items-center justify-center min-h-screen bg-gradient">
      <div className="mx-auto container">
        <form
          onSubmit={formik.handleSubmit}
          className="mx-auto max-w-sm bg-white rounded-lg py-10 px-10 flex flex-col"
        >
          <div className="w-4/6 mx-auto mb-3">
            <img src="/static/logo-full.svg" />
          </div>
          <h1 className="text-xl font-bold mb-2">Admin Login</h1>
          <Input
            placeholder="E-mail"
            name="username"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            error={formik.errors.username}
            touched={formik.touched.username}
          />
          <div className="w-full flex flex-col">
            <Input
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              Icon={AiFillEye}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              iconClick={() => setPasswordStatus(old => !old)}
              iconClasses="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            />

            <div className="flex justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  name="rememberMe"
                  Icon={AiFillEye}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rememberMe}
                  error={formik.errors.rememberMe}
                  touched={formik.touched.rememberMe}
                />
                <label className="text-xs font-thin">remember me</label>
              </div>
            </div>

            <button
              type="submit"
              className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}
