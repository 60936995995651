import { useEffect, useLayoutEffect, useRef, useState, useImperativeHandle } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs"
import { FaCopy, FaDollarSign, FaPercent, FaUsers } from "react-icons/fa"

export default function SummartStats({ dashboard }) {

  const { stats } = useSelector(({ common }) => common)

  const compareStats = (a, b) => {
    return (a >= b) ? <BsFillArrowUpCircleFill className="ml-3 pb-1 text-green-500" size={23} /> : <BsFillArrowDownCircleFill className="ml-3 pb-1" size={23} fill="red" />
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 lg:gap-5 mb-4 items-end mt-8">
        <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
          <div><div className="rounded-full stats-circle p-5"><FaUsers size="25" /></div></div>
          <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
            <div className="flex flex-col">
              <p className="flex items-start text-xs 4xl:text-sm">Sold Leads
                {compareStats(stats?.ms_sold_leads, stats?.previous?.ms_sold_leads)}
              </p>
              <h4 className="text-lg font-bold text-gray-900">{stats?.ms_sold_leads || 0}</h4>
            </div>
            <div className="flex flex-col mt-2 4xl:mt-0">
              <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
              <h4 className="text-lg font-bold text-gray-900">{stats?.previous?.ms_sold_leads || 0}</h4>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
          <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
          <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
            <div className="flex flex-col">
              <p className="flex items-start text-xs 4xl:text-sm">Revenue
                {compareStats(stats?.ms_revenue, stats?.previous?.ms_revenue)}
              </p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.ms_revenue.toFixed(2) || 0}</h4>
            </div>
            <div className="flex flex-col mt-2 4xl:mt-0">
              <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.ms_revenue.toFixed(2) || 0}</h4>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
          <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
          <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
            <div className="flex flex-col">
              <p className="flex items-start text-xs 4xl:text-sm">Amount Spent
                {compareStats(stats?.spend, stats?.previous?.spend)}
              </p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.spend.toFixed(2) || 0}</h4>
            </div>
            <div className="flex flex-col mt-2 4xl:mt-0">
              <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.spend.toFixed(2) || 0}</h4>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
          <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
          <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
            <div className="flex flex-col">
              <p className="flex items-start text-xs 4xl:text-sm">Profit
                {compareStats(stats?.ms_profit, stats?.previous?.ms_profit)}
              </p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.ms_profit.toFixed(2) || 0}</h4>
            </div>
            <div className="flex flex-col mt-2 4xl:mt-0">
              <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
              <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.ms_profit.toFixed(2) || 0}</h4>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
          <div><div className="rounded-full stats-circle p-5"><FaPercent size="25" /></div></div>
          <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
            <div className="flex flex-col">
              <p className="flex items-start text-xs 4xl:text-sm">ROI
                {compareStats(stats?.ms_roi, stats?.previous?.ms_roi)}
              </p>
              <h4 className="text-lg font-bold text-gray-900">{stats?.ms_roi.toFixed(2) || 0}%</h4>
            </div><div className="flex flex-col mt-2 4xl:mt-0">
              <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
              <h4 className="text-lg font-bold text-gray-900">{stats?.previous?.ms_roi.toFixed(2) || 0}%</h4>
            </div>
          </div>
        </div>
      </div>

      {(dashboard) && <>
        <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 lg:gap-5 mb-4 items-end mt-4">
          <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
            <div><div className="rounded-full stats-circle p-5"><FaUsers size="25" /></div></div>
            <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
              <div className="flex flex-col">
                <p className="flex items-start text-xs 4xl:text-sm">Average Daily<br /> Sold Leads
                  {compareStats(stats?.ms_sold_leads_avg, stats?.previous?.ms_sold_leads_avg)}
                </p>
                <h4 className="text-lg font-bold text-gray-900">{Math.round(stats?.ms_sold_leads_avg) || 0}</h4>
              </div>
              <div className="flex flex-col mt-2 4xl:mt-0">
                <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
                <h4 className="text-lg font-bold text-gray-900">{Math.round(stats?.previous?.ms_sold_leads_avg) || 0}</h4>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
            <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
            <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
              <div className="flex flex-col">
                <p className="flex items-start text-xs 4xl:text-sm">Average Daily<br /> Revenue
                  {compareStats(stats?.ms_revenue_avg, stats?.previous?.ms_revenue_avg)}
                </p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.ms_revenue_avg.toFixed(2) || 0}</h4>
              </div>
              <div className="flex flex-col mt-2 4xl:mt-0">
                <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.ms_revenue_avg.toFixed(2) || 0}</h4>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
            <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
            <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
              <div className="flex flex-col">
                <p className="flex items-start text-xs 4xl:text-sm">Average Daily<br /> Amount Spent
                  {compareStats(stats?.spend_avg, stats?.previous?.spend_avg)}
                </p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.spend_avg.toFixed(2) || 0}</h4>
              </div>
              <div className="flex flex-col mt-2 4xl:mt-0">
                <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.spend_avg.toFixed(2) || 0}</h4>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
            <div><div className="rounded-full stats-circle p-5"><FaDollarSign size="25" /></div></div>
            <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
              <div className="flex flex-col">
                <p className="flex items-start text-xs 4xl:text-sm">Average Daily<br /> Profit
                  {compareStats(stats?.ms_profit_avg, stats?.previous?.ms_profit_avg)}
                </p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.ms_profit_avg.toFixed(2) || 0}</h4>
              </div>
              <div className="flex flex-col mt-2 4xl:mt-0">
                <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
                <h4 className="text-lg font-bold text-gray-900">${stats?.previous?.ms_profit_avg.toFixed(2) || 0}</h4>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-xl 3xl:h-30 p-5 flex items-center mb-4">
            <div><div className="rounded-full stats-circle p-5"><FaPercent size="25" /></div></div>
            <div className="flex flex-col 4xl:flex-row justify-between w-full ml-5">
              <div className="flex flex-col">
                <p className="flex items-start text-xs 4xl:text-sm">Average Daily<br /> ROI
                  {compareStats(stats?.ms_roi_avg, stats?.previous?.ms_roi_avg)}
                </p>
                <h4 className="text-lg font-bold text-gray-900">{stats?.ms_roi_avg.toFixed(2) || 0}%</h4>
              </div><div className="flex flex-col mt-2 4xl:mt-0">
                <p className="flex items-center text-xs 4xl:text-sm mb-2">Previous Period</p>
                <h4 className="text-lg font-bold text-gray-900">{stats?.previous?.ms_roi_avg.toFixed(2) || 0}%</h4>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  )
}
