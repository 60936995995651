import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from "react"
import { BsSearch } from "react-icons/bs"
import { toast } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"
import _ from "lodash"

import { Button, CreateUserModal, Pagination } from "../../components"

import { getVerticalsAPI, deleteVerticalsAPI } from "../../service"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Users() {
  const checkbox = useRef()

  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [createUsers, setCreateUsers] = useState(false)
  const [totalItems, setTotalItems] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [users, setUsers] = useState([
    {
      id: "12345",
      firstName: "Wasif",
      lastName: "Jameel",
      email: "wasif.jameel@crowdbotics.com",
      admin: "Rakesh",
      role: "Manager"
    },
    {
      id: "12345",
      firstName: "Wasif",
      lastName: "Jameel",
      email: "wasif.jameel@crowdbotics.com",
      admin: "Rakesh",
      role: "Manager"
    },
    {
      id: "12345",
      firstName: "Wasif",
      lastName: "Jameel",
      email: "wasif.jameel@crowdbotics.com",
      admin: "Rakesh",
      role: "Manager"
    },
    {
      id: "12345",
      firstName: "Wasif",
      lastName: "Jameel",
      email: "wasif.jameel@crowdbotics.com",
      admin: "Rakesh",
      role: "Manager"
    },
    {
      id: "12345",
      firstName: "Wasif",
      lastName: "Jameel",
      email: "wasif.jameel@crowdbotics.com",
      admin: "Rakesh",
      role: "Manager"
    }
  ])
  const [currentUsers, setCurrentUsers] = useState({})
  const [search, setSearch] = useState("")

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedUsers.length > 0 && selectedUsers.length < users.length
    setChecked(selectedUsers.length === users.length)
    setIndeterminate(isIndeterminate)
    checkbox.current.indeterminate = isIndeterminate
  }, [selectedUsers, users])

  function toggleAll() {
    setSelectedUsers(checked || indeterminate ? [] : users)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = async (params = { name: search }) => {
    try {
      return
      const {
        data: { data, count }
      } = await getVerticalsAPI(params)

      setTotalItems(count)
      setUsers(data)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const debouncedChangeHandler = useCallback(_.debounce(getAllUsers, 1000), [])

  const deleteUsers = async id => {
    try {
      await deleteVerticalsAPI(id)

      toast.success("Vertical Created")
      getAllUsers()
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const deleteUsersConfirmation = id => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUsers(id)
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    })
  }

  const handleInputChange = ({ target: { value } }) => {
    console.log("value ", value)
    setSearch(value)

    debouncedChangeHandler({ name: value })
  }
  return (
    <section className="container max-w-6xlxl mx-auto">
      <CreateUserModal
        open={createUsers}
        setOpen={value => {
          getAllUsers()
          setCreateUsers(value)
          setCurrentUsers({})
        }}
        user={currentUsers}
      />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex justify-start w-full">
          <Button
            onClick={() => setCreateUsers(true)}
            className="px-7 py-2 bg-[#B6CC43] rounded-xl mr-3 w-auto"
          >
            Create User
          </Button>
          <span className="relative flex rounded-md shadow-sm flex-grow">
            <input
              type="text"
              name="account-number"
              id="account-number"
              className="block w-full rounded-xl border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B6CC43] sm:text-sm sm:leading-6"
              placeholder="Search"
              onChange={handleInputChange}
              value={search}
            />
            <span className="absolute flex items-center pr-3 top-2 right-0">
              <BsSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </span>
          <Button
            text={""}
            className="px-7 py-2 bg-[#B6CC43] rounded-xl ml-3 w-auto"
          >
            Filter
          </Button>
        </div>

        <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedUsers.length > 0 && (
                  <div className="absolute top-0 left-14 flex h-12 items-center space-x-3 bg-white sm:left-12">
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Bulk edit
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Delete all
                    </button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Admin
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Role
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-sm text-center font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users.map(item => (
                      <tr
                        key={item.email}
                        className={
                          selectedUsers.includes(item)
                            ? "bg-gray-50"
                            : "hover:bg-gray-100"
                        }
                      >
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedUsers.includes(item) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-[#B6CC43]" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
                            value={item.email}
                            checked={selectedUsers.includes(item)}
                            onChange={e =>
                              setSelectedUsers(
                                e.target.checked
                                  ? [...selectedUsers, item]
                                  : selectedUsers.filter(p => p !== item)
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedUsers.includes(item)
                              ? "text-indigo-600"
                              : "text-gray-900"
                          )}
                        >
                          {item.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.firstName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.admin}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.role}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                          <Button
                            className={"bg-[#DBD312] mr-2 py-2 px-3 shadow-sm"}
                            onClick={() => {
                              setCurrentUsers(item)
                              setCreateUsers(true)
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                            onClick={() => deleteUsersConfirmation(item.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 justify-between sm:hidden">
        <Button className="border-bg bg-[#B6CC43] py-2 px-7 rounded-full">
          Previous
        </Button>
        <Button className="border-bg bg-[#B6CC43] py-2 px-7 rounded-full">
          Next
        </Button>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{users.length}</span>
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
        </div>
        <Pagination
          totalPages={Math.ceil(totalItems / 10)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  )
}
