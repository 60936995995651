import React, { Component } from "react";

export default function LowestPerformers() {

  const performerCols = [
    "Name",
    "Leads",
    "Conversion",
    "Profit",
    "ROI"
  ]
  const performerData = [
    {
      name: "Campaign Name",
      leads: "250",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      leads: "250",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      leads: "250",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      leads: "250",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
    {
      name: "Campaign Name",
      leads: "250",
      conversion: "25",
      profit: "20",
      roi: "20"
    },
  ]

  return (
    <div className="overflow-y-auto mt-12">
      <table className="min-w-full table-fixed divide-y divide-gray-300 rounded-2xl overflow-hidden border-spacing-0">
        <thead className="bg-[#D9D9D9] rounded-2xl">
          <tr>
            {performerCols.map(label => (
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y-2 divide-gray-200 bg-[#F4F4F4]">
          {performerData.map(
            ({ name, leads, conversion, profit, roi }, index) => (
              <tr key={index} className={"hover:bg-gray-100"}>
                <td className="whitespace-nowrap py-4 px-3 text-sm font-medium flex">
                  <p className="font-semibold">{name}</p>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span>{leads}</span> <img src="/static/graphup.png" className="ml-1" width={13} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{conversion}%</span> <img src="/static/graphdown.png" className="ml-1" width={13} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{profit}%</span> <img src="/static/graphup.png" className="ml-1" width={13} />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1">
                <span>{roi}</span> <img src="/static/graphdown.png" className="ml-1" width={13} />
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}
