import React, { useEffect, useContext, useState } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-confirm-alert/src/react-confirm-alert.css"
import "rsuite/dist/rsuite.min.css"

import { getProfileAPI } from "./service"
import { ProfileContext } from "./context/profileContext"
import {
  Login,
  Signup,
  ForgetPawword,
  Settings,
  ChangePassword,
  Verticals,
  LandingPages,
  RulesAndAutomation,
  Adset,
  AdCreative,
  AdminLogin,
  Users,
  Feedback,
  UserManagement,
  PartialSigup,
  CampaignStructure,
  AudienceTargeting,
  AdCreation,
  AdReview,
  AdvancePreview,
  CampaignTesting,
  CampaignPerformance,
  DraftCampagins,
  CampaignLists,
  LogManagement,
  Dashboard,
  CreativeStudio,
  Adwords
} from "./pages"
import { DashboardLayout, AdminLayout } from "./components"

function App() {
  const [_, setProfileData] = useContext(ProfileContext)
  const [isAuth, setAuth] = useState(true)

  const getProfileData = async () => {
    try {
      const { data } = await getProfileAPI()
      setProfileData({ ...data, loaded: true })
      setAuth(true)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      // console.log("error?.response?", error.response)
      // console.log("ERROR", error)
    }
  }

  useEffect(() => {
    getProfileData()
  }, [])
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgetPawword />} />

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route
              path="/dashboard"
              element={
                <Protected isAuth={isAuth}>
                  <Dashboard />
                </Protected>
              }
            />
            <Route
              path="/dashboard/settings"
              element={
                <Protected isAuth={isAuth}>
                  <ChangePassword />
                </Protected>
              }
            />
            <Route
              path="/dashboard/change-password"
              element={
                <Protected isAuth={isAuth}>
                  <ChangePassword />
                </Protected>
              }
            />

            <Route
              path="/dashboard/offers"
              element={
                <Protected isAuth={isAuth}>
                  <Verticals />
                </Protected>
              }
            />
            <Route
              path="/dashboard/landing-page"
              element={
                <Protected isAuth={isAuth}>
                  <LandingPages />
                </Protected>
              }
            />
            <Route
              path="/dashboard/campagins"
              element={
                <Protected isAuth={isAuth}>
                  <CampaignLists />
                </Protected>
              }
            />
            <Route
              path="/dashboard/drafts"
              element={
                <Protected isAuth={isAuth}>
                  <DraftCampagins />
                </Protected>
              }
            />
            
            <Route
              path="/dashboard/campagins-structure/:campaignId?"
              element={
                <Protected isAuth={isAuth}>
                  <CampaignStructure />
                </Protected>
              }
            />
            <Route
              path="/dashboard/audience-targeting/:campaignId?"
              element={
                <Protected isAuth={isAuth}>
                  <AudienceTargeting />
                </Protected>
              }
            />
            <Route
              path="/dashboard/ad-creation/:campaignId?"
              element={
                <Protected isAuth={isAuth}>
                  <AdCreation />
                </Protected>
              }
            />
            <Route
              path="/dashboard/ad-review/:campaignId?"
              element={
                <Protected isAuth={isAuth}>
                  <AdReview />
                </Protected>
              }
            />
            <Route
              path="/dashboard/advance-review"
              element={
                <Protected isAuth={isAuth}>
                  <AdvancePreview />
                </Protected>
              }
            />
            <Route
              path="/dashboard/automation"
              element={
                <Protected isAuth={isAuth}>
                  <RulesAndAutomation />
                </Protected>
              }
            />
            <Route
              path="/dashboard/adset"
              element={
                <Protected isAuth={isAuth}>
                  <Adset />
                </Protected>
              }
            />
            <Route
              path="/dashboard/adcreative"
              element={
                <Protected isAuth={isAuth}>
                  <AdCreative />
                </Protected>
              }
            />
            <Route
              path="/dashboard/user-management"
              element={
                <Protected isAuth={isAuth}>
                  <UserManagement />
                </Protected>
              }
            />
            <Route
              path="/dashboard/testing"
              element={
                <Protected isAuth={isAuth}>
                  <CampaignTesting />
                </Protected>
              }
            />
            <Route
              path="/dashboard/logs"
              element={
                <Protected isAuth={isAuth}>
                  <LogManagement />
                </Protected>
              }
            />
            <Route
              path="/dashboard/campaign-performance"
              element={
                <Protected isAuth={isAuth}>
                  <CampaignPerformance />
                </Protected>
              }
            />
            <Route
              path="/dashboard/creative-studio"
              element={
                <Protected isAuth={isAuth}>
                  <CreativeStudio />
                </Protected>
              }
            />
            <Route
              path="/dashboard/adwords"
              element={
                <Protected isAuth={isAuth}>
                  <Adwords />
                </Protected>
              }
            />
          </Route>

          <Route path="/admin" element={<AdminLayout />}>
            <Route path="login" element={<AdminLogin />} />
            <Route path="users" element={<Users />} />
            <Route path="feedback" element={<Feedback />} />
          </Route>

          <Route path="join-team" element={<PartialSigup />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </>
  )
}

export default App

function Protected({ children, isAuth = true }) {
  let token = localStorage.getItem("token")

  if (!isAuth) window.location.href = "/login"

  if (!token) {
    return <Navigate to="/login" replace />
  }
  return children
}
