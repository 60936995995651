import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { BsSearch, BsArrowCounterclockwise } from "react-icons/bs"
import { toast } from "react-toastify"
import { getCampaginsAPI, deleteCampaignAPI } from "../../service"
import { Pagination, Spinner, BaseModal, Button, Input } from "../../components"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import DataTable from 'react-data-table-component'
import moment from 'moment'
import 'moment-timezone'

export default function DraftComponents() {

  const navigate = useNavigate()
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedVerticals, setSelectedVerticals] = useState([])
  const [campagins, setAllCampaigns] = useState([])
  const [totalItems, setCampaignsCount] = useState(0)
  const [rowActionIndex, setRowActionIndex] = useState(-1)
  const [currentPage, setCurrentPage] = useState(0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [dataId, setDataId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [sorted, setSorted] = useState(null)

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  const handleFormData = async values => {
    try {
      let params = {}

      if (values.insights_start_date) {
        params.insights_start_date = values.insights_start_date
      }
      if (values.insights_end_date) {
        params.insights_end_date = values.insights_end_date
      }
      getAllCampagins(params)
    } catch (error) {

    }
  }

  const formik = useFormik({
    initialValues: {
      insights_start_date: moment().format('YYYY-MM-DD'),
      insights_end_date: moment().format('YYYY-MM-DD')
    },
    onSubmit: handleFormData
  })

  useEffect(() => {
    getAllCampagins({ insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date })
  }, [currentPage])

  const getAllCampagins = async (param) => {

    let current = (currentPage > 0) ? currentPage : 0
    let obj = { offset: current, limit: 10, drafts: true }

    if (param && param.search) {
      obj.search_query = param.search
    }
    if (param && param.ad_account_id) {
      obj.ad_account_id = param.ad_account_id
    }
    if (param && param.insights_start_date) {
      obj.insights_start_date = param.insights_start_date
    }
    if (param && param.insights_end_date) {
      obj.insights_end_date = param.insights_end_date
    }

    if (param && param.sort_by) {
      obj.sort_by = param.sort_by
    }

    try {
      setLoading(true)
      const { data } = await getCampaginsAPI(obj)
      setLoading(false)
      setCampaignsCount(data.count)
      setAllCampaigns(data.results)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      toast.error(message)
    }
  }

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedVerticals.length > 0 &&
      selectedVerticals.length < campagins.length
    setIndeterminate(isIndeterminate)
  }, [selectedVerticals, campagins])

  const deleteRow = async () => {
    try {
      if (dataId) {
        setOpenConfirm(!openConfirm)
        setRowActionIndex(-1)
        await deleteCampaignAPI(dataId)
        getAllCampagins({ insights_start_date: formik.values.insights_start_date, insights_end_date: formik.values.insights_end_date })
        toast.success("Campaign Deleted!")
      }
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      toast.error(message)
    }
  }

  const handleDelete = (id) => {
    setDataId(id)
    setOpenConfirm(!openConfirm)
  }

  const handleReset = () => {
    setKeyword('')
    formik.setFieldValue("insights_start_date", moment().format('YYYY-MM-DD'))
    formik.setFieldValue("insights_end_date", moment().format('YYYY-MM-DD'))
    getAllCampagins({ insights_start_date: moment().format('YYYY-MM-DD'), insights_end_date: moment().format('YYYY-MM-DD') })
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      getAllCampagins({
        search: keyword
      })
    }
  }

  const handleEdit = (item) => {
    navigate(`/dashboard/campagins-structure/${item.id}`)
  }

  const handleSorting = (col, direction) => {
    setSorted(`${(direction === 'desc') ? '-' : ''}${col.id}`)
    getAllCampagins({ sort_by: `${(direction === 'desc') ? '-' : ''}${col.id}` })
  }

  const columns = [
    {
      name: "ID",
      id: 'id',
      sortable: true,
      selector: row => <span>{row.id}</span>
    },
    {
      name: "Name",
      id: "name",
      sortable: true,
      selector: row => <span>{row.name}</span>
    },
    {
      name: "Delivery",
      sortable: false,
      selector: row => <span>PENDING</span>
    },
    {
      name: "Actions",
      id: 'actions',
      center: true,
      selector: row => (
        <div className="py-3">
          <button className="my-2 md:my-0 px-7 py-2 bg-[#DBD312] text-white rounded-xl ml-3" onClick={() => handleEdit(row)}>
            Edit
          </button>
          <button
            className="my-2 md:my-0 px-7 py-2 bg-[#CC4B43] text-white rounded-xl ml-3"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
        </div>
      )
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: '600',
        paddingTop: '0.875rem',
        paddingBottom: '0.875rem',
        paddingRight: '0.75rem',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        backgroundColor: '#E3E3E3'
      },
    },
    rows: {
      style: {
        boxShadow: '10px 10px 20px 5px #5E84C24F'
      }
    }
  }

  return (
    <section className="mx-auto w-full px-3 md:px-8 min-h-screen bg-gray-200">
      <div>
        <div className="mt-8 flex items-center flex-col md:flex-row justify-end w-full">
          <span className="relative flex rounded-md shadow-sm lg:w-1/4 my-4 lg:my-0 mr-3">
            <input
              type="text"
              name="account-number"
              id="account-number"
              className="block w-full rounded-lg border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleSearch}
            />
            <span className="absolute flex items-center cursor-pointer pr-3 top-2 right-0" onClick={() => getAllCampagins({ search: keyword })}>
              <BsSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </span>

          <form onSubmit={formik.handleSubmit} className="flex flex-col md:flex-row items-center">
            <div className="ml-3">
              <Input
                placeholder="Start Date"
                type="date"
                className="bg-white"
                name="insights_start_date"
                onChange={(e) => formik.setFieldValue("insights_start_date", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.insights_start_date}
                error={formik.errors.insights_start_date}
                touched={formik.touched.insights_start_date}
              />
            </div>
            <div className="ml-3">
              <Input
                placeholder="End Date"
                type="date"
                className="bg-white"
                name="insights_end_date"
                onChange={(e) => formik.setFieldValue("insights_end_date", e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.insights_end_date}
                error={formik.errors.insights_end_date}
                touched={formik.touched.insights_end_date}
              />
            </div>
            <div className="ml-3">
              <button className="px-5 py-2 my-4 button-gradient rounded-lg font-bold text-white w-full">
                Apply
              </button>
            </div>
          </form>

          <Button className="px-4 py-3 bg-[#DBD312] rounded-lg ml-3" onClick={() => handleReset()}>
            <BsArrowCounterclockwise />
          </Button>
        </div>

        <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
          <div className="-my-2 mx-0 md:-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative px-3 py-3">
                <DataTable
                  columns={columns}
                  data={campagins}
                  responsive={true}
                  progressPending={loading}
                  progressComponent={<Spinner />}
                  customStyles={customStyles}
                  className='react-dataTable'
                  onSort={(selectedColumn, sortDirection) => handleSorting(selectedColumn, sortDirection)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center flex-col md:flex-row justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{campagins.length}</span>&nbsp;of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
        </div>
        <Pagination
          totalPages={Math.ceil(totalItems / 10)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          offset={10}
        />
      </div>

      <BaseModal open={openConfirm} setOpen={setOpenConfirm} title="Delete Draft?">
        <div class="md:flex items-center">
          <div class="mt-4 md:mt-0 md:text-left">
            <p class="text-sm text-gray-700 mt-1">Are you sure you want to delete this draft?</p>
            <p class="text-sm text-gray-700 mt-1">This action cannot be undone.</p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end">
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={deleteRow}>Yes, Delete it</button>
          <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => setOpenConfirm(!openConfirm)}>Cancel</button>
        </div>
      </BaseModal>
    </section >
  )
}
