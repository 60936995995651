import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FaAngleDown, FaCheck } from 'react-icons/fa'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Select({ placeholder, options, selected, setSelected, className, islocationField = false, hideCheckIcons = false }) {
  return (
    <Listbox value={selected} onChange={item => setSelected && setSelected(item)}>
      {({ open }) => (
        <>
          <div className={`relative ${className}`}>
            {/* <Listbox.Button className={`${islocationField : '' : ''} relative w-full cursor-pointer rounded-lg bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}> */}
            <Listbox.Button className={`${islocationField ? 'bg-transparent d-flex' : 'bg-white ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600'} relative w-full cursor-pointer rounded-lg py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm sm:text-sm sm:leading-6`}>
              <span className="block truncate font-semibold">{selected?.label || placeholder}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FaAngleDown className={`h-5 w-5 ${islocationField ? 'text-gray-500' : 'text-[#B6CC43]'}`} aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-36 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((person) => (
                  <Listbox.Option
                    key={person.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-[#B6CC43] text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate mr-2')}>
                          {person.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-[#B6CC43]',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <FaCheck className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
