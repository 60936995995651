import { Link } from "react-router-dom"
import { useFormik } from "formik"
import { toast } from "react-toastify"

import { Input } from "../components"
import { forgetPawwordAPI } from "../service"
import { forgetPasswordSchema } from "../helpers/validationSchema"

export default function ForgetPawword() {
  const submitHandler = async values => {
    try {
      const response = await forgetPawwordAPI(values)
      toast.success("Reset Email sent")
      formik.resetForm()
      console.log("response", response)
    } catch (error) {
      console.log("ERROR", error)
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: submitHandler
  })
  return (
    <section className="flex items-center justify-center min-h-screen bg-gradient">
      <div className="mx-auto container">
        <div className="mx-auto max-w-sm bg-white rounded-lg">
          <Link to={-1}>
            <img
              src="/static/icons/angleLeft.svg"
              alt="back icon"
              className="pt-5 ml-5"
              width={16}
            />
          </Link>
          <form
            onSubmit={formik.handleSubmit}
            className="py-10 px-10 flex flex-col"
          >
            <h1 className="text-xl font-bold mb-2">Forgot Password</h1>
            <h1 className="text-xs mb-2 font-light">
              Enter email to reset your password
            </h1>

            <Input
              placeholder="E-mail"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <button
              type="submit"
              className="px-2 py-2 my-5 button-gradient rounded-xl font-bold text-white mb-2"
            >
              Send email
            </button>
            <p className="text-xs font-light">
              Make sure you already confirmed your email. By clicking button
              above you’ll receive an email.
            </p>
          </form>
        </div>
      </div>
    </section>
  )
}
