import { useEffect, useState, useImperativeHandle } from "react"
import { Popover } from "@headlessui/react"
import { BiChevronDown, BiFilterAlt } from "react-icons/bi"
import { Dropdown } from "../../components"
import { useFormik } from "formik"
import { useDispatch, useSelector } from 'react-redux'
import { getStatsSummary } from "../../redux/actions/common"
import moment from 'moment'
import 'moment-timezone'

export default function FilterComponent({ getAllData, adAccounts, handleFilteredStats, formik, forwardedRef }) {

  const { offerList } = useSelector(({ common }) => common)
  const [togglePopover, setPopover] = useState(false)
  const [offers, setOffers] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    moment.tz.setDefault("America/New_York")
  }, [])

  useEffect(() => {
    if (offerList) {
      const res = offerList.map(item => {
        return { label: item.name, value: item.id }
      })
      setOffers(res)
    }
  }, [offerList])

  const handleFilterData = async values => {

    try {
      let params = {}
      if (values.ad_account_id) {
        params.ad_account_id = values.ad_account_id.value
      }
      if (values.offer_ids) {
        params.offer_ids = values.offer_ids.value
      }
      if (values.status) {
        params.status = values.status.value
      }

      getAllData(params)
      handleFilteredStats(params)

      setPopover(false)
    } catch (error) {
      setPopover(false)
    }
  }

  useImperativeHandle(forwardedRef, () => ({
    handleFilterData,
  }));

  return (
    <>
      <Popover
        as="div"
        className="relative inline-block text-left"
      >
        {({ open }) => (
          <>
            <Popover.Button className="px-4 py-2 font-medium bg-[#ffffff] rounded-md shadow-sm focus:outline-none" onClick={() => setPopover(true)}>
              <div className="flex items-center">
                <BiFilterAlt className="h-5 w-5 text-[#B6CC43]" aria-hidden="true" />
                <span className="ml-2 mr-3">
                  Filter
                </span>
                <BiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </Popover.Button>
            {togglePopover && (
              <Popover.Panel className="absolute z-10 bg-[#FFFFFF] border border-gray-300 rounded-md py-5 px-3 w-full flex flex-col min-w-[300px] right-0">
                <form onSubmit={formik.handleSubmit}>
                  <div className="block mb-3">
                    <span className="font-semibold text-gray-500">Offer</span>
                    <Dropdown
                      placeholder="Offer"
                      classBox="bg-[#ffffff]"
                      options={offers}
                      onChange={(e) => formik.setFieldValue("offer_ids", e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.offer_ids}
                      error={formik.errors.offer_ids}
                      touched={formik.touched.offer_ids}
                    />
                  </div>

                  <div className="block mb-3">
                    <span className="font-semibold text-gray-500">Ad Account</span>
                    <Dropdown
                      placeholder="Account"
                      classBox="bg-[#ffffff]"
                      options={adAccounts}
                      onChange={(e) => formik.setFieldValue("ad_account_id", e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.ad_account_id}
                      error={formik.errors.ad_account_id}
                      touched={formik.touched.ad_account_id}
                    />
                  </div>

                  <div className="block">
                    <span className="font-semibold text-gray-500">Delivery Status</span>
                    <Dropdown
                      placeholder="Delivery Status"
                      classBox="bg-[#ffffff]"
                      options={[
                        { label: "ALL", value: "" },
                        { label: "ACTIVE", value: "ACTIVE" },
                        { label: "ARCHIVED", value: "ARCHIVED" },
                        { label: "DELETED", value: "DELETED" },
                        { label: "PAUSED", value: "PAUSED" }
                      ]}
                      onChange={(e) => formik.setFieldValue("status", e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.status}
                      error={formik.errors.status}
                      touched={formik.touched.status}
                    />
                  </div>

                  <div className="block">
                    <button className="px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white w-full" type="submit">
                      Apply
                    </button>
                  </div>
                </form>
              </Popover.Panel>
            )}
          </>
        )}
      </Popover>
    </>
  )
}
