import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from "react"
import { BsSearch, BsArrowCounterclockwise } from "react-icons/bs"
import { toast } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"
import _ from "lodash"

import {
  Button,
  CreateUserModal,
  Pagination,
  UserDetailModal,
  Select
} from "../../components"

import { getUsersAPI, deleteUsersAPI } from "../../service"
import { ORGANIZATION_ROLE_ENUM } from "../../utils/constants"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function UserManagement() {
  const checkbox = useRef()

  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [viewUser, setViewUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState([])
  const [createUser, setCreateUser] = useState(false)
  const [users, setUsers] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const [userCount, setUserCount] = useState(0)
  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedRole, setSelectedRole] = useState("")

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedUser.length > 0 && selectedUser.length < users.length
    setChecked(selectedUser.length === users.length)
    setIndeterminate(isIndeterminate)
    checkbox.current.indeterminate = isIndeterminate
  }, [selectedUser, users])

  function toggleAll() {
    setSelectedUser(checked || indeterminate ? [] : users)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  useEffect(() => {
    getUsers()
  }, [currentPage])

  useEffect(() => {
    if (selectedRole) {
      const param = { role: selectedRole.value }
      if (search && search !== "") {
        param.query = search
      }
      getUsers(param)
    }
  }, [selectedRole])

  const getUsers = async (params) => {
    try {

      let current = (currentPage > 0) ? currentPage : 0
      let obj = { offset: current, limit: 10 }
      
      if (params && params.query) {
        obj.search_query = params.query
      }
      if (params && params.role) {
        obj.organization_role = params.role
      }

      const { data } = await getUsersAPI(obj)
      setUserCount(data.count)
      setUsers(data.results)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const deleteUser = async id => {
    try {
      await deleteUsersAPI(id)

      toast.success("User Deleted")
      getUsers()
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    }
  }

  const deleteUserConfirmation = id => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUser(id)
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    })
  }

  const handleSearch = () => {
    const param = { query: search }
    if (selectedRole) {
      param.role = selectedRole.value
    }
    getUsers(param)
  }

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value)
  }
  return (
    <section className="w-full mx-auto px-3 min-h-screen bg-gray-200">
      <CreateUserModal
        open={createUser}
        setOpen={value => {
          getUsers()
          setCreateUser(value)
          setCurrentUser({})
        }}
        user={currentUser}
      />
      <UserDetailModal
        open={viewUser}
        setOpen={value => {
          setViewUser(value)
        }}
        user={currentUser}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 inline-flex w-full">
          <Button
            onClick={() => setCreateUser(true)}
            text={""}
            className="px-7 py-2 bg-[#B6CC43] rounded-xl mr-3"
          >
            Create User
          </Button>
          <span className="relative flex rounded-md shadow-sm w-1/3">
            <input
              type="text"
              name="account-number"
              id="account-number"
              className="block w-full rounded-xl border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B6CC43] sm:text-sm sm:leading-6"
              placeholder="Search"
              onChange={handleInputChange}
              value={search}
            />
            <span className="absolute flex items-center pr-3 top-2 right-0">
              <BsSearch className="h-5 w-5 text-gray-400" aria-hidden="true"  onClick={handleSearch} />
            </span>
          </span>
          <Button text={""} className="px-7 py-2 bg-[#B6CC43] rounded-xl ml-3" onClick={handleSearch}>
            Filter
          </Button>
          <Button className="px-4 py-2 bg-[#DBD312] rounded-xl ml-3" onClick={() => { setSearch(""); setSelectedRole(""); getUsers(); }}>
            <BsArrowCounterclockwise />
          </Button>
          <Select placeholder={'Filter by Role'} className="ml-3" options={ORGANIZATION_ROLE_ENUM} selected={selectedRole} setSelected={setSelectedRole} />
        </div>

        <div className="mt-8 flow-root bg-white rounded-xl shadow-lg">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedUser.length > 0 && (
                  <div className="absolute top-0 left-14 flex h-12 items-center space-x-3 bg-white sm:left-12">
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Bulk edit
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Delete all
                    </button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        User ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Last Name
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Admin
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Roles
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-sm text-center font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users.map(item => (
                      <tr
                        key={item.email}
                        className={
                          selectedUser.includes(item)
                            ? "bg-gray-50"
                            : "hover:bg-gray-100"
                        }
                      >
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedUser.includes(item) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-[#B6CC43]" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#B6CC43] focus:ring-[#B6CC43]"
                            value={item.email}
                            checked={selectedUser.includes(item)}
                            onChange={e =>
                              setSelectedUser(
                                e.target.checked
                                  ? [...selectedUser, item]
                                  : selectedUser.filter(p => p !== item)
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedUser.includes(item)
                              ? "text-indigo-600"
                              : "text-gray-900"
                          )}
                        >
                          {item.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.first_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.last_name}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.lastName}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.phone}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.admin}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.organization_role}
                        </td>

                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                          <Button
                            className={"bg-[#DBD312] mr-2 py-2 px-3 shadow-sm"}
                            onClick={() => {
                              setCurrentUser(item)
                              setCreateUser(true)
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            className={"bg-[#CC4B43] mr-2 py-2 px-3 shadow-sm"}
                            onClick={() => deleteUserConfirmation(item.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6 mt-5 ">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{users.length}</span>&nbsp;of <span className="font-medium">{userCount}</span> results
            </p>
          </div>
        </div>
        <Pagination
          totalPages={Math.ceil(userCount / 10)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  )
}
