import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default function LeadGenerated() {

  const series = [{
    name: "Revenue",
    data: [
      {
        x: '2008',
        y: [2800, 4500]
      },
      {
        x: '2009',
        y: [3200, 4100]
      },
      {
        x: '2010',
        y: [2950, 7800]
      },
      {
        x: '2011',
        y: [3000, 4600]
      },
      {
        x: '2012',
        y: [3500, 4100]
      },
      {
        x: '2013',
        y: [4500, 6500]
      },
      {
        x: '2014',
        y: [4100, 5600]
      },
      {
        x: '2015',
        y: [3500, 4100]
      },
      {
        x: '2016',
        y: [4500, 6500]
      },
      {
        x: '2017',
        y: [4100, 5600]
      },
      {
        x: '2018',
        y: [4500, 6500]
      },
      {
        x: '2019',
        y: [4100, 5600]
      }
    ]
  }]

  const options = {
    chart: {
      height: 300,
      type: 'rangeBar',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        isDumbbell: false,
        columnWidth: 15,
        borderRadius: 5,
        horizontal: false
      }
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left',
      customLegendItems: ['Product A', 'Product B']
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        gradientToColors: ['#00B2B4'],
        inverseColors: true,
        stops: [0, 100]
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="rangeBar" height="300px" />
    </div>
  )
}
