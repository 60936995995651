// ** Initial State
const initialState = {
    stats: null,
    leads: null,
    campaigns: [],
    landingPages: [],
    offerList: []
}

const common = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATS_SUMMARY':
            return { ...state, stats: action.payload, isFetching: action.isFetching }
        case 'GET_LEAD_STATS':
            return { ...state, leads: action.payload, isFetching: action.isFetching }
        case 'GET_CAMPAIGN_LISTS':
                return { ...state, campaigns: action.payload, isFetching: action.isFetching }
        case 'GET_ALL_LANDING':
            return { ...state, landingPages: action.payload, isFetching: action.isFetching }
        case 'GET_ALL_OFFERS':
            return { ...state, offerList: action.payload, isFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default common