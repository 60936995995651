import { useState } from "react"
import _ from "lodash"
import { Dropdown, Input, Tabs } from "../../../components"
import LeadGenerated from "./LeadGenerated"
import ProfitFromLeads from "./ProfitFromLeads"
import TotalRevenue from "./TotalRevenue"
import ConversionComparison from "./ConversionComparison"
import LowestPerformers from "./LowestPerformers"
import TopPerformers from "./TopPerformers"
import Analytics from "./Analytics"

import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs"

export default function CampaignPerformance() {

  const [open, setOpen] = useState(false);
  const [tabsList, _setTabsList] = useState([
    { title: "Reach", value: 1, isActive: true },
    { title: "Clicks", value: 2, isActive: false },
    { title: "Conversion", value: 3, isActive: false },
    { title: "Revenue", value: 4, isActive: false },
    { title: "Spend", value: 5, isActive: false },
    { title: "Profit", value: 6, isActive: false },
    { title: "CPC", value: 7, isActive: false },
    { title: "CTR", value: 8, isActive: false },
    { title: "Conv.Rate", value: 9, isActive: false },
    { title: "ROI", value: 10, isActive: false },
    { title: "Avg. Daily Profit", value: 11, isActive: false }
  ])
  const [tabsListGraph, _setTabsListGraph] = useState([
    { title: "Daily", value: 1, isActive: true },
    { title: "Weekly", value: 2, isActive: false },
    { title: "Monthly", value: 3, isActive: false }
  ])

  const setTabsList = activeId => {
    let tempTabs = tabsList.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsList(tempTabs)
  }

  const setTabsListGraph = activeId => {
    let tempTabs = tabsListGraph.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsListGraph(tempTabs)
  }

  return (
    <section className="w-full mx-auto px-10 min-h-screen bg-gray-200">

      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 lg:gap-5 mb-4 items-end mt-12">
        <div className="bg-white rounded-lg shadow-xl h-24 p-5 flex items-center justify-between">
          <div className="flex flex-col">
            <p className="flex items-center">Conversions <BsFillArrowDownCircleFill className="ml-1" size={20} fill="red" /></p>
            <h4 className="font-xl font-bold text-gray-900">225</h4>
          </div>
          <div><img src="/static/graphdown.png" width={80} /></div>
        </div>
        <div className="bg-white rounded-lg shadow-xl h-24 p-5 flex items-center justify-between">
          <div className="flex flex-col">
            <p className="flex items-center">Revenue <BsFillArrowUpCircleFill className="ml-1 text-green-500" size={20} /></p>
            <h4 className="font-xl font-bold text-gray-900">$225</h4>
          </div>
          <div><img src="/static/graphup.png" width={80} /></div>
        </div>
        <div className="bg-white rounded-lg shadow-xl h-24 p-5 flex items-center justify-between">
          <div className="flex flex-col">
            <p className="flex items-center">Amount Spent <BsFillArrowDownCircleFill className="ml-1" size={20} fill="red" /></p>
            <h4 className="font-xl font-bold text-gray-900">225</h4>
          </div>
          <div><img src="/static/graphdown.png" width={80} /></div>
        </div>
        <div className="bg-white rounded-lg shadow-xl h-24 p-5 flex items-center justify-between">
          <div className="flex flex-col">
            <p className="flex items-center">Profile <BsFillArrowUpCircleFill className="ml-1 text-green-500" size={20} /></p>
            <h4 className="font-xl font-bold text-gray-900">$400</h4>
          </div>
          <div><img src="/static/graphup.png" width={80} /></div>
        </div>
        <div className="bg-white rounded-lg shadow-xl h-24 p-5 flex items-center justify-between">
          <div className="flex flex-col">
            <p className="flex items-center">ROI <BsFillArrowUpCircleFill className="ml-1 text-green-500" size={20} /></p>
            <h4 className="font-xl font-bold text-gray-900">40%</h4>
          </div>
          <div><img src="/static/graphup.png" width={80} /></div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 lg:gap-5 mb-2 items-end mt-12">
        <label class="block">
          <span className="font-bold text-gray-500">Offers</span>
          <Dropdown
            placeholder="Select"
            classBox="bg-white"
            options={[
              { label: "1", value: "1" },
              { label: "2", value: "2" }
            ]}
          />
        </label>
        <label class="block">
          <span className="font-bold text-gray-500">Media Buyer</span>
          <Dropdown
            placeholder="Select"
            classBox="bg-white"
            options={[
              { label: "1", value: "1" },
              { label: "2", value: "2" }
            ]}
          />
        </label>
        <label class="block">
          <span className="font-bold text-gray-500">Start Date</span>
          <Input className="bg-white" placeholder="Start Date" type="date" name="start_date" />
        </label>
        <label class="block">
          <span className="font-bold text-gray-500">End Date</span>
          <Input className="bg-white" placeholder="End Date" type="date" name="start_date" />
        </label>

        <button className="px-2 py-2 my-4 button-gradient rounded-xl font-bold text-white">
          Apply
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:gap-5 mb-4 items-end mt-8">
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Leads Generated</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <LeadGenerated />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Profit From Leads</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <ProfitFromLeads />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-5 items-end mt-8">
        <div className="bg-white col-span-2 rounded-lg shadow-xl px-6 py-3">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Total Revenue</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-3">
              <div className="flex flex-row items-center">
                <h3 className="font-xl font-bold text-gray-900">$ 236,535</h3>
                <div className="ml-3 flex flex-row items-center">
                  <BsFillArrowUpCircleFill size={25} className="text-green-500" />
                  <div className="ml-3 block">
                    <h5 className="font-md font-bold text-green-500">0.8%</h5>
                    <p className="text-gray-400">than last time</p>
                  </div>
                </div>
              </div>
              <TotalRevenue />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900 mb-8">Conversion Comparison</h4>
            </div>
            <div>
              <ConversionComparison />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:gap-5 mb-4 items-end mt-8 mb-8">
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Top Performers</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <label className="block w-64">
                <Dropdown
                  placeholder="Select"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" }
                  ]}
                />
              </label>
              <TopPerformers />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Lowest Performers</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div className="mb-6">
              <label className="block w-64">
                <Dropdown
                  placeholder="Select"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" }
                  ]}
                />
              </label>
              <LowestPerformers />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-xl px-6 py-6">
          <div className="w-full">
            <div className='flex justify-between items-center'>
              <h4 className="font-lg font-bold text-gray-900">Analytics</h4>
              <Tabs tabs={tabsListGraph} handleChange={setTabsListGraph} />
            </div>
            <div>
              <label className="block w-64">
                <Dropdown
                  placeholder="Select"
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" }
                  ]}
                />
              </label>
              <Analytics />
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}
