import { Disclosure } from "@headlessui/react"
import { FaAngleDown } from "react-icons/fa"
import { Link } from "react-router-dom"
export default function DisclosureComponent({ subMenu, handleClick }) {

  const handleSubClick = () => {
    handleClick && handleClick()
  }
  return (
    <div className="w-full px-4 pt-2">
      <div className="mx-auto w-full text-[#9B9B9B] max-w-md rounded-lg group-hover:md:rounded-2xl bg-[#F8F9F3] shadow-sm px-1 border">
        {subMenu.map(({ title, Icon, links }) => (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full md:justify-center group-hover:md:justify-between justify-start rounded-lg px-1 group-hover:px-4 border-b py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                  <div className="flex justify-center items-center text-lg group-hover:text-base">
                    <Icon />
                    <span className="ml-2 text-sm md:text-base md:hidden group-hover:md:block">
                      {title}
                    </span>
                  </div>
                  <FaAngleDown
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } hidden group-hover:block h-5 w-5`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-1 group-hover:px-4 pt-4 pb-2 text-sm text-gray-500">
                  <ul className="list-disc list-inside">
                    {links.map(({ name, href }) => (
                      <li className="py-1" onClick={() => handleSubClick()}>
                        <Link
                          to={href}
                          className="md:hidden group-hover:md:inline-block"
                        >
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  )
}
