import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default function LeadGenerated() {

  const series = [{
    name: "Cost per Lead",
    data: [10, 41, 35, 51, 49, 62, 69, 91]
  }]

  const options = {
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#B6CC43'],
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Week 01', 'Week 02', 'Week 03', 'Week 04', 'Week 05', 'Week 06', 'Week 07', 'Week 08'],
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<div class="px-4 py-4"><b>$' + data + '</b><p>Cost Per Lead</p>'
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height="300px" />
    </div>
  )
}
